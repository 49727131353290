import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import FaqSection from './FaqSection'
import { sections } from './constants'
import LabelIconButton from '../../../core/Button/LabelIconButton'
import useSettingsStore from '../store'

const FaqContainer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { setSupportSubPage } = useSettingsStore()

  const handleBack = () => {
    if (location.pathname.includes('/settings')) {
      setSupportSubPage('main')
    } else {
      navigate(-1)
    }
  }

  return (
    <div className="settings-section" data-testid="faq">
      <div className="settings-card-header mb-700">
        <LabelIconButton
          id="back_icon-btn"
          icon="back"
          bordered
          onClick={handleBack}
        />
        <h2 className="settings-title m-base">
          {t('support.faq.title', 'FAQs')}
        </h2>
      </div>
      
      {sections.map(({ title, id }) => (
        <FaqSection id={id} title={title} key={title} />
      ))}
    </div>
  )
}

export default FaqContainer