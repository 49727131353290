import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  components,
  CSSObjectWithLabel,
  SingleValueProps,
  SingleValue as SingleValueT,
} from 'react-select'
import { EN_LANGUAGE, NL_LANGUAGE } from '../../../i18n'
import { Option } from '../../../utils/getSelectOptions'
import Icon, { ICON_TYPE } from '../../core/Icon/Icon'
import { Select } from '../../core/Select/Select'
import { useAuth } from '../../../hooks/useAuth'

export const languageOptions: Option[] = [
  { value: EN_LANGUAGE, label: 'English', icon: ICON_TYPE.GB_FLAG },
  { value: NL_LANGUAGE, label: 'Dutch', icon: ICON_TYPE.NL_FLAG },
]

const IconOption = (props: any) => (
  <components.Option {...props}>
    <Icon icon={props.data.icon} className="me-300" />
    {props.data.label}
  </components.Option>
)

const SingleValue = (props: SingleValueProps<Option>) => (
  <components.SingleValue {...props}>
    {props.data.icon && <Icon icon={props.data.icon} className="me-300" />}
    {props.data.label}
  </components.SingleValue>
)

interface LanguageSwitcherProps {
  onChange?: (lng: string) => void
  value?: string
}

export function LanguageSwitcher({ onChange, value }: LanguageSwitcherProps) {
  const { user } = useAuth()
  const { i18n } = useTranslation()

  const defaultLanguage = languageOptions[1]
  const storedLanguage =
    localStorage.getItem('selectedLanguage') || defaultLanguage.value

  const language = value || storedLanguage

  const handleChange = useCallback(
    (lng: SingleValueT<Option>) => {
      if (!lng) {
        return
      }

      localStorage.setItem('selectedLanguage', lng.value)

      if (onChange) {
        onChange(lng.value)
      } else {
        i18n.changeLanguage(lng.value)
      }
    },
    [i18n, onChange],
  )

  useEffect(() => {
    const currentLanguage = localStorage.getItem('selectedLanguage')

    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage)
    } else {
      localStorage.setItem('selectedLanguage', defaultLanguage.value)
      i18n.changeLanguage(defaultLanguage.value)
    }
  }, [defaultLanguage, i18n])

  return (
    <Select
      isSearchable={false}
      options={languageOptions}
      components={{ Option: IconOption, SingleValue }}
      isMulti={false}
      value={languageOptions.find((o) => o.value === language)}
      onChange={handleChange}
      styles={{
        singleValue: (base: CSSObjectWithLabel) => ({
          ...base,
          display: 'flex',
          alignItems: 'center',
        }),
      }}
    />
  )
}
