import { i18n } from 'i18next'
import { IconName } from '../../../utils/getIconClass/getIconClass'
import { Paths } from '../../../utils/paths'
import { ICON_TYPE } from '../../core/Icon/Icon'

interface NavItem {
  id: string
  href: string
  key: string
  label: string
  icon: IconName | ICON_TYPE
  bottom?: boolean
}

const getNavItems = (i18n: i18n, bottom = false) => {
  const navItems: {
    [path in Paths]: NavItem
  } = {
    history: {
      id: 'nav_history',
      href: '/history',
      key: 'history',
      label: i18n.t('navigation.history', 'History'),
      icon: ICON_TYPE.CLOCK,
    },
    consultation: {
      id: 'nav_consultation',
      href: '/consultation',
      key: 'consultation',
      label: i18n.t('navigation.consultation', 'Consult'),
      icon: ICON_TYPE.MIC,
    },
    library: {
      id: 'nav_library',
      href: '/library',
      key: 'library',
      label: i18n.t('navigation.library', 'Library'),
      icon: ICON_TYPE.CAMERA,
    },
    settings: {
      id: 'nav_settings',
      href: '/settings',
      key: 'settings',
      label: i18n.t('navigation.settings', 'Settings'),
      icon: ICON_TYPE.SETTINGS,
      bottom: true,
    },
    /*organization: {
      id: 'nav_organization',
      href: '/organization',
      key: 'organization',
      label: i18n.t('navigation.organization', 'Organization'),
      icon: 'organization',
    },*/
  }
  const returnObj: {
    [path in Paths]: NavItem
  } = {} as {
    [path in Paths]: NavItem
  }
  Object.keys(navItems).forEach((value) => {
    if (Boolean(navItems[value as Paths].bottom) === bottom) {
      returnObj[value as Paths] = navItems[value as Paths]
    }
  })

  return returnObj
}
export default getNavItems
