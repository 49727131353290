export type IconName =
  | 'add-user'
  | 'arrow-down-circle'
  | 'arrow-up'
  | 'arrow-up-circle'
  | 'at-sign'
  | 'back'
  | 'bell'
  | 'breadcrumb'
  | 'calendar-arrow'
  | 'chart'
  | 'check-circle'
  | 'chevron'
  | 'chevron-left'
  | 'chevron-right'
  | 'clear'
  | 'close'
  | 'organization'
  | 'delete'
  | 'document'
  | 'download'
  | 'edit'
  | 'export'
  | 'filters'
  | 'gear'
  | 'hamburger'
  | 'headphone'
  | 'home'
  | 'info'
  | 'link'
  | 'live'
  | 'magnifier'
  | 'mail'
  | 'minus'
  | 'minus-circle'
  | 'more-info'
  | 'movie'
  | 'note'
  | 'next'
  | 'pin'
  | 'phone'
  | 'plus'
  | 'question'
  | 'reading'
  | 'refresh'
  | 'report'
  | 'select-toggle'
  | 'switch'
  | 'success'
  | 'three-dots'
  | 'user-star'
  | 'users'
  | 'user'

export function getIconClass(name: IconName) {
  return 'icon-' + name
}
