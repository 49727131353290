import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import App from './App'
import { AppState, Auth0Provider, CacheLocation } from '@auth0/auth0-react'
import { AuthProvider } from './AuthProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { getEnvMap, getEnvironmentVariable } from './utils/environment'
import { languageInit } from './i18n'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

// Initialize environment variables
const envMap = getEnvMap()

if (process.env.NODE_ENV !== 'production') {
  console.group('Environment Variables')
  envMap.forEach((value, key) => {
    console.log(`${key}:`, value)
  })
  console.groupEnd()
}

const onRedirectCallback = (appState?: AppState) => {
  if (appState?.returnTo) {
    window.location.replace(appState.returnTo)
  } else {
    window.location.replace('/consultation')
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const authDomain = getEnvironmentVariable('REACT_APP_AUTH0_DOMAIN')
const authAudience =
  getEnvironmentVariable('REACT_APP_AUTH0_AUDIENCE') || 'ditto.user-service'
const authClientId = getEnvironmentVariable('REACT_APP_AUTH0_CLIENT_ID')
const authConnection = getEnvironmentVariable('REACT_APP_AUTH0_CONNECTION')
const authRedirectUri = window.location.origin

const providerConfig = {
  domain: authDomain,
  clientId: authClientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: authRedirectUri,
    ...(authAudience ? { audience: authAudience } : null),
    connection: authConnection,
  },
  useRefreshTokens: true,
  cacheLocation: 'localstorage' as CacheLocation,
}
/*
https://stackoverflow.com/a/79048387/2706861
* */
languageInit.then(() => {
  root.render(
    <React.StrictMode>
      <Auth0Provider {...providerConfig}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <App />
          </AuthProvider>
          {/*<ReactQueryDevtools initialIsOpen={true} />*/}
        </QueryClientProvider>
      </Auth0Provider>
    </React.StrictMode>,
  )
  reportWebVitals()
})
