import { TableStyles } from 'react-data-table-component'

export const dataTableStyles: TableStyles = {
  head: {
    style: {
      fontSize: 'var(--g-fs-200)',
      color: 'var(--g-gray-700)',
      letterSpacing: '3px',
      textTransform: 'uppercase',
      fontWeight: 'var(--g-fw-regular)',
      paddingLeft: '0',
      paddingRight: '0',
      '&:hover': {
        opacity: '1',
      },
    },
  },
  headRow: {
    style: {
      minHeight: '66px',
      borderBottomColor: 'var(--g-border-color)',
      paddingLeft: 'var(--g-spacer-base)',
    },
  },
  headCells: {
    style: {
      paddingLeft: 'var(--g-spacer-base)',
      paddingRight: 'var(--g-spacer-base)',
    },
  },
  cells: {
    style: {
      padding: 'var(--g-table-cell-padding-y) var(--g-table-cell-padding-x)',
    },
  },
  rows: {
    style: {
      fontSize: 'var(--g-fs-300)',
      minHeight: '56px',
      color: 'var(--g-gray-700)',
      paddingLeft: 'var(--g-spacer-base)',
      '&:not(:last-of-type)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: 'var(--g-border-color)',
      },
    },
    highlightOnHoverStyle: {
      color: 'var(--g-gray-700)',
      backgroundColor: 'var(--g-gray-bg)',
      transitionDuration: '0.15s',
      transitionProperty: 'background-color',
      outlineStyle: 'solid',
      outlineWidth: '1px',
    },
    selectedHighlightStyle: {
      backgroundColor: 'var(--g-gray-bg) !important',
    },
  },
  pagination: {
    style: {
      color: 'var(--g-gray-700)',
      fontSize: 'var(--g-fs-300)',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: 'var(--g-border-color)',
      padding: 'var(--g-spacer-700)',
    },
    pageButtonsStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 'var(--g-border-radius)',
      height: 'var(--g-rdt-controls-height)',
      width: 'var(--g-rdt-controls-height)',
      padding: '0',
      margin: '0',
      fill: 'var(--g-gray-900)',
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'var(--g-gray-300)',
      '&:not(:first-of-type)': {
        marginLeft: 'var(--g-spacer-500)',
      },
      '&:disabled': {
        fill: 'var(--g-gray-500)',
      },
      '&:hover:not(:disabled)': {
        borderColor: 'var(--g-color-primary)',
        fill: 'var(--g-color-primary)',
        backgroundColor: 'transparent',
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
}
