import { useTranslation } from 'react-i18next'
import { UserProfile } from '../../../../types/domain'
import { useState } from 'react'
import EditPersonalDetails from './EditPersonalDetails'

interface Props {
  profile: UserProfile
}

const PersonalDetails = ({ profile }: Props) => {
  const { t } = useTranslation()
  const [isEditing, setIsEditing] = useState(false)

  const displayFields = [
    { key: 'firstName', label: 'First Name' },
    { key: 'lastName', label: 'Last Name' },
    { key: 'jobTitle', label: 'Job Title' },
  ]

  if (isEditing) {
    return <EditPersonalDetails profile={profile} onCancel={() => setIsEditing(false)} />
  }

  return (
    <div>
      <div>
        <h2 className="h3">{t('settings.profile.personal', 'Personal Details')}</h2>
        <button 
          className="btn btn-secondary"
          onClick={() => setIsEditing(true)}
        >
          {t('common.edit', 'Edit')}
        </button>
      </div>
      
      {displayFields.map(({ key, label }) => (
        <div key={key} className="settings-field-row">
          <span className="regular-300-gray-700">
            {t(`user.details.${key}`, label)}
          </span>
          <span className="medium-300-semi-bold">
            {profile[key as keyof UserProfile] || '-'}
          </span>
        </div>
      ))}
    </div>
  )
}

export default PersonalDetails 