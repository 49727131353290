import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { MultiValue } from 'react-select'
import { Attachment, Template } from '../../../../types/domain'
import MarkdownEditor, { EditorContentChanged } from '../../../core/MarkdownEditor/MarkdownEditor'
import { Select } from '../../../core/Select/Select'
import Modal from '../../../shared/Modal/Modal'
import { CategoryOption, categoryOptions, getSubcategoryOptions } from '../../InteractionCategory/CategoryOptions'
import { AtachmentOption } from '../AttachmentManagement/AttachmentOption'
import { useAttachment } from '../../../../hooks/useAttachment/useAttachment'
import Button from '../../../core/Button/Button'

interface ExtendedTemplate {
  id?: number
  displayName: string
  content: string
  category: CategoryOption[]
  subcategory: CategoryOption[]
  attachments: AtachmentOption[]
  isForOrganization?: boolean
  userId: number | null
  organizationId: number | null
}

interface TemplateManagementModalProps {
  show: boolean
  onHide: () => void
  template: Template | null
  onSubmit: (data: Partial<Template>) => Promise<void>
  isManager: boolean
  attachmentNamesMap: Record<string | number, string>
}

const mapApiToFormData = (
  template: Template,
  attachmentNamesMap: Record<string | number, string>,
): Partial<ExtendedTemplate> => {
  return {
    ...template,
    displayName: template.displayName || '',
    content: template.content || '',
    category: template.category?.map((value) => ({
      value,
      label: categoryOptions.find((cat) => cat.value === value)?.label || value.toString(),
    })) || [],
    subcategory: template.subcategory?.map((value) => ({
      value,
      label: categoryOptions.find((cat) => cat.value === value)?.label || value.toString(),
    })) || [],
    attachments: template.attachments?.map((attachment) => ({
      value: attachment.id,
      label: attachment.displayName,
    })) || [],
    isForOrganization: template.organizationId !== null,
  }
}

export const mapFormDataToApiFormat = (
  formData: Partial<ExtendedTemplate>,
): Partial<Template> => {
  const apiData: Partial<Template> = {
    displayName: formData.displayName,
    content: formData.content,
    category: formData.category?.length
      ? formData.category.map((cat) => cat.value)
      : [],
    subcategory: formData.subcategory?.length
      ? formData.subcategory.map((sub) => sub.value)
      : [],
    attachmentIds: formData.attachments?.length
      ? formData.attachments.map((att) => att.value)
      : [],
  }
  return apiData
}

const TemplateManagementModal: React.FC<TemplateManagementModalProps> = ({
  show,
  onHide,
  template,
  onSubmit,
  isManager,
  attachmentNamesMap,
}) => {
  const [formData, setFormData] = useState<Partial<ExtendedTemplate>>({
    displayName: '',
    content: '',
    category: [],
    subcategory: [],
    attachments: [],
    isForOrganization: false,
  })

  const memoizedTemplate = useMemo(() => template, [template?.id])
  const editorKey = useMemo(() => `editor-${show}-${Date.now()}`, [show])

  const { attachments, isLoading: isLoadingAttachments } = useAttachment()
  const attachmentOptions: AtachmentOption[] = attachments.map(
    (attachment) => ({
      value: attachment.id,
      label: attachment.displayName,
    }),
  )

  const { t } = useTranslation()

  useEffect(() => {
    if (show) {
      const newData = memoizedTemplate 
        ? mapApiToFormData(memoizedTemplate, attachmentNamesMap)
        : {
            displayName: '',
            content: '',
            category: [],
            subcategory: [],
            attachments: [],
            isForOrganization: isManager,
          }
      console.log('Setting new form data:', newData)
      setFormData(newData)
    }
  }, [show, memoizedTemplate])

  const filteredSubcategoryOptions = getSubcategoryOptions(
    formData.category || null,
  )

  const onEditorContentChanged = useCallback((content: EditorContentChanged) => {
    console.log('Editor content changed:', content)
    setFormData(prev => ({ ...prev, content: content.markdown }))
  }, [])

  const handleSubmit = async () => {
    const apiData = mapFormDataToApiFormat(formData)
    await onSubmit(apiData)
  }

  const handleCategoryChange = (values: MultiValue<CategoryOption>) => {
    setFormData((prev) => ({ ...prev, category: Array.from(values) }))
  }

  const handleSubcategoryChange = (values: MultiValue<CategoryOption>) => {
    setFormData((prev) => ({ ...prev, subcategory: Array.from(values) }))
  }

  const handleAttachmentsChange = (values: MultiValue<AtachmentOption>) => {
    setFormData((prev) => ({ ...prev, attachments: Array.from(values) }))
  }

  return (
    <Modal
      show={show}
      dialogClassName="max-w-600px"
      contentClassName="rounded-2"
      onHide={onHide}
    >
      <Modal.Body className="p-600 d-flex flex-column align-items-center">
        <h4 className="mb-500">
          {template ? 'Edit Template' : 'Add Template'}
        </h4>

        <div className="w-500px">
          <label className="form-label">Template Display Name</label>
          <input
            className="form-control"
            value={formData.displayName}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, displayName: e.target.value }))
            }
          />
        </div>

        <div className="w-500px">
          <label className="form-label">Template Content</label>
          <MarkdownEditor
            key={editorKey}
            value={formData.content || ''}
            onChange={onEditorContentChanged}
          />
        </div>

        <div className="w-500px">
          <label className="form-label mt-300">Categories</label>
          <Select<CategoryOption, true>
            isMulti
            options={categoryOptions}
            value={formData.category}
            onChange={handleCategoryChange}
          />
        </div>

        {(formData.category?.length ?? 0) > 0 && (
          <div className="w-500px">
            <label className="form-label mt-300">Subcategories</label>
            <Select<CategoryOption, true>
              isMulti
              options={filteredSubcategoryOptions}
              value={formData.subcategory}
              onChange={handleSubcategoryChange}
            />
          </div>
        )}

        <div className="w-500px">
          <label className="form-label mt-300">Attachments</label>
          <Select<AtachmentOption, true>
            isMulti
            options={attachmentOptions}
            value={formData.attachments}
            onChange={handleAttachmentsChange}
          />
        </div>

        {isManager && (
          <div className="form-group mt-300">
            <label>
              <input
                type="checkbox"
                checked={formData.isForOrganization}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    isForOrganization: e.target.checked,
                  }))
                }
              />
              Use Organization Template
            </label>
          </div>
        )}

        <div className="mt-500">
          <Button variant="primary" onClick={handleSubmit}>
            {template ? 'Save Changes' : 'Add Template'}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default TemplateManagementModal
