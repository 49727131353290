import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Panel } from '../../core/Panel/Panel'
import BrandLogo from '../../shared/BrandLogo'
import { LanguageSwitcher } from '../LanguageSwitcher/LanguageSwitcher'

export function AuthContainer({ children }: PropsWithChildren) {
  const { i18n } = useTranslation()

  return (
    <div
      data-testid="login-container"
      className="app-landing-page | vh-100 d-flex align-items-start align-items-center flex-column"
    >
      <div className="w-100 d-flex justify-content-end">
        <div className="max-w-300px m-500">
          <LanguageSwitcher onChange={(lng) => i18n.changeLanguage(lng)} />
        </div>
      </div>
      <Panel className="app-landing-page__panel | rounded-4 py-lg-900 mt-900">
        <div className="app-landing-page__panel-content | flow | d-flex flex-column align-items-center mx-auto">
          <div className="app-landing-page__brand-logo">
            <BrandLogo width={70} height={70} />
          </div>
          {children}
        </div>
      </Panel>
    </div>
  )
}
