import { useTranslation } from 'react-i18next'
import Search from '../Icon/icons/svg/Search_Magnifying_Glass_small.svg'
interface Props {
  value: string
  onChange: (value: string) => void
}

export function SearchInput({ value, onChange }: Props) {
  const { t } = useTranslation()

  return (
    <div className="search-input | position-relative" role="search">
      <img
        className="p-absolute-left-center magnifier"
        alt="magnifying glass"
        src={Search}
      />
      <input
        type="text"
        className="form-control ps-750 bg-transparent"
        placeholder={t('searchField.placeholder', 'Find...')}
        value={value}
        onChange={({ target }) => onChange(target.value)}
        data-testid="search-input"
      />
    </div>
  )
}
