import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ArrowMoveLeft from '../components/core/Icon/icons/svg/Chevron_Left_Duo.svg'
import ProfileSettings from '../components/features/Settings/ProfileSettings/ProfileSettings'
import PrivacySettings from '../components/features/Settings/PrivacySettings/PrivacySettings'
import SupportSettings from '../components/features/Settings/SupportSettings/SupportSettings'
import SettingsNavigation from '../components/features/Settings/SettingsNavigation'
import useSettingsStore from '../components/features/Settings/store'

const SettingsPage = () => {
  const [collapsed, setCollapsed] = useState(false)
  const { t } = useTranslation()
  const page = useSettingsStore((state) => state.page)

  const getView = () => {
    switch (page) {
      case 'profile':
        return <ProfileSettings />
      case 'privacy':
        return <PrivacySettings />
      case 'support':
        return <SupportSettings />
      default:
        return <ProfileSettings />
    }
  }

  return (
    <div className="d-flex h-100">
      <div className={`settings-left-column d-flex flex-column ${collapsed ? 'collapsed' : ''}`}>
        <div className="settings-head justify-content-between">
          <span className="collapsable">{t('settings.header', 'Settings')}</span>
          <img
            alt="arrow move"
            src={ArrowMoveLeft}
            className={`cursor-pointer ${collapsed ? 'mirror-x' : ''}`}
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>
        <div className="settings-left-column-content flex-grow-1 collapsable">
          <SettingsNavigation />
        </div>
      </div>
      <div className="d-flex flex-grow-1 flex-column">
        <div className="settings-content-wrapper flex-grow-1">
          {getView()}
        </div>
      </div>
    </div>
  )
}

export default SettingsPage 