import { getEnvironmentVariable } from '../../utils/environment'
import { useMutationPost } from '../useQuery/useQuery'

const API_BASE_URL = getEnvironmentVariable('REACT_APP_USER_SERVICE_API_URL')

interface RegistrationResponse {
  message: string
  userId: string
  auth0UserId: string
}

interface ValidationError {
  [validationType: string]: string
}

interface RegistrationError {
  error?: string
  details?: string
  errors?: ValidationError[]
}

export interface RegistrationDto {
  email: string
  password: string
  isTermsAccepted: boolean
  firstName: string
  lastName: string
  jobTitle?: string
  bigNumber?: string
  organizationId?: number
  phoneNumber?: string
  phoneCountryCode?: string
  invitationId?: string
  language?: string
  roleIds?: number[]
}

export const useRegisterUser = () => {
  return useMutationPost<RegistrationResponse, RegistrationError, RegistrationDto>({
    baseUrl: API_BASE_URL || '',
    path: '/register',
  })
}
