import React from 'react'
import Icon, { ICON_TYPE } from '../Icon/Icon'

interface AttachmentCounterProps {
  count: number
}

export const AttachmentCounter: React.FC<AttachmentCounterProps> = ({ count }) => {
//   if (count === 0) return null
  
  return (
    <div className="attachment-counter">
      <Icon icon={ICON_TYPE.LINK} />
      <span className="attachment-counter__count">{count}</span>
    </div>
  )
}