import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Button from '../../../core/Button/Button'
import Modal from '../../../shared/Modal/Modal'

export const COOKIE_CONSENT = 'cookieConsent'

export function CookieConsentModal() {
  const [isShow, setIsShow] = useState(false)
  const { t } = useTranslation()

  const saveConsent = (answer: Boolean) => {
    Cookies.set(COOKIE_CONSENT, answer.toString())
    setIsShow(false)
  }

  useEffect(() => {
    const defaultValue = Cookies.get(COOKIE_CONSENT) !== 'true'
    setIsShow(defaultValue)
  }, [])

  return (
    <Modal
      show={isShow}
      backdrop="static"
      dialogClassName="max-w-800px"
      contentClassName="rounded-2"
    >
      <Modal.Body className="p-600">
        <h3>{t('cookie.consent.modal.title', 'Our use of cookies')}</h3>
        <div className="mt-500">
          {t(
            'cookie.consent.modal.description',
            "We've put some small files called cookies on your device to make our site work. " +
              "We'd also like to use analytics cookies. These send information about how our product is used to services" +
              'called Hotjar and Google analytics. They also allow us to get feedback from you. We use this information' +
              'to improve the product.',
          )}
        </div>
        <Trans
          i18nKey="cookie.consent.modal.read.more"
          t={t}
          defaults="Let us know if this is ok. We'll use a cookie to save your choice. You can <Link>read more about our cookies before you choose.<Link>"
          components={{
            Link: <Link to="/cookie-policy" target="_blank" />,
          }}
        />
        <div className="mt-700">
          <Button className="me-600" onClick={() => saveConsent(true)}>
            {t('cookie.consent.modal.btn.yes', 'I’m ok with analytics cookies')}
          </Button>
          <Button variant="secondary" onClick={() => saveConsent(false)}>
            {t('cookie.consent.modal.btn.no', 'Do not use analytics cookies')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
