import React, { useEffect, useRef } from 'react'
import * as THREE from 'three'
import useStore from '../store'

interface AudioVisualizerProps {
  isRecording: boolean
}

const AudioVisualizer: React.FC<AudioVisualizerProps> = ({ isRecording }) => {
  const mountRef = useRef<HTMLDivElement | null>(null)
  const analyserRef = useRef<AnalyserNode | null>(null)
  const dataArrayRef = useRef<Uint8Array | null>(null)
  const audioContextRef = useRef<AudioContext | null>(null)
  const mediaStreamRef = useRef<MediaStream | null>(null)
  const audioNodeRef = useRef<AudioNode | null>(null)
  const waveAmplitudeRef = useRef(0) // Amplitude tracker
  const deviceId = useStore((state) => state.deviceId)

  useEffect(() => {
    let scene: THREE.Scene
    let camera: THREE.OrthographicCamera
    let renderer: THREE.WebGLRenderer
    let lines: THREE.Line[] = []
    let animationFrameId: number

    const createWaveVisualization = () => {
      const mount = mountRef.current
      if (!mount) return

      // Set up canvas dimensions
      const width = 450
      const height = 160

      // Initialize Scene, Camera, and Renderer
      scene = new THREE.Scene()
      camera = new THREE.OrthographicCamera(
        -width / 2,
        width / 2,
        height / 2,
        -height / 2,
        0.1,
        1000,
      )
      camera.position.z = 100

      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
      renderer.setSize(width, height)
      mount.appendChild(renderer.domElement)

      // Create multiple line geometries for the waves
      const numWaves = 5
      const waveMaterial = new THREE.LineDashedMaterial({
        color: 0x6c63ff,
        dashSize: 3, // Length of dashes
        gapSize: 2, // Space between dashes
        linewidth: 1,
      })

      for (let i = 0; i < numWaves; i++) {
        const waveGeometry = new THREE.BufferGeometry()
        const vertices = new Float32Array(200 * 3) // 200 points, 3 values (x, y, z) each
        waveGeometry.setAttribute(
          'position',
          new THREE.BufferAttribute(vertices, 3),
        )

        const wave = new THREE.Line(waveGeometry, waveMaterial.clone())
        wave.computeLineDistances() // Necessary for dashed lines
        wave.position.z = -i * 5 // Stagger waves in Z-axis
        lines.push(wave)
        scene.add(wave)
      }
    }

    const updateWave = () => {
      if (!analyserRef.current || !dataArrayRef.current) return

      analyserRef.current.getByteTimeDomainData(dataArrayRef.current)

      const waveSpeed = 0.02 // Controls speed of wave oscillation
      const damping = 0.98 // Damping factor to reduce amplitude over time

      const currentAmplitude = waveAmplitudeRef.current
      const audioInput =
        dataArrayRef.current.reduce(
          (sum, val) => sum + Math.abs(val - 128),
          0,
        ) / dataArrayRef.current.length

      // Blend current audio input with sustained amplitude
      waveAmplitudeRef.current = Math.max(
        audioInput / 128,
        currentAmplitude * damping,
      )

      const waveAmplitude = waveAmplitudeRef.current * 720 // Scale amplitude
      const innerWidth = 410 // 450px width minus 20px padding on both sides
      lines.forEach((line, waveIndex) => {
        const positions = (line.geometry as THREE.BufferGeometry).attributes
          .position.array as Float32Array
        const phaseOffset = waveIndex * 0.3 // Slight phase offset for each wave
        const numPoints = 200 // Total points in the wave
        const wavelength = Math.PI * 2 // Full sine wave cycle

        for (let i = 0; i < numPoints; i++) {
          const progress = i / (numPoints - 1) // Normalized progress across the wave (0 to 1)
          const x = progress * innerWidth - innerWidth / 2 // Normalize X values between -205 and 205

          // Generate a smooth wave with aligned start/end points
          const y =
            Math.sin(
              progress * wavelength +
                phaseOffset +
                waveSpeed * performance.now() * 0.001,
            ) *
            waveAmplitude *
            Math.cos(progress * Math.PI) // Cosine envelope for smooth edges
          positions[i * 3] = x // X coordinate
          positions[i * 3 + 1] = y // Y coordinate
          positions[i * 3 + 2] = 0 // Z coordinate
        }
        line.geometry.attributes.position.needsUpdate = true
        line.computeLineDistances() // Update for dashed lines
      })
    }

    const animate = () => {
      animationFrameId = requestAnimationFrame(animate)
      updateWave()
      renderer.render(scene, camera)
    }

    const cleanup = () => {
      cancelAnimationFrame(animationFrameId)
      lines.forEach((line) => scene.remove(line))
      lines = []
      renderer.dispose()
      mountRef.current?.removeChild(renderer.domElement)
    }

    createWaveVisualization()
    animate()

    return cleanup // Cleanup on unmount
  }, [])

  const startRecording = async () => {
    try {
      const audioContext = new AudioContext()
      const analyser = audioContext.createAnalyser()
      analyser.fftSize = 256

      const constraints: MediaStreamConstraints = {
        audio: { deviceId: deviceId ? { exact: deviceId } : undefined },
      }

      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints)
      mediaStreamRef.current?.getTracks().forEach((track) => {
        console.log(`Stopping track: ${track.kind}, state: ${track.readyState}`)
        track.stop()
      })
      mediaStreamRef.current = mediaStream

      const source = audioContext.createMediaStreamSource(mediaStream)
      audioNodeRef.current = source.connect(analyser)

      audioContextRef.current = audioContext
      analyserRef.current = analyser
      dataArrayRef.current = new Uint8Array(analyser.frequencyBinCount)
    } catch (error) {
      console.error('Visualizer: Error starting recording:', error)
    }
  }

  const stopRecording = () => {
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => {
        console.log(`Stopping track: ${track.kind}, state: ${track.readyState}`)
        track.stop()
      })
      mediaStreamRef.current = null
    } else {
      console.log('No active MediaStream to stop.')
    }

    if (audioNodeRef.current) {
      audioNodeRef.current.disconnect()
      audioNodeRef.current = null
    } else {
      console.log('No active audio node to disconnect.')
    }

    if (audioContextRef.current) {
      audioContextRef.current
        .close()
        .then(() => {
          console.log('AudioContext closed successfully.')
        })
        .catch((err) => {
          console.error('Error closing AudioContext:', err)
        })
      audioContextRef.current = null
    } else {
      console.log('No active AudioContext to close.')
    }

    console.log('Recording stopped successfully.')
  }

  useEffect(() => {
    if (isRecording) {
      startRecording()
    }

    return () => {
      stopRecording() // Ensure cleanup is called on unmount
    }
  }, [isRecording, deviceId])

  return (
    <div
      ref={mountRef}
      style={{
        width: '450px',
        height: '160px',
        background: 'white',
      }}
    ></div>
  )
}

export default AudioVisualizer
