import { useEffect, useRef, useState } from 'react'
import {
  EditorState,
  convertToRaw,
  Modifier,
  convertFromHTML,
  ContentState,
  ContentBlock,
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { htmlToMarkdown, markdownToHtml } from './Parser'
import { OrderedMap } from 'immutable'
import { Template } from '../../../types/domain'

export interface EditorContentChanged {
  html: string
  markdown: string
}

export interface EditorProps {
  value?: string
  onChange?: (changes: EditorContentChanged) => void
  placeholder?: string
  onTemplateApplied?: (template: Template) => void
}

export default function MarkdownEditor(props: EditorProps) {
  const [editorState, setEditorState] = useState<EditorState>(() => {
    let contentState
    if (props.value) {
      const converted = convertFromHTML(markdownToHtml(props.value))
      contentState = ContentState.createFromBlockArray(
        converted.contentBlocks,
        converted.entityMap,
      )
    } else {
      contentState = ContentState.createFromText('')
    }
    return EditorState.createWithContent(contentState)
  })

  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const onEditorStateChange = (state: EditorState) => {
    if (isMounted.current) {
      setEditorState(state)

      if (props.onChange) {
        const html = draftToHtml(convertToRaw(state.getCurrentContent()))
        props.onChange({
          html,
          markdown: htmlToMarkdown(html),
        })
      }
    }
  }

  const handleDrop = (event: any) => {
    event.preventDefault()
    const templateData = event.dataTransfer.getData('template')

    if (templateData) {
      try {
        const template = JSON.parse(templateData) as Template
        const htmlContent = markdownToHtml(template.content)
        const updatedEditorState = insertTextAsHTML(htmlContent, editorState)

        onEditorStateChange(updatedEditorState)

        if (props.onTemplateApplied) {
          props.onTemplateApplied(template)
        }
      } catch (error) {
        console.error('Error processing template:', error)
      }
    }
  }

  const insertTextAsHTML = (html: string, editorState: EditorState) => {
    const blocksFromHTML = convertFromHTML(html)
    const contentState = editorState.getCurrentContent()
    const selectionState = editorState.getSelection()

    const newContentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    )

    const finalContentState = Modifier.replaceWithFragment(
      contentState,
      selectionState,
      newContentState.getBlockMap() as OrderedMap<string, ContentBlock>,
    )

    return EditorState.push(editorState, finalContentState, 'insert-fragment')
  }

  return (
    <div
      onDrop={(event) => handleDrop(event)}
      onDragOver={(event) => event.preventDefault()}
      className="d-flex flex-column m-auto flow"
    >
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        placeholder={props.placeholder || 'Provide summary...'}
        toolbar={{
          options: ['inline', 'list', 'history'],
          inline: { options: ['bold', 'italic'] },
          list: { options: ['ordered'] },
        }}
        editorClassName="editor-main"
        toolbarClassName="editor-toolbar"
        wrapperClassName="editor-wrapper"
      />
    </div>
  )
}
