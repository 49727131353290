import React from 'react'
import { useAttachments } from '../../../hooks/useAttachment/useAttachmentBase'
import { Spinner } from 'react-bootstrap'
import { Attachment, LIBRARY_TYPE } from '../../../types/domain'
import { SearchInput } from '../../core/SearchInput/SearchInput'
import { LibraryCard } from '../../core/LibraryCard/LibraryCard'
import { AttachmentPreview } from '../../core/AttachmentPreview/AttachmentPreview'

export const AttachmentLibrary: React.FC = () => {
  const { data: attachments = [], isLoading } = useAttachments()
  const [searchTerm, setSearchTerm] = React.useState('')

  const handleAttachmentDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    attachment: Attachment,
  ) => {
    console.log('attachment', attachment)
    e.dataTransfer.setData('attachment', JSON.stringify(attachment))
  }

  const getContentByType = (items: Attachment[], type: LIBRARY_TYPE) => {
    const filtered = items.filter(attachment => 
      attachment.displayName.toLowerCase().includes(searchTerm.toLowerCase())
    )

    if (type === LIBRARY_TYPE.ORGANIZATION) {
      return filtered.filter(item => item.organizationId != null)
    }
    if (type === LIBRARY_TYPE.PERSONAL) {
      return filtered.filter(item => item.organizationId === null && item.userId != null)
    }
    return filtered.filter(item => item.organizationId === null && item.userId === null)
  }

  if (isLoading) return <Spinner />

  return (
    <div className="library-list">
    <div className='library-list-wrapper'>
    <div className='library-list__search-wrapper'>
    <SearchInput onChange={setSearchTerm} value={searchTerm} />
    </div>      
  <div className="library-list__section">
    <div className="library-list__section-title">Organization</div>
    <div className="library-list__section-list-wrapper">
    {getContentByType(attachments, LIBRARY_TYPE.ORGANIZATION).map(attachment => (
           <div 
                className="library-list__section-item-wrapper"
           key={attachment.id}
           draggable
           onDragStart={(e) => handleAttachmentDragStart(e, attachment)}>
    <div className="library-list__section-item">
               <LibraryCard
                title={attachment.displayName}
                date={attachment.createdAt}
                size='small'
                content={<AttachmentPreview
                    size='small'
                    metadata={attachment.metadata || {
                        title: '',
                        description: '',
                        domain: '',
                    }}              
                />}
                />  
    </div>
             </div>
        ))}
    </div>
</div>
<div className="library-list__section">
    <div className="library-list__section-title">Personal</div>
    <div className="library-list__section-list-wrapper">
    {getContentByType(attachments, LIBRARY_TYPE.PERSONAL).map(attachment => (
           <div 
                className="library-list__section-item-wrapper"
           key={attachment.id}
           draggable
           onDragStart={(e) => handleAttachmentDragStart(e, attachment)}>
    <div className="library-list__section-item">
               <LibraryCard
                title={attachment.displayName}
                date={attachment.createdAt}
                size='small'
                content={<AttachmentPreview
                size='small'
                    metadata={attachment.metadata || {
                        title: '',
                        description: '',
                        domain: '',
                    }}              
                />}
                />  
    </div>
             </div>
        ))}

    </div>
</div>
<div className="library-list__section">
    <div className="library-list__section-title">General</div>
    <div className="library-list__section-list-wrapper">
    {getContentByType(attachments, LIBRARY_TYPE.DEFAULT).map(attachment => (
           <div 
                className="library-list__section-item-wrapper"
           key={attachment.id}
           draggable
           onDragStart={(e) => handleAttachmentDragStart(e, attachment)}>
    <div className="library-list__section-item">
               <LibraryCard
                title={attachment.displayName}
                date={attachment.createdAt}
                size='small'
                content={<AttachmentPreview
                    metadata={attachment.metadata || {
                        title: '',
                        description: '',
                        domain: '',
                    }}              
                />}
                />  
    </div>
             </div>
        ))}
    </div>
    </div>
  </div>
</div>
  )
}
