import * as pako from 'pako'
import { dataToFrames } from 'qrloop'

const SECRET_KEY = 'ditto_patient_2024_shared_key'

const generateEncryptionKey = async (sharedKey: string): Promise<CryptoKey> => {
  const encoder = new TextEncoder()
  const keyData = encoder.encode(sharedKey)
  const hash = await crypto.subtle.digest('SHA-256', keyData)

  return crypto.subtle.importKey('raw', hash, { name: 'AES-GCM' }, false, [
    'encrypt',
  ])
}

const encryptData = async (
  data: Uint8Array,
  key: CryptoKey,
): Promise<Uint8Array> => {
  const iv = crypto.getRandomValues(new Uint8Array(12))

  const ciphertext = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    key,
    data,
  )

  const combined = new Uint8Array(iv.length + ciphertext.byteLength)
  combined.set(iv)
  combined.set(new Uint8Array(ciphertext), iv.length)
  return combined
}

const base64EncodeUint8Array = (arr: Uint8Array): string => {
  let binary = ''
  arr.forEach((byte) => (binary += String.fromCharCode(byte)))
  return btoa(binary)
}

const base64DecodeToUint8Array = (base64: string): Uint8Array => {
  const binaryString = atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes
}

export const generateQRCodeData = async (
  data: Object,
): Promise<string | null> => {
  try {
    const jsonString = JSON.stringify(data)

    const compressedData = pako.deflate(jsonString)
    const base64Data = base64EncodeUint8Array(compressedData)

    const key = await generateEncryptionKey(SECRET_KEY)
    const encryptedData = await encryptData(
      base64DecodeToUint8Array(base64Data),
      key,
    )

    const customUrl = `dittopatient://event?data=${encodeURIComponent(base64EncodeUint8Array(encryptedData))}`
    return customUrl
  } catch (error) {
    console.error('Error generating QR code data:', error)
    return null
  }
}

const splitDataIntoChunks = (
  data: Uint8Array,
  chunkSize: number,
): Uint8Array[] => {
  let chunks = []
  for (let i = 0; i < data.length; i += chunkSize) {
    chunks.push(data.slice(i, i + chunkSize))
  }
  return chunks
}

export const generateMultipleQRCodes = async (
  data: Object,
  chunkSize: number,
): Promise<string[]> => {
  try {
    const jsonString = JSON.stringify(data)
    const compressedData = pako.deflate(jsonString)
    const base64Data = base64EncodeUint8Array(compressedData)

    const key = await generateEncryptionKey(SECRET_KEY)
    const encryptedData = await encryptData(
      base64DecodeToUint8Array(base64Data),
      key,
    )

    const dataChunks = splitDataIntoChunks(encryptedData, chunkSize)
    const totalChunks = dataChunks.length
    return dataChunks.map((chunk, index) => {
      const chunkString = base64EncodeUint8Array(chunk)
      return `dittopatient://event?total=${totalChunks}&chunk=${index}&data=${encodeURIComponent(chunkString)}`
    })
  } catch (error) {
    console.error('Error generating QR code data:', error)
    return []
  }
}

export const generateMultipleQRCodeLoop = async (
  data: Object,
): Promise<string[]> => {
  try {
    const jsonString = JSON.stringify(data)
    const compressedData = pako.deflate(jsonString)
    const base64Data = base64EncodeUint8Array(compressedData)

    const key = await generateEncryptionKey(SECRET_KEY)
    const encryptedData = await encryptData(
      base64DecodeToUint8Array(base64Data),
      key,
    )
    const frames = dataToFrames(encryptedData.toString())
    return frames
  } catch (error) {
    console.error('Error generating QR code data:', error)
    return []
  }
}
