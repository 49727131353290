import React from 'react'
import Modal from '../../shared/Modal/Modal'
import Button from '../Button/Button'

interface ConfirmationModalProps {
  show: boolean
  onHide: () => void
  onConfirm: () => void
  message: string
  title?: string
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  show,
  onHide,
  onConfirm,
  message,
  title = 'Confirm Action',
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="max-w-500px"
      contentClassName="rounded-2"
    >
      <Modal.Body className="p-600 d-flex flex-column align-items-center">
        <h4 className="mb-500">{title}</h4>
        <p>{message}</p>
        <div className="d-flex justify-content-end mt-400">
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onConfirm()
              onHide()
            }}
          >
            Confirm
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ConfirmationModal
