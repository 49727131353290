import { Organization } from '../../types/domain'
import { getEnvironmentVariable } from '../../utils/environment'
import {
  useAuthQuery,
  useAuthMutationPost,
  useAuthMutationPut,
  useAuthMutationDelete,
} from '../useAuthQuery/useAuthQuery'

const API_BASE_URL = `${getEnvironmentVariable('REACT_APP_USER_SERVICE_API_URL') || ''}/organizations`

export const useOrganization = () => {
  return useAuthQuery<Organization>(['organization'], {
    baseUrl: API_BASE_URL,
    path: '/',
  })
}

export const useCreateOrganization = () => {
  return useAuthMutationPost<Organization, Error, Partial<Organization>>({
    baseUrl: API_BASE_URL,
    path: '/',
  })
}

export const useUpdateOrganization = (orgId: number) => {
  return useAuthMutationPut<Organization, Error, Partial<Organization>>({
    baseUrl: API_BASE_URL,
    path: `/${orgId}`,
  })
}

export const useDeleteOrganization = (orgId: number) => {
  return useAuthMutationDelete<void, Error>({
    baseUrl: API_BASE_URL,
    path: `/${orgId}`,
  })
}

export const useRemoveUserFromOrganization = (
  orgId: number,
  userId: number,
) => {
  return useAuthMutationDelete<void, Error>({
    baseUrl: API_BASE_URL,
    path: `/${orgId}/users/${userId}`,
  })
}

export const useCreateOrganizationInvitation = (orgId: number) => {
  return useAuthMutationPost<void, Error, { email: string }>({
    baseUrl: API_BASE_URL,
    path: `/${orgId}/invitations`,
  })
}
