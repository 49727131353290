import React, { PropsWithChildren } from 'react'
import cx from 'classnames'
import Button from './Button'
import {
  getIconClass,
  IconName,
} from '../../../utils/getIconClass/getIconClass'

type LabelIconButtonProps = PropsWithChildren<{
  id: string
  onClick?: (ev: React.MouseEvent) => void
  icon: IconName
  disabled?: boolean
  bordered?: boolean
  circled?: boolean
  className?: string
  iconClasses?: string
}>

const LabelIconButton: React.FC<LabelIconButtonProps> = ({
  children,
  onClick,
  icon,
  disabled,
  bordered,
  circled = true,
  className,
  iconClasses,
  ...rest
}) => {
  return (
    <Button
      variant="label-icon"
      onClick={onClick}
      disabled={disabled}
      className={className}
      {...rest}
    >
      <span
        className={cx(
          getIconClass(icon),
          'icon-size-2x',
          'flex-shrink-0',
          iconClasses,
          { bordered },
          { 'icon-circle': circled },
        )}
      />
      {children}
    </Button>
  )
}

export default LabelIconButton
