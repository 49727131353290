import { WithClassName } from '../Icon'

export const Mic = ({ className }: WithClassName) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path fillRule="evenodd" clipRule="evenodd"
          d="M7.16671 4.16668C7.16671 2.32573 8.65909 0.833344 10.5 0.833344C12.341 0.833344 13.8334 2.32573 13.8334 4.16668V9.16668C13.8334 11.0076 12.341 12.5 10.5 12.5C8.65909 12.5 7.16671 11.0076 7.16671 9.16668V4.16668ZM10.5 2.50001C9.57957 2.50001 8.83337 3.2462 8.83337 4.16668V9.16668C8.83337 10.0872 9.57957 10.8333 10.5 10.8333C11.4205 10.8333 12.1667 10.0872 12.1667 9.16668V4.16668C12.1667 3.2462 11.4205 2.50001 10.5 2.50001Z"
          fill="#5A545C" />
    <path
      d="M11.3334 15.7811C11.9225 15.7068 12.5004 15.5541 13.0513 15.3259C13.8601 14.9908 14.595 14.4998 15.2141 13.8807C15.8331 13.2617 16.3242 12.5267 16.6592 11.7179C16.9943 10.9091 17.1667 10.0422 17.1667 9.16668H15.5C15.5 9.82329 15.3707 10.4735 15.1194 11.0801C14.8682 11.6867 14.4999 12.2379 14.0356 12.7022C13.5713 13.1665 13.0201 13.5348 12.4135 13.7861C11.8068 14.0373 11.1567 14.1667 10.5 14.1667C9.84343 14.1667 9.19325 14.0373 8.58662 13.7861C7.98 13.5348 7.4288 13.1665 6.96451 12.7022C6.50021 12.2379 6.13192 11.6867 5.88064 11.0801C5.62937 10.4735 5.50004 9.82329 5.50004 9.16668H3.83337C3.83337 10.0422 4.00581 10.9091 4.34084 11.7179C4.67587 12.5267 5.16694 13.2617 5.786 13.8807C6.40505 14.4998 7.13998 14.9908 7.94882 15.3259C8.49972 15.5541 9.07757 15.7068 9.66671 15.7811V17.5H7.16671V19.1667H13.8334V17.5H11.3334V15.7811Z"
      fill="#5A545C" />
  </svg>
)
