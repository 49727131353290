import * as React from 'react'
import { NavigationChip } from './NavigationChip'
import styles from './Navigation.module.scss'
import useStore, { NavigationItem, NavigationTab } from '../store'

interface NavigationProps {
  navigationItems: NavigationItem[]
}

export const Navigation: React.FC<NavigationProps> = ({ 
  navigationItems,
}) => {
  const navigationState = useStore(state => state.navigationState)
  const setNavigationState = useStore(state => state.setNavigationState)

  const handleTabClick = (tab: NavigationTab) => {
    const item = navigationItems.find(i => i.label === tab)
    if (item && !item.disabled) {
      setNavigationState(tab)
    }
  }

  const visibleItems = navigationItems.filter(item => item.isVisible)

  return (
    <div className={styles.navigation} role="tablist">
      {visibleItems.map((item, index) => (
        <NavigationChip
          key={index}
          label={item.label}
          isActive={item.label === navigationState}
          disabled={item.disabled}
          onClick={() => handleTabClick(item.label)}
        />
      ))}
    </div>
  )
}
