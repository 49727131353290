import i18n from 'i18next'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const NL_LANGUAGE = 'nl-NL'
export const EN_LANGUAGE = 'en-GB'
export const FALLBACK_LANGUAGE = EN_LANGUAGE
const PRIMARY_LANGUAGE = FALLBACK_LANGUAGE
const DEBUG_MODE = false
const ESCAPE_VALUE = false

const Backend = HttpBackend
const loadPath = '/locales/{{lng}}/{{ns}}.json'

const i18nConfig = {
  lng: PRIMARY_LANGUAGE,
  fallbackLng: FALLBACK_LANGUAGE,
  debug: DEBUG_MODE,
  interpolation: { escapeValue: ESCAPE_VALUE },
  backend: { loadPath },
  supportedLngs: [EN_LANGUAGE, NL_LANGUAGE],
}

export const languageInit = initializei18n(i18nConfig)

function initializei18n(config: typeof i18nConfig) {
  return i18n.use(Backend).use(initReactI18next).init(config)
}

export default i18n
