import { create } from 'zustand'

type SettingsPage = 'profile' | 'privacy' | 'support' | 'dittoai' | 'vocabulary'
type SupportSubPage = 'main' | 'faq'

interface SettingsState {
  page: SettingsPage
  supportSubPage: SupportSubPage
  setPage: (page: SettingsPage) => void
  setSupportSubPage: (subPage: SupportSubPage) => void
}

const useSettingsStore = create<SettingsState>((set) => ({
  page: 'profile',
  supportSubPage: 'main',
  setPage: (page) => set({ page }),
  setSupportSubPage: (subPage) => set({ supportSubPage: subPage }),
}))

export default useSettingsStore 