import React from 'react'
import { Container } from 'react-bootstrap'
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import './App.scss'
import FaqContainer from './components/features/Settings/Faq/FaqContainer'
import { NavigationPanel } from './components/features/NavigationPanel/NavigationPanel'
import OrganizationPage from './components/features/Organization/OrganizationPage'
import OrganizationRegistration from './components/features/Organization/OrganizationRegistration'
import TermsPage from './components/features/Terms/TermsPage'
import RegistrationPage from './components/RegistrationPage'
import { Header } from './components/shared/Layout/Header'
import ContentLibraryPage from './pages/ContentLibraryPage'
import ConsultationPage from './pages/ConsultationPage'
import LandingPage from './pages/LandingPage'
import HistoryPage from './pages/HistoryPage'
import SettingsPage from './pages/SettingsPage'

const PublicRoutes: React.FC = () => {
  const { isAuthenticated } = useAuth0()

  if (isAuthenticated) {
    return <Navigate to="/consultation" replace />
  }

  return (
    <Routes>
      <Route element={<UnauthenticatedLayout />}>
        <Route path="/" element={<LandingPage />} />
        <Route path="/register" element={<RegistrationPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  )
}

const ProtectedRoutes: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0()

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  }

  return (
    <Routes>
      <Route element={<AuthenticatedLayout />}>
        <Route path="/" element={<Navigate to="/consultation" replace />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/support/faq" element={<FaqContainer />} />
        <Route path="/library" element={<ContentLibraryPage />} />
        <Route path="/organization" element={<OrganizationPage />} />
        <Route path="/consultation" element={<ConsultationPage />} />
        <Route path="/history" element={<HistoryPage />} />
        <Route
          path="/organization/register"
          element={<OrganizationRegistration />}
        />
        <Route path="*" element={<Navigate to="/consultation" replace />} />
      </Route>
    </Routes>
  )
}

const UnauthenticatedLayout: React.FC = () => (
  <>
    <main className="app-main">
      <Outlet />
    </main>
  </>
)

const AuthenticatedLayout: React.FC = () => (
  <>
    <Header />
    <NavigationPanel />
    <main className="app-main">
      <Outlet />
      {/*<Footer />*/}
    </main>
  </>
)

const App: React.FC = () => {
  const { isLoading, isAuthenticated } = useAuth0()

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <Router>
      <Container fluid className="vh-100">
        <Routes>
          <Route
            path="*"
            element={isAuthenticated ? <ProtectedRoutes /> : <PublicRoutes />}
          />
        </Routes>
      </Container>
    </Router>
  )
}

export default App
