import {
  components,
  CSSObjectWithLabel,
  GroupBase,
  InputProps,
  default as ReactSelect,
  Props as SelectProps,
  Theme,
} from 'react-select'
import { MultiValue } from './MultiValue'

export const control = (base: any, state: any) => ({
  ...base,
  height: '52px',
  minHeight: '52px',
  minWidth: '300px',
  boxShadow: 'none',
  borderColor: state.isFocused ? '#6e7272' : base.borderColor,
  '&:hover': { borderColor: '#6e7272' },
})

const styles = {
  control: control,
  multiValueRemove: (base: CSSObjectWithLabel) => ({
    ...base,
    '&:hover': { backgroundColor: '#B2D4DD', color: '#333333' },
  }),
  multiValue: (base: CSSObjectWithLabel) => ({
    ...base,
    borderRadius: '5px',
    backgroundColor: '#E5F1F4',
  }),
  singleValue: (base: CSSObjectWithLabel) => ({
    ...base,
    borderRadius: '5px',
    padding: '5px',
    color: '#333',
    backgroundColor: '#E5F1F4',
  }),
  valueContainer: (css: any) => ({
    ...css,
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  menu: (base: any) => ({
    ...base,
    width: 'max-content',
    minWidth: '300px',
  }),
  option: (base: any) => ({
    ...base,
    cursor: 'pointer',
    minHeight: '52px',
    fontSize: '16px',
    alignContent: 'center',
  }),
}

const Input = <Option,>(props: InputProps<Option>) => (
  <components.Input {...props} />
)

export const Select = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectProps<Option, IsMulti, Group>,
) => {
  const newProps = {
    ...props,
    styles: {
      ...styles,
      ...props.styles,
    },
    components: {
      ...components,
      MultiValue,
      Input,
      ...props.components,
    },
    theme: (theme: Theme) => ({
      ...theme,
      borderRadius: 6,
      colors: {
        ...theme.colors,
        primary75: '#EDF8FE',
        primary50: '#EDF8FE',
        primary25: '#EDF8FE',
        primary: '#797980',
      },
    }),
  }
  return <ReactSelect {...newProps} />
}
