import React, { useState, useEffect } from 'react'
import Modal from '../../shared/Modal/Modal'
import QRCode from 'react-qr-code'
import Button from '../../core/Button/Button'

interface QRModalProps {
  show: boolean
  onHide: () => void
  qrData: string[]
}

const QRModalStreaming: React.FC<QRModalProps> = ({ show, onHide, qrData }) => {
  const [currentQRIndex, setCurrentQRIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentQRIndex((prevIndex) => (prevIndex + 1) % qrData.length)
    }, 2000)
    return () => clearInterval(interval)
  }, [qrData])

  return (
    <Modal
      show={show}
      dialogClassName="max-w-600px"
      contentClassName="rounded-2"
      onHide={onHide}
    >
      <Modal.Body className="p-600 d-flex flex-column align-items-center">
        <h3>Dynamic QR Code</h3>
        <QRCode size={400} value={qrData[currentQRIndex]} />
        <div className="mt-2">
          <p>
            QR Code {currentQRIndex + 1} of {qrData.length}
          </p>
        </div>
        <Button
          variant="secondary"
          type="button"
          onClick={onHide}
          className="mt-3"
        >
          Close
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default QRModalStreaming
