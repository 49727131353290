import React from 'react'
import styles from './ChatMessage.module.scss'
import { ChatMessageProps } from './types'

export const ChatMessage: React.FC<ChatMessageProps> = ({ text, isUser }) => {
  return (
    <div
      className={`${styles.messageContainer} ${isUser ? styles.userMessage : styles.botMessage}`}
    >
      <div className={styles.messageContent}>
        <div
          className={`${styles.messageText} ${isUser ? styles.userMessageText : ''}`}
        >
          {text}
        </div>
      </div>
    </div>
  )
}
