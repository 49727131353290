import React, { useState } from 'react'
import AudioVisualizer from './AudioVisualizer'
import { useSendSoundToApi } from '../../../../hooks/Ai/useSendSoundToApi/useSendSoundToApi'
import { t } from 'i18next'
import Button from '../../../core/Button/Button'
import useStore, { Page } from '../store'

const RecordingInProgress: React.FC = () => {
  const [isRecording, setIsRecording] = useState(true)
  const setPage = useStore((state) => state.setPage)
  useSendSoundToApi(isRecording, () => setPage(Page.aiRecordingSummary))
  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          gap: '16px',
          padding: '16px',
          background: 'white',
          borderRadius: '29px',
        }}
      >
        <AudioVisualizer isRecording={isRecording} />
        <p
          style={{
            fontSize: '14px',
            fontWeight: '400px',
            color: '#000',
            opacity: '0.5',
          }}
        >
          {t('visualizer.state', 'Recording in process...')}
        </p>
        <Button
          disabled={!isRecording}
          onClick={() => {
            setIsRecording(false)
          }}
        >
          {isRecording
            ? t('visualizer.action', 'Stop recording')
            : t('visualizer.action.stopping', 'Stopping recording...')}
        </Button>
      </div>
    </>
  )
}

export default RecordingInProgress
