import React from 'react'
import Icon, { ICON_TYPE } from '../Icon/Icon'
import Button from '../Button/Button'

interface ActionMenuItemProps {
  label: string
  icon?: ICON_TYPE
  onClick: () => void
  variant?: 'danger' | 'default'
}

export const ActionMenuItem: React.FC<ActionMenuItemProps> = ({ label, icon, onClick, variant = 'default' }) => {
  return (
    <Button
      className={`menu-item menu-item--${variant}`}
      onClick={onClick}
    >
      {icon && <Icon icon={icon} />}
      <span className="menu-item__label">{label}</span>
    </Button>
  )
} 