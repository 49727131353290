import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../core/Button/Button'
import OrganizationDetails from './OrganizationDetails'

const OrganizationPage: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div>
      <h1>Organization Page</h1>
      <OrganizationDetails />

      <Button onClick={() => navigate('/dashboard')}>Back to Dashboard</Button>
    </div>
  )
}

export default OrganizationPage
