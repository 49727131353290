import { useAuth0 } from '@auth0/auth0-react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LanguageSwitcher } from '../components/features/LanguageSwitcher/LanguageSwitcher'
import Button from '../components/core/Button/Button'
import { CookieConsentModal } from '../components/features/Auth/Login/CookieConsentModal'

export function LandingPage() {
  const { loginWithRedirect, isLoading } = useAuth0()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="app-landing-page | vh-100 d-flex align-items-start align-items-center flex-column">
      <div className="w-100 d-flex justify-content-end">
        <div className="max-w-300px m-500">
          <LanguageSwitcher onChange={(lng) => i18n.changeLanguage(lng)} />
        </div>
      </div>
      <div className="app-landing-page__content-wrapper">
        <div className="app-landing-page__content">
          <div>
            <img
              src="/media/landing-image.svg"
              alt="Welcome illustration"
              className="welcome-image"
            />
          </div>
          <div className="app-landing-page__content-text-wrapper">
            <p className="app-landing-page__content-title">
              {t('login.welcome', 'Welcome!')}
            </p>
            <p className="app-landing-page__content-subtitle">
              {t('login.portalDescription', 'Some marketing text here')}
            </p>
          </div>
          {isLoading ? (
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          ) : (
            <div className="app-landing-page__content-button-wrapper">
              <Button
                variant="primary"
                onClick={() => navigate('/register')}
                className="app-landing-page__content-signup-button"
              >
                {t('register.button', 'Sign Up')}
              </Button>

              <Button
                variant="secondary"
                onClick={() =>
                  loginWithRedirect({
                    appState: { returnTo: '/dashboard' },
                    authorizationParams: {
                      redirect_uri: window?.location.origin,
                    },
                  })
                }
                className="app-landing-page__content-signin-button"
              >
                {t('login.button', 'Sign In')}
              </Button>
            </div>
          )}
        </div>

        <div></div>
      </div>
      <CookieConsentModal />
    </div>
  )
}

export default LandingPage
