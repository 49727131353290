import { Accordion } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { questionsMap, SECTIONS } from './constants'

export const FaqSection = ({ id, title }: { title: string; id: SECTIONS }) => {
  const { t } = useTranslation()
  return (
    <div className="settings-card">
      <h3 className="settings-card-header mb-600">{title}</h3>
      <Accordion>
        {questionsMap[id]?.().map((item, i) => (
          <Accordion.Item eventKey={i.toString()} key={i}>
            <Accordion.Header>{t(item.question)}</Accordion.Header>
            <Accordion.Body>{t(item.answer)}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  )
}

export default FaqSection
