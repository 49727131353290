import { Template, Result } from '../../types/domain'
import { getEnvironmentVariable } from '../../utils/environment'
import {
  useAuthQuery,
  useAuthMutationPost,
  useAuthMutationPut,
  useAuthMutationDelete,
} from '../useAuthQuery/useAuthQuery'

const API_BASE_URL = getEnvironmentVariable('REACT_APP_API_URL') || ''

const handleResult = <T,>(result: Result<T> | undefined | null): T => {
  if (result === undefined || result === null) {
    return undefined as T;
  }
  
  if (!result.isSuccessful || result._value === null) {
    throw new Error(result._error || 'Operation failed')
  }
  return result._value
}

export const useTemplates = (params?: Record<string, any>) => {
  const result = useAuthQuery<Result<Template[]>>(['templates', params], {
    baseUrl: API_BASE_URL,
    path: '/templates',
    params,
  })

  return {
    ...result,
    data: result.data ? handleResult(result.data) : undefined
  }
}

export const useTemplate = (templateId: string) => {
  const result = useAuthQuery<Result<Template>>(['template', templateId], {
    baseUrl: API_BASE_URL,
    path: `/templates/${templateId}`,
  })

  return {
    ...result,
    data: result.data ? handleResult(result.data) : undefined
  }
}

export const useCreateTemplate = () => {
  const mutation = useAuthMutationPost<Result<Template>, Error, Partial<Template>>({
    baseUrl: API_BASE_URL,
    path: '/templates',
  })

  return {
    ...mutation,
    mutateAsync: async (variables: Partial<Template>) => {
      const result = await mutation.mutateAsync(variables)
      return handleResult(result)
    }
  }
}

export const useUpdateTemplate = () => {
  const mutation = useAuthMutationPut<
    Result<Template>,
    Error,
    { id: number } & Partial<Template>
  >({
    baseUrl: API_BASE_URL,
    path: '/templates/{id}',
  })

  return {
    ...mutation,
    mutateAsync: async (variables: { id: number } & Partial<Template>) => {
      const result = await mutation.mutateAsync(variables)
      return handleResult(result)
    }
  }
}

export const useDeleteTemplate = () => {
  const mutation = useAuthMutationDelete<void, Error, { id: number }>({
    baseUrl: API_BASE_URL,
    path: '/templates/{id}',
  })

  return {
    ...mutation,
    mutateAsync: async (variables: { id: number }) => {
      await mutation.mutateAsync(variables)
      return undefined as void
    }
  }
}
