import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {
  useAttachments,
  useCreateAttachment,
  useUpdateAttachment,
  useDeleteAttachment,
} from '../../../../hooks/useAttachment/useAttachmentBase'
import { useAuth } from '../../../../hooks/useAuth'
import useRole from '../../../../hooks/useRole'
import Button from '../../../core/Button/Button'
import { SearchInput } from '../../../core/SearchInput/SearchInput'
import ConfirmationModal from '../../../core/ConfirmationModal/ConfirmationModal'
import AttachmentManagementModal from './AttachmentManagementModal'
import { Attachment } from '../../../../types/domain'
import { useQueryClient } from '@tanstack/react-query'
import { AttachmentCard } from './AttachmentCard'
import useLibraryStore from '../store'
import { Toast } from '../../../core/Toast/Toast'

const AttachmentManagement: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { isManager } = useRole()
  const queryClient = useQueryClient()

  const { 
    attachmentModal, 
    openAttachmentModal, 
    closeAttachmentModal,
    deleteModal,
    openDeleteModal,
    closeDeleteModal,
    showToast 
  } = useLibraryStore()

  const { data: attachments = [], isLoading } = useAttachments()
  const { mutateAsync: addAttachment } = useCreateAttachment()
  const { mutateAsync: updateAttachment } = useUpdateAttachment()
  const { mutateAsync: deleteAttachment } = useDeleteAttachment()

  const [searchQuery, setSearchQuery] = useState('')
  const [isForOrganization, setIsForOrganization] = useState(false)

  const handleSubmit = async (data: Partial<Attachment>) => {
    try {
      if (attachmentModal.editItem?.id) {
        const id = attachmentModal.editItem.id
        await updateAttachment({ ...data, id })
        await queryClient.invalidateQueries({ queryKey: ['attachments'] })
        showToast('success', t('attachment.update.success', 'Attachment updated successfully'))
        closeAttachmentModal()
      } else {
        await addAttachment(data)
        await queryClient.invalidateQueries({ queryKey: ['attachments'] })
        showToast('success', t('attachment.create.success', 'Attachment created successfully'))
        closeAttachmentModal()
      }
    } catch (error: any) {
      console.error('Error handling attachment:', error)
      const errorMessage = error.details?.message || error.message || t('attachment.operation.error', 'Operation failed. Please try again.')
      showToast('danger', errorMessage)
    }
  }

  const handleEdit = (attachment: Attachment) => {
    openAttachmentModal(attachment)
  }

  const handleDelete = async (id: number) => {
    openDeleteModal(id, 'attachment')
  }

  const handleConfirmDelete = async () => {
    if (deleteModal.itemToDelete?.id) {
      try {
        await deleteAttachment({ id: deleteModal.itemToDelete.id })
        const updatedAttachments = attachments.filter(
          attachment => attachment.id !== deleteModal.itemToDelete?.id
        )
        queryClient.setQueryData(['attachments'], updatedAttachments)
        await queryClient.invalidateQueries({ queryKey: ['attachments'] })
        
        showToast('success', t('attachment.delete.success', 'Attachment deleted successfully'))
        closeDeleteModal()
      } catch (error: any) {
        console.error('Error deleting attachment:', error)
        const errorMessage = error.details?.message || error.message || t('attachment.delete.error', 'Failed to delete attachment')
        showToast('danger', errorMessage)
      }
    }
  }

  const filteredAttachments = searchQuery
    ? attachments?.filter?.((attachment) =>
        [attachment.description, attachment.displayName, attachment.url]
          .filter(Boolean) // Remove undefined/null values
          .some((field) => 
            field?.toLowerCase().includes(searchQuery.toLowerCase())
          ),
      ) || []
    : attachments || []

  const ATTACHMENT_TYPES = {
    Personal: 'Personal',
    Organization: 'Organization',
    General: 'General',
  } as const

  type AttachmentType = (typeof ATTACHMENT_TYPES)[keyof typeof ATTACHMENT_TYPES]

  const groupAttachments = (attachments: Attachment[]) => {
    const initialGroups = Object.values(ATTACHMENT_TYPES).reduce(
      (acc, type) => {
        acc[type] = []
        return acc
      },
      {} as Record<AttachmentType, Attachment[]>,
    )

    return attachments.reduce((acc, attachment) => {
      let type: AttachmentType = ATTACHMENT_TYPES.General
      if (attachment.organizationId !== null)
        type = ATTACHMENT_TYPES.Organization
      if (attachment.userId !== null) type = ATTACHMENT_TYPES.Personal

      acc[type].push(attachment)
      return acc
    }, initialGroups)
  }

  const groupedAttachments = groupAttachments(filteredAttachments)

  return (
    <div className="content-library__content">
      <div className="content-library__content-header">
        <div className="search">
          <SearchInput onChange={setSearchQuery} value={searchQuery} />
        </div>
        <div className="add-button">
          <Button
            icon="plus"
            onClick={() => openAttachmentModal(undefined)}
            variant="secondary"
          >
            {t('attachment.addAttachment', 'Add Attachment')}
          </Button>
        </div>
      </div>

      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center mh-300px">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="content-library__content-section">
          {Object.entries(groupedAttachments).map(([type, attachments]) => (
            <div
              key={type}
              className="content-library__content-section__wrapper"
            >
              <div className="content-library__content-section__title">
                {type}
              </div>
              {attachments.length > 0 ? (
                <div className="content-library__content-section__content">
                  {attachments.map((attachment) => (
                    <AttachmentCard
                      key={attachment.id}
                      attachment={attachment}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                    />
                  ))}
                </div>
              ) : (
                <p className="text-muted">
                  No {type.toLowerCase()} attachments available
                </p>
              )}
            </div>
          ))}
        </div>
      )}

      <AttachmentManagementModal
        show={attachmentModal.show}
        onHide={closeAttachmentModal}
        editAttachment={attachmentModal.editItem as Attachment}
        onSubmit={handleSubmit}
        isManager={isManager}
      />

      <ConfirmationModal
        show={deleteModal.show && deleteModal.itemToDelete?.type === 'attachment'}
        onHide={closeDeleteModal}
        onConfirm={handleConfirmDelete}
        message={t('attachment.confirmDelete', 'Are you sure you want to delete this attachment?')}
      />
    </div>
  )
}

export default AttachmentManagement
