import { Template } from '../../../../types/domain'
import { LibraryCard } from '../../../core/LibraryCard/LibraryCard'
import { AttachmentCounter } from '../../../core/AttachmentCounter/AttachmentCounter'
import Markdown from 'react-markdown'

interface TemplateCardProps {
  template: Template
  onEdit: (template: Template) => void
  onDelete: (id: number | undefined) => void
}

export const TemplateCard: React.FC<TemplateCardProps> = ({
  template,
  onEdit,
  onDelete
}) => {
  const TemplateContent = () => (
    <div className="template-card__content-wrapper">
      <p><Markdown>{template.content}</Markdown></p>
    </div>
  )

  return (
    <LibraryCard
      title={template.displayName}
      date={template.updatedAt ? new Date(template.updatedAt).toLocaleDateString() : undefined}
      content={<TemplateContent />}
      titleAdornment={<AttachmentCounter count={template.attachmentCount} />}
      onEdit={() => onEdit(template)}
      onDelete={() => template.id && onDelete(template.id)}
    />
  )
}