import React from 'react'
import Modal from '../../shared/Modal/Modal'
import QRCode from 'react-qr-code'
import Button from '../../core/Button/Button'

interface QRModalProps {
  show: boolean
  onHide: () => void
  qrData: string
}

const QRModal: React.FC<QRModalProps> = ({ show, onHide, qrData }) => (
  <Modal
    show={show}
    dialogClassName="max-w-600px"
    contentClassName="rounded-2"
    onHide={onHide}
  >
    <Modal.Body className="p-600 d-flex flex-column align-items-center">
      <h3>QR Code</h3>
      <QRCode size={400} value={qrData} />
      <div>
        <Button variant="secondary" type="button" onClick={onHide}>
          Close
        </Button>
      </div>
    </Modal.Body>
  </Modal>
)

export default QRModal
