import React from 'react'
import styles from './RecordingOptions.module.scss'
import { ToggleOptionProps } from './types'
import iconCheck from './../../../core/Icon/icons/svg/Circle_Check.svg'
import iconCheckDisabled from './../../../core/Icon/icons/svg/Circle_Check_Disabled.svg'

export const ToggleOption: React.FC<ToggleOptionProps> = ({
  title,
  description,
  Off,
  Disabled,
  onToggle,
}) => {
  const disabled = Disabled ? 'Disabled' : ''
  return (
    <div className={styles.optionContainer}>
      <div className={styles.optionHeader}>
        <div className={styles.optionTitle}>{title}</div>
        <button
          className={`${styles.toggle} ${Off ? styles['toggleOff' + disabled] : styles['toggleOn' + disabled]}`}
          onClick={Disabled ? () => {} : onToggle}
          aria-pressed={!Off}
          role="switch"
        >
          <div className={styles.toggleIcon}>
            {!Off && (
              <img
                loading="lazy"
                src={Disabled ? iconCheckDisabled : iconCheck}
                alt=""
              />
            )}
          </div>
        </button>
      </div>
      <div className={styles.optionDescription}>{description}</div>
    </div>
  )
}
