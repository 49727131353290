import cx from 'classnames'
import { useState } from 'react'
import { FormControl } from 'react-bootstrap'
import Button from '../Button/Button'
import { ICON_TYPE } from '../Icon/Icon'

interface Props {
  placeholder?: string
  value: string
  onChange: (value: string) => void
}

export function PasswordInput({
  value,
  onChange,
  placeholder,
}: Readonly<Props>) {
  const [show, setShow] = useState(false)

  return (
    <div className="position-relative mt-600">
      <FormControl
        id="password"
        className="pe-700"
        type={show ? 'text' : 'password'}
        placeholder={placeholder}
        onChange={({ target }) => onChange(target.value)}
      />
      <Button
        id="eye"
        className={cx(
          'p-absolute-right-center p-200 me-100 text-gray-500 icon-size-200 cursor-pointer',
          {
            fadeIn: value,
            fadeOut: !value,
          },
        )}
        type="button"
        variant="link"
        onClick={() => setShow((show) => !show)}
        icon={show ? ICON_TYPE.EYE : ICON_TYPE.EYE_SLASH}
      />
    </div>
  )
}
