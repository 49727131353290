import cx from 'classnames'
import { PropsWithChildren, ReactNode } from 'react'

export type PanelVariant =
  | 'border'
  | 'light'
  | 'light-grey'
  | 'primary'
  | 'success'
  | 'shadow'

interface PanelProps {
  variant?: PanelVariant
  header?: ReactNode
  className: string
}

export function Panel({
  children,
  variant,
  className,
  header,
}: PropsWithChildren<PanelProps>) {
  return (
    <>
      {header}
      <div
        className={cx(
          'panel',
          { [`panel-${variant}`]: variant != null },
          className,
        )}
      >
        {children}
      </div>
    </>
  )
}
