import { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Button from '../../../core/Button/Button'
import { PasswordInput } from '../../../core/PasswordInput/PasswordInput'
import LegalLinks from '../../../shared/LegalLinks'
import { AuthContainer } from '../AuthContainer'
import {
  ERROR_MESSAGE,
  hasLength,
  validatePassword,
} from './password_constants'
import { PasswordRequirements } from './PasswordRequirements'
import { useRegisterUser } from '../../../../hooks/useRegistration/useRegistration'
import { RegistrationDto } from '../../../../hooks/useRegistration/useRegistration'

export type RegistrationForm = {
  email: string
  bigNumber: string
  organization?: {
    id: string
    name: string
    role: string
  }
  password: string
  confirmPassword: string
  isTermsAccepted: boolean
  firstName: string
  lastName: string
  jobTitle: string
  phoneNumber?: {
    countryCode: string
    number: string
  }
}

export function Registration() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const [error, setError] = useState<string | null>(null)
  const [invitationData, setInvitationData] = useState<any>(null)

  const methods = useForm<RegistrationForm>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      email: '',
      bigNumber: '',
      organization: undefined,
      confirmPassword: '',
      password: '',
      isTermsAccepted: false,
      firstName: '',
      lastName: '',
      jobTitle: '',
      phoneNumber: { countryCode: '', number: '' },
    },
  })

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
    trigger,
    setValue,
  } = methods

  const password = watch('password')

  const invitationId = new URLSearchParams(location.search).get('invitationId')

  useEffect(() => {
    console.log(invitationId)
    if (invitationId) {
      const fetchInvitation = async () => {
        try {
          // const invitesQuery = query(
          //   collection(db, 'invitations'),
          //   where('invitationId', '==', invitationId),
          // )
          // const invitesSnapshot = await getDocs(invitesQuery)
          // if (!invitesSnapshot.empty) {
          //   const invitationDoc = invitesSnapshot.docs[0]
          //   setInvitationData(invitationDoc.data())
          // } else {
          //   throw new Error('Invitation not found')
          // }
        } catch (error) {
          setError('Invalid invitation')
        }
      }
      fetchInvitation()
    }
  }, [invitationId])

  useEffect(() => {
    if (invitationData) {
      setValue('email', invitationData.email || '', { shouldDirty: true })
      setValue('organization.name', invitationData.organizationName || '', {
        shouldDirty: true,
      })
      setValue('organization.id', invitationData.organizationId || '', {
        shouldDirty: true,
      })
      setValue('organization.role', invitationData.role || '', {
        shouldDirty: true,
      })
    }
  }, [invitationData, setValue])

  const {
    mutate: registerUser,
    isPending,
    error: submitError,
  } = useRegisterUser()

  const onSubmit = async (data: RegistrationForm) => {
    try {
      const registrationData: RegistrationDto = {
        email: data.email,
        password: data.password,
        isTermsAccepted: data.isTermsAccepted,
        firstName: data.firstName,
        lastName: data.lastName,
        jobTitle: data.jobTitle || undefined,
        bigNumber: data.bigNumber || undefined,
        organizationId: data.organization?.id
          ? parseInt(data.organization.id)
          : undefined,
        phoneNumber: data.phoneNumber?.number || undefined,
        phoneCountryCode: data.phoneNumber?.countryCode || undefined,
        invitationId: invitationId || undefined,
        language: i18n.language,
        roleIds: data.organization?.role
          ? [parseInt(data.organization.role)]
          : undefined,
      }

      await registerUser(registrationData, {
        onSuccess: (response) => {
          navigate('/')
        },
        onError: (error) => {
          if (error.errors) {
            const errorMessages = error.errors
              .map((err) => Object.values(err)[0])
              .join(', ')
            setError(errorMessages || t('registration.validationError'))
          } else {
            setError(
              t(
                'registration.error',
                "We're sorry, but we couldn't process your request. Please try again later.",
              ),
            )
          }
        },
      })
    } catch (error) {
      setError(
        t(
          'registration.error',
          "We're sorry, but we couldn't process your request. Please try again later.",
        ),
      )
    }
  }

  return (
    <AuthContainer>
      <h1 className="h4">
        {t('registration.title', 'Register to use Ditto Send HCP Portal')}
      </h1>
      <div className="regular-300 text-center">
        {t(
          'registration.portalDescription',
          'Please add your contact details to register for the Ditto Send HCP Portal.',
        )}
      </div>
      <FormProvider {...methods}>
        <Form noValidate className="w-100">
          <Controller
            rules={{
              required: true,
              validate: {
                match: (v) =>
                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v),
              },
            }}
            control={control}
            name="email"
            render={({ field }) => (
              <div className="position-relative mt-600">
                <Form.Control
                  className="pe-700"
                  {...field}
                  placeholder={t('registration.email', 'Email')}
                  disabled={!!invitationData}
                  value={invitationData?.email || field.value || ''}
                  onChange={(e) => {
                    const nameValue = e.target.value || ''
                    field.onChange(nameValue)
                  }}
                />
              </div>
            )}
          />
          <Controller
            rules={{ required: true }}
            control={control}
            name="firstName"
            render={({ field }) => (
              <Form.Control
                {...field}
                placeholder={t('registration.firstName', 'First Name')}
                className="mt-600"
              />
            )}
          />
          <Controller
            rules={{ required: true }}
            control={control}
            name="lastName"
            render={({ field }) => (
              <Form.Control
                {...field}
                placeholder={t('registration.lastName', 'Last Name')}
                className="mt-600"
              />
            )}
          />
          <Controller
            rules={{ required: true }}
            control={control}
            name="jobTitle"
            render={({ field }) => (
              <Form.Control
                {...field}
                placeholder={t('registration.jobTitle', 'Job Title')}
                className="mt-600"
              />
            )}
          />
          <Controller
            control={control}
            name="phoneNumber.countryCode"
            render={({ field }) => (
              <Form.Control
                {...field}
                placeholder={t('registration.countryCode', 'Country Code')}
                className="mt-600"
              />
            )}
          />
          <Controller
            control={control}
            name="phoneNumber.number"
            render={({ field }) => (
              <Form.Control
                {...field}
                placeholder={t('registration.phoneNumber', 'Phone Number')}
                className="mt-600"
              />
            )}
          />
          <Controller
            rules={{
              required: true,
              validate: {
                hasLength: (v) => v?.length === 11 || ERROR_MESSAGE,
              },
            }}
            control={control}
            name="bigNumber"
            render={({ field }) => (
              <div className="position-relative mt-600">
                <Form.Control
                  className="pe-700"
                  {...field}
                  placeholder={t('registration.bigNumber', 'BIG number')}
                />
              </div>
            )}
          />
          {!!invitationData && (
            <>
              {/* Organization Name Field (Visible to User) */}
              <Controller
                control={control}
                name="organization.name"
                rules={{
                  required: !!invitationData,
                }}
                render={({ field }) => (
                  <div className="position-relative mt-600">
                    <Form.Control
                      className="pe-700"
                      {...field}
                      placeholder={t(
                        'registration.organization',
                        'Organization',
                      )}
                      disabled={!!invitationData}
                      value={
                        invitationData.organizationName || field.value || ''
                      }
                      onChange={(e) => {
                        const nameValue = e.target.value || ''
                        field.onChange(nameValue)
                      }}
                    />
                  </div>
                )}
              />

              {/* Hidden Organization ID Field */}
              <Controller
                control={control}
                name="organization.id"
                render={({ field }) => (
                  <input
                    type="hidden"
                    {...field}
                    value={invitationData.organizationId || ''}
                  />
                )}
              />

              {/* Hidden Organization Role Field */}
              <Controller
                control={control}
                name="organization.role"
                render={({ field }) => (
                  <input
                    type="hidden"
                    {...field}
                    value={invitationData.organizationRole || ''}
                  />
                )}
              />
            </>
          )}

          <Controller
            rules={{
              required: true,
              validate: {
                hasLength: (v) => hasLength(v) || ERROR_MESSAGE,
                meetRequirements: (v) => validatePassword(v) || ERROR_MESSAGE,
                dontMatch: (value, formValues) =>
                  value === formValues.confirmPassword || ERROR_MESSAGE,
              },
            }}
            control={control}
            name="password"
            render={({ field: { onChange, value } }) => (
              <PasswordInput
                value={value}
                onChange={onChange}
                placeholder={t('password.create', 'Create password')}
              />
            )}
          />
          <Controller
            rules={{
              required: true,
            }}
            control={control}
            name="confirmPassword"
            render={({ field: { onChange, value } }) => (
              <PasswordInput
                value={value}
                onChange={(value) => {
                  trigger('password')
                  return onChange(value)
                }}
                placeholder={t('password.confirm', 'Confirm password')}
              />
            )}
          />
          <Controller
            control={control}
            name="isTermsAccepted"
            rules={{ validate: (value) => value || ERROR_MESSAGE }}
            render={({ field: { onChange } }) => (
              <Form.Check
                defaultChecked={false}
                className="me-300 mt-600"
                id="accept.terms.conditions"
              >
                <Form.Check.Input
                  onChange={onChange}
                  data-testid="accept-terms"
                />
                <Form.Check.Label className="fw-semi-bold">
                  <Trans
                    i18nKey="accept.terms.conditions"
                    t={t}
                    defaults="I accept the <Link>Terms and Conditions</Link>"
                    components={{ Link: <Link to="/terms" target="_blank" /> }}
                  />
                </Form.Check.Label>
              </Form.Check>
            )}
          />
        </Form>
      </FormProvider>

      {password && (
        <PasswordRequirements
          isDirty={isDirty}
          passwordErrors={errors.password?.types}
          password={password}
        />
      )}

      <div className="d-grid w-100">
        <Button
          id="btn_register_register"
          isLoading={isPending}
          disabled={!isValid || !isDirty || isPending}
          onClick={handleSubmit(onSubmit)}
        >
          {t('registration.signUp', 'Sign Up')}
        </Button>
      </div>
      {/* {error && <Toast text={error} />} */}
      <LegalLinks />
    </AuthContainer>
  )
}
