import { components, MultiValueProps } from 'react-select'
import { MoreSelectedBadge } from './MoreSelectedBadge'

export const MultiValue = <Option,>({
  index,
  getValue,
  ...props
}: MultiValueProps<Option> | any) => {
  const maxToShow = 2
  const overflow = getValue()
    .slice(maxToShow)
    .map((x: any) => x.label)

  if (index < maxToShow) {
    return (
      <components.MultiValue {...props} index={index} getValue={getValue}>
        {props.children}
      </components.MultiValue>
    )
  }

  if (index === maxToShow) {
    return <MoreSelectedBadge items={overflow} />
  } else {
    return null
  }
}
