import React, { useEffect } from 'react'
import styles from './History.module.scss'
import { HistoryItem } from './HistoryItem'
import { HistoryItemProps } from './types'
import { useAiApiQuery } from '../../../../hooks/Ai/useAiApiQuery/useAiApiQuery'
import useStore from '../store'

export const History: React.FC = () => {
  const {
    data = [],
    isLoading,
    isError,
    error,
  } = useAiApiQuery<HistoryItemProps[]>(['consultation_list'], {
    path: '/consultation/',
  })
  const setConsultId = useStore((state) => state.setConsultId)
  useEffect(() => {
    if (data.length) {
      setConsultId(data[0].consultation_id)
    }
  }, [data])
  return (
    <div className={styles.container}>
      {data.map((item, index) => (
        <HistoryItem
          key={index}
          consultation_id={item.consultation_id}
          title={item.title}
          created_at={item.created_at}
          duration={item.duration}
        />
      ))}
    </div>
  )
}
