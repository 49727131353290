import { WithClassName } from '../Icon'

export const NewTab = (props: WithClassName) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.5 3.5H1.5C0.948 3.5 0.5 3.948 0.5 4.5V14.5C0.5 15.052 0.948 15.5 1.5 15.5H12.5C13.052 15.5 13.5 15.052 13.5 14.5V11.5"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.52441 1.31836H14.1813V6.97521"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1813 1.31768L7.81738 7.68164"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
