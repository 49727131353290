import React, { useState, useRef } from 'react'
import Icon, { ICON_TYPE } from '../Icon/Icon'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { ActionMenuItem } from './ActionMenuItem'

interface Action {
  label: string
  icon?: ICON_TYPE
  onClick: () => void
  variant?: 'danger' | 'default'
}

interface ActionMenuProps {
  actions: Action[]
}

export const ActionMenu: React.FC<ActionMenuProps> = ({ actions }) => {
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(menuRef, () => setIsOpen(false))

  return (
    <div className="more-menu" ref={menuRef}>
      <button 
        className="more-menu__trigger" 
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon icon={ICON_TYPE.MORE_VERTICAL} />
      </button>
      
      {isOpen && (
        <div className="more-menu__content">
          {actions.map((action, index) => (
            <ActionMenuItem
              key={index}
              label={action.label}
              icon={action.icon}
              onClick={() => {
                action.onClick()
                setIsOpen(false)
              }}
              variant={action.variant}
            />
          ))}
        </div>
      )}
    </div>
  )
} 