import { Attachment, Result } from '../../types/domain'
import { getEnvironmentVariable } from '../../utils/environment'
import {
  useAuthQuery,
  useAuthMutationPost,
  useAuthMutationPut,
  useAuthMutationDelete,
} from '../useAuthQuery/useAuthQuery'

const API_BASE_URL = getEnvironmentVariable('REACT_APP_API_URL') || ''

const handleResult = <T,>(result: Result<T> | undefined | null): T => {
  if (result === undefined || result === null) {
    return undefined as T;
  }
  
  if (!result.isSuccessful || result._value === null) {
    throw new Error(result._error || 'Operation failed')
  }
  return result._value
}

export const useAttachments = (params?: Record<string, any>) => {
  const result = useAuthQuery<Result<Attachment[]>>(['attachments', params], {
    baseUrl: API_BASE_URL,
    path: '/attachments',
    params,
  })

  // Transform the result to match the expected format
  return {
    ...result,
    data: result.data ? handleResult(result.data) : undefined
  }
}

export const useCreateAttachment = () => {
  const mutation = useAuthMutationPost<Result<Attachment>, Error, Partial<Attachment>>({
    baseUrl: API_BASE_URL,
    path: '/attachments',
  })

  // Wrap the mutateAsync to handle the Result type
  return {
    ...mutation,
    mutateAsync: async (variables: Partial<Attachment>) => {
      const result = await mutation.mutateAsync(variables)
      return handleResult(result)
    }
  }
}

export const useUpdateAttachment = () => {
  const mutation = useAuthMutationPut<
    Result<Attachment>,
    Error,
    { id: number } & Partial<Attachment>
  >({
    baseUrl: API_BASE_URL,
    path: '/attachments/{id}',
  })

  return {
    ...mutation,
    mutateAsync: async (variables: { id: number } & Partial<Attachment>) => {
      const result = await mutation.mutateAsync(variables)
      return handleResult(result)
    }
  }
}

export const useDeleteAttachment = () => {
  const mutation = useAuthMutationDelete<void, Error, { id: number }>({
    baseUrl: API_BASE_URL,
    path: '/attachments/{id}',
  })

  return {
    ...mutation,
    mutateAsync: async (variables: { id: number }) => {
      await mutation.mutateAsync(variables)
      return undefined as void
    }
  }
}
