import { useTranslation } from 'react-i18next'
import Form from 'react-bootstrap/Form'
import { Controller, useForm } from 'react-hook-form'
import { UserProfile } from '../../../../types/domain'
import Button from '../../../core/Button/Button'
import { useAuth } from '../../../../hooks/useAuth'
import { useUpdateProfile } from '../../../../hooks/useUser/useUser'
import { useQueryClient } from '@tanstack/react-query'

interface Props {
  profile: UserProfile
  onCancel: () => void
}

const EditPersonalDetails = ({ profile, onCancel }: Props) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { mutate: updateProfile, isPending } = useUpdateProfile(user?.id!)

  const { handleSubmit, control, formState: { isValid, isDirty } } = useForm({
    mode: 'all',
    defaultValues: {
      firstName: profile.firstName,
      lastName: profile.lastName,
      jobTitle: profile.jobTitle,
    },
  })

  const onSubmit = async (data: Partial<UserProfile>) => {
    if (!user?.id) return

    updateProfile(data, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ['currentUser'] })
        onCancel()
      },
    })
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="d-flex mb-700">
        <div className="w-100 me-300">
          <Form.Label>{t('user.details.firstName', 'First Name')}</Form.Label>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { value } }) => (
              <Form.Control disabled type="text" value={value} />
            )}
          />
        </div>
        <div className="w-100 ms-300">
          <Form.Label>{t('user.details.lastName', 'Last Name')}</Form.Label>
          <Controller
            control={control}
            name="lastName"
            render={({ field: { value } }) => (
              <Form.Control disabled type="text" value={value} />
            )}
          />
        </div>
      </Form.Group>

      <Form.Group className="mb-700">
        <Form.Label>{t('user.details.jobTitle', 'Job Title')}</Form.Label>
        <Controller
          control={control}
          name="jobTitle"
          render={({ field: { value } }) => (
            <Form.Control disabled type="text" value={value} />
          )}
        />
      </Form.Group>

      <Button type="submit" disabled={isPending}>
        {t('common.save', 'Save')}
      </Button>
    </Form>
  )
}

export default EditPersonalDetails 