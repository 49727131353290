import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { EN_LANGUAGE, NL_LANGUAGE } from '../../../i18n'
import { Language } from '../../../types/domain'
import Icon, { ICON_TYPE } from '../../core/Icon/Icon'
import { UserLabel } from '../../shared/UserLabel'
import { LanguageSwitcherModal } from '../LanguageSwitcher/LanguageSwitcherModal'
import { useAuth0 } from '@auth0/auth0-react'

const languageIconMap = {
  [EN_LANGUAGE]: {
    icon: ICON_TYPE.GB_FLAG,
  },
  [NL_LANGUAGE]: {
    icon: ICON_TYPE.NL_FLAG,
  },
}

const getLngIcon = (language: string) => {
  return languageIconMap[language as Language] || languageIconMap[NL_LANGUAGE]
}

export const UserMenu: React.FC = () => {
  const { logout } = useAuth0()
  const { t, i18n } = useTranslation()
  const [showLanguageModal, setShowLanguageModal] = useState(false)

  const currentLanguage =
    localStorage.getItem('selectedLanguage') || NL_LANGUAGE
  const lngIcon = getLngIcon(currentLanguage)

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }

  return (
    <>
      <Dropdown className="dropdown-user-menu">
        <Dropdown.Toggle
          variant=""
          className="d-flex align-items-center w-100 h-100 rounded-0"
        >
          <UserLabel />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            id="nav_account"
            as={Link}
            data-testid="user-menu-account"
            to="/settings"
          >
            {t('usermenu.account', 'Account')}
          </Dropdown.Item>
          <Dropdown.Item
            id="nav_support"
            data-testid="user-menu-support"
            as={Link}
            to="/support/faq"
          >
            {t('usermenu.support', 'Support')}
          </Dropdown.Item>
          <Dropdown.Item
            id="nav_language"
            data-testid="user-menu-language"
            onClick={() => setShowLanguageModal(true)}
            className="d-flex align-items-center justify-content-between"
          >
            {t('usermenu.language', 'Language')}
            {lngIcon && <Icon icon={lngIcon.icon} />}
          </Dropdown.Item>
          <Dropdown.Item
            id="nav_log_out"
            data-testid="user-menu-logout"
            onClick={handleLogout}
          >
            {t('usermenu.logout', 'Log Out')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <LanguageSwitcherModal
        isShow={showLanguageModal}
        onHide={() => setShowLanguageModal(false)}
      />
    </>
  )
}

export default UserMenu
