import cx from 'classnames'
import { IconName } from '../../../utils/getIconClass/getIconClass'
import { WithTooltip } from '../../shared/WithTooltip'
import { MenuNavLink } from './MenuNavLink'
import { MobileNavItem } from './MobileSideNav'
import Icon, { ICON_TYPE } from '../../core/Icon/Icon'

interface WithIcon {
  icon: IconName | ICON_TYPE
  disabled: boolean
}

export interface NavItemProps extends WithIcon {
  id: string
  to: string
  label: string
  disabled: boolean
}

export function NavIcon({ icon, disabled }: WithIcon) {
  return (
    <span
      className={cx('app-side-nav__icon | icon-' + icon, {
        disabled,
      })}
    />
  )
}

export function NavItem({ id, to, label, icon, disabled }: NavItemProps) {
  const isIconType = (value: string): boolean => {
    return Object.values(ICON_TYPE).includes(value as ICON_TYPE)
  }
  return (
    <>
      <div className="d-flex">
        <div className="d-flex w-100 justify-content-center">
          <MenuNavLink
            id={id}
            to={to}
            label={label}
            icon={!isIconType(icon) ? icon : undefined}
            disabled={disabled}
          >
            {isIconType(icon) ? (
              <div className="app-side-nav__svg-container">
                <Icon icon={icon} />
              </div>
            ) : null}
            <span className="app-side-nav__item-label">{label}</span>
          </MenuNavLink>
        </div>
      </div>

      {/*<MobileNavItem
        id={id}
        to={to}
        label={label}
        icon={icon}
        disabled={disabled}
      />*/}
    </>
  )
}
