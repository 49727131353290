import {
  QueryKey,
  UseQueryOptions,
  UseMutationOptions,
  useQuery,
  useMutation,
} from '@tanstack/react-query'
import axios from 'axios'
import { getEnvironmentVariable } from '../../../utils/environment'
import { useAuth0 } from '@auth0/auth0-react'

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'
const API_BASE_URL = getEnvironmentVariable('REACT_APP_AI_CONSULTATION_API')

interface AiQueryConfig {
  baseUrl?: string
  path: string
  params?: Record<string, any>
  data?: any
}

export const makeRequest = async (
  getToken: () => Promise<string>,
  method: HttpMethod,
  { baseUrl = API_BASE_URL, path, params, data }: AiQueryConfig,
) => {
  try {
    const token = await getToken()

    const response = await axios({
      method,
      url: `${baseUrl}${path}`,
      params,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (error) {
    console.error(`Error in ${method} request to ${path}:`, error)
    if (axios.isAxiosError(error)) {
      console.error('Axios response data:', error.response?.data)
      throw error.response?.data
    }
    throw error
  }
}

export function useAiApiQuery<TData = unknown, TError = unknown>(
  queryKey: QueryKey,
  config: AiQueryConfig,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>,
) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery<TData, TError>({
    queryKey,
    queryFn: async () => {
      try {
        return await makeRequest(getAccessTokenSilently, 'GET', config)
      } catch (error) {
        if (error instanceof Error) {
          if (error.message === 'TOKEN_EXPIRED') {
            return makeRequest(getAccessTokenSilently, 'GET', config)
          }
        }
        throw error
      }
    },
    staleTime: 1000 * 60 * 180, // 180 minutes
    ...options,
  })
}

export function useAiMutationPost<
  TData = unknown,
  TError = unknown,
  TVariables = any,
>(
  config: Omit<AiQueryConfig, 'data'>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationFn'>,
) {
  const { getAccessTokenSilently } = useAuth0()
  return useMutation<TData, TError, TVariables>({
    mutationFn: (variables) =>
      makeRequest(getAccessTokenSilently, 'POST', {
        ...config,
        data: variables,
      }),
    ...options,
  })
}

export function useAiMutationPut<
  TData = unknown,
  TError = unknown,
  TVariables = any,
>(
  config: Omit<AiQueryConfig, 'data'>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationFn'>,
) {
  const { getAccessTokenSilently } = useAuth0()
  return useMutation<TData, TError, TVariables>({
    mutationFn: (variables) =>
      makeRequest(getAccessTokenSilently, 'PUT', {
        ...config,
        data: variables,
      }),
    ...options,
  })
}

export function useAiMutationDelete<
  TData = unknown,
  TError = unknown,
  TVariables = any,
>(
  config: Omit<AiQueryConfig, 'data'>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationFn'>,
) {
  const { getAccessTokenSilently } = useAuth0()
  return useMutation<TData, TError, TVariables>({
    mutationFn: (variables) =>
      makeRequest(getAccessTokenSilently, 'DELETE', {
        ...config,
        data: variables,
      }),
    ...options,
  })
}
