import React, { useEffect } from 'react'
import { t } from 'i18next'
import useStore, { NavigationItem, NavigationTab, Page } from './store'
import ArrowLeft from '../../core/Icon/icons/svg/ArrowLeft.svg'
import { Navigation } from './ChipNavigation/Navigation'
import { TemplateLibrary } from '../InteractionSummary/TemplateLibrary'
import { AttachmentLibrary } from '../InteractionSummary/AttachmentLibrary'

const ManualConsult: React.FC = () => {
  const setPage = useStore((state) => state.setPage)
  const navigationState = useStore(state => state.navigationState)
  const initializeNavigationState = useStore((state) => state.initializeNavigationState)

  useEffect(() => {
    initializeNavigationState(Page.newManual)
  }, [initializeNavigationState])

  const navigationItems: NavigationItem[] = [
    {
      label: NavigationTab.TEMPLATES,
      isVisible: true,
      isActive: true,
      disabled: false
    },
    {
      label: NavigationTab.FILES,
      isVisible: true,
      isActive: false,
      disabled: false
    }
  ]

  const renderContent = () => {
    switch (navigationState) {
      case NavigationTab.TEMPLATES:
        return <TemplateLibrary />
      case NavigationTab.FILES:
        return <AttachmentLibrary />
      default:  
        return null
    }
  }

  return (
    <>
      <div className="align-self-start">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            setPage(Page.config)
          }}
          className="ai-consult-top-link d-flex justify-content-center align-items-center"
        >
          <img alt="" src={ArrowLeft} /> {t('consult.menu.header', 'Back')}
        </a>
      </div>
      <Navigation navigationItems={navigationItems} />
      {navigationState === NavigationTab.TEMPLATES && (
        <TemplateLibrary />
      )}
      {navigationState === NavigationTab.FILES && (
        <AttachmentLibrary />
      )}
    </>
  )
}

export default ManualConsult
