import { useAuth } from './useAuth'

function useRole() {
  const { user } = useAuth()
  const userRoleNames = user?.roles?.map((r) => r.name)
  const hasRole = (roles: any) => roles.includes(userRoleNames) //TODO check if correctly mapped

  return {
    isAdmin: hasRole(['admin']),
    isOwner: hasRole(['owner']),
    isManager: hasRole(['admin', 'owner']),
    isEmployee: hasRole(['owner']),
    hasRole,
  }
}

export default useRole
