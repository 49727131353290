import { useTranslation } from 'react-i18next'

export const MoreSelectedBadge = ({ items }: any) => {
  const { t } = useTranslation()
  const style = {
    marginLeft: 'auto',
    background: '#e5f1f4',
    borderRadius: '5px',
    fontSize: '13px',
    padding: '3px',
    order: 99,
  }

  const title = items.join(', ')
  const length = items.length

  return (
    <div style={style} title={title}>
      {t('multiSelect.itemsSelected', {
        count: length,
        defaultValue: '+{{count}}',
      })}
    </div>
  )
}
