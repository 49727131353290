import { WithClassName } from '../Icon'

export const Privacy = ({ className }: WithClassName) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.33325 1.16666V8.60882C2.33325 16.3788 7.17492 22.9997 13.9999 25.6667C20.8249 22.9997 25.6666 16.3788 25.6666 8.60882V1.16666H2.33325ZM24.4999 8.60882C24.4999 15.5657 20.3944 21.7175 13.9999 24.4078C7.60542 21.7175 3.49992 15.5657 3.49992 8.60882V2.33332H24.4999V8.60882ZM23.3333 5.83332H4.66659V6.99999H23.3333V5.83332ZM12.1694 15.813L9.74509 13.3887L8.92025 14.2135L12.1694 17.4627L19.0784 10.5525L18.2536 9.72766L12.1694 15.813Z"
      fill="#00708D"
    />
  </svg>
)
