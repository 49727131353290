import { t } from 'i18next'
import React from 'react'
import { Form } from 'react-bootstrap'
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form'
import { Select } from '../../core/Select/Select'
import Button from '../../core/Button/Button'
import MarkdownEditor from '../../core/MarkdownEditor/MarkdownEditor'
import {
  CategoryOption,
  getSubcategoryOptions,
  categoryOptions,
} from '../InteractionCategory/CategoryOptions'
import { InteractionSummaryForm } from '../../../types/forms'
import { Attachment, Template } from '../../../types/domain'
import AttachmentList from './AttachmentList'

interface FormSectionProps {
  methods: UseFormReturn<InteractionSummaryForm>
  selectedCategory: CategoryOption | null
  selectedSubCategory: CategoryOption | null
  onCategoryChange: (category: CategoryOption | null) => void
  onSubCategoryChange: (category: CategoryOption | null) => void
  onSubmit: (data: InteractionSummaryForm) => Promise<void>
  onAddAttachment: (attachment: Attachment) => void
  onRemoveAttachment: (index: number) => void
  onTemplateApplied: (template: Template) => void
}

export const FormSection: React.FC<FormSectionProps> = ({
  methods,
  selectedCategory,
  selectedSubCategory,
  onCategoryChange,
  onSubCategoryChange,
  onSubmit,
  onAddAttachment,
  onRemoveAttachment,
  onTemplateApplied,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods
  const subcategoryOptions = getSubcategoryOptions(
    selectedCategory ? [selectedCategory] : null,
  )

  return (
    <>
      <FormProvider {...methods}>
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="w-500px">
          <Form.Group>
            <Form.Label>{t('summary.form.title', 'Title')}</Form.Label>
            <Controller
              name="title"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  type="text"
                  placeholder={t(
                    'summary.form.title-placeholder',
                    'Enter title',
                  )}
                />
              )}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>{t('summary.form.category', 'Category')}</Form.Label>
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isClearable
                  options={categoryOptions}
                  onChange={onCategoryChange}
                />
              )}
            />
          </Form.Group>

          {selectedCategory && (
            <Form.Group>
              <Form.Label>
                {t('summary.form.subcategory', 'Subcategory')}
              </Form.Label>
              <Controller
                name="subCategory"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isClearable
                    options={subcategoryOptions}
                    onChange={onSubCategoryChange}
                  />
                )}
              />
            </Form.Group>
          )}

          <Form.Group>
            <Form.Label>
              {t('summary.form.description', 'Description')}
            </Form.Label>
            <Controller            
              name="description"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <MarkdownEditor                  
                  value={field.value}
                  onChange={(value) => field.onChange(value.markdown)}
                  onTemplateApplied={onTemplateApplied}
                  placeholder={t(
                    'summary.form.description-placeholder',
                    'Enter description',
                  )}
                />
              )}
            />
          </Form.Group>

          <Form.Group className="manual-consult__attachments-section">
            <Form.Label className="manual-consult__attachments-title">
              {t('summary.form.attachments', 'Attached files')}
            </Form.Label>
            <Controller
              name="attachments"
              control={control}
              render={({ field }) => (
                <AttachmentList
                  attachments={field.value}
                  onRemove={onRemoveAttachment}
                  onAddAttachment={onAddAttachment}
                  onAttachmentDragStart={(e, attachment) => {
                    e.dataTransfer.setData(
                      'attachment',
                      JSON.stringify(attachment),
                    )
                  }}
                />
              )}
            />
          </Form.Group>

          <div className="d-flex justify-content-end">
            <Button type="submit" disabled={!isValid || !isDirty}>
              {t('summary.form.generate', 'Generate QR Code')}
            </Button>
          </div>
        </Form>
      </FormProvider>
    </>
  )
}
