import React, { useCallback, useEffect, useMemo } from 'react'
import {
  components,
  CSSObjectWithLabel,
  SingleValueProps,
  SingleValue as SingleValueT,
} from 'react-select'
import { Option } from '../../../../utils/getSelectOptions'
import { Select } from '../../../core/Select/Select'
import dropDown from './../../../core/Icon/icons/svg/Chevron_Down.svg'

const IconOption = (props: any) => (
  <components.Option {...props}>{props.data.label}</components.Option>
)

const SingleValue = (props: SingleValueProps<Option>) => (
  <components.SingleValue {...props}>{props.data.label}</components.SingleValue>
)

interface ProtocolSwitcherProps {
  onChange?: (lng: string) => void
  value?: string
  isDisabled?: boolean
  options?: { value: string; label: string }[]
}

export function SelectField({
  onChange,
  value,
  isDisabled,
  options = [],
}: ProtocolSwitcherProps) {
  const CustomDropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <img
          src={dropDown} // Replace with your image URL
          alt="dropdown indicator"
          style={{ width: 20, height: 20 }}
        />
      </components.DropdownIndicator>
    )
  }

  const handleChange = useCallback(
    (lng: SingleValueT<Option>) => {
      if (!lng) {
        return
      }

      if (onChange) {
        onChange(lng.value)
      }
    },
    [onChange],
  )

  return (
    <Select
      isDisabled={isDisabled}
      isSearchable={false}
      options={options}
      components={{
        Option: IconOption,
        SingleValue,
        DropdownIndicator: CustomDropdownIndicator,
      }}
      isMulti={false}
      value={
        options.find(
          (o: { value: string; label: string }) => o.value === value,
        ) || options[0]
      }
      onChange={handleChange}
      styles={{
        control: (base: CSSObjectWithLabel) => ({
          ...base,
          height: '44px',
          minWidth: '100%',
        }),
        indicatorSeparator: (base: CSSObjectWithLabel) => ({
          ...base,
          backgroundColor: 'transparent',
        }),
        menu: (base: CSSObjectWithLabel) => ({
          ...base,
          minWidth: '100%',
        }),
        singleValue: (base: CSSObjectWithLabel) => ({
          ...base,
          display: 'flex',
          alignItems: 'center',
        }),
      }}
    />
  )
}
