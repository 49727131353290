import { User, UserProfile } from '../../types/domain'
import { getEnvironmentVariable } from '../../utils/environment'
import {
  useAuthQuery,
  useAuthMutationPut,
  useAuthMutationDelete,
  useAuthMutationPost,
} from '../useAuthQuery/useAuthQuery'

const API_BASE_URL = `${getEnvironmentVariable('REACT_APP_USER_SERVICE_API_URL') || ''}/users`

export const useCurrentUser = () => {
  return useAuthQuery<User>(['currentUser'], {
    baseUrl: API_BASE_URL,
    path: '',
  })
}

export const useUpdateProfile = (userId: number) => {
  return useAuthMutationPut<UserProfile, Error, Partial<UserProfile>>({
    baseUrl: API_BASE_URL,
    path: `/profile/${userId}`,
  })
}

export const useAllUsers = () => {
  return useAuthQuery<User[]>(['users'], {
    baseUrl: API_BASE_URL,
    path: '/users',
  })
}

export const useDeleteAccount = () => {
  return useAuthMutationDelete<void, Error>({
    baseUrl: API_BASE_URL,
    path: '/',
  })
}

export const useResetPassword = (userId: number) => {
  return useAuthMutationPost<void, Error>({
    baseUrl: API_BASE_URL,
    path: `/${userId}/reset-password`,
  })
}
