import { WithClassName } from '../Icon'

export const Camera = ({ className }: WithClassName) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
      <path fillRule="evenodd" clipRule="evenodd"
            d="M2.16669 4.16667C2.16669 3.70643 2.53978 3.33334 3.00002 3.33334L11.3334 3.33334C11.7936 3.33334 12.1667 3.70643 12.1667 4.16667L12.1667 15.8333C12.1667 16.2936 11.7936 16.6667 11.3334 16.6667H3.00002C2.53978 16.6667 2.16669 16.2936 2.16669 15.8333L2.16669 4.16667ZM3.83335 5L3.83335 15L10.5 15L10.5 5L3.83335 5Z"
            fill="#5A545C" />
      <path d="M17.1667 5.83334L18.8334 5.83334V14.1667L17.1667 14.1667V5.83334Z" fill="#5A545C" />
      <path d="M15.5 4.16667L13.8334 4.16667L13.8334 15.8333H15.5L15.5 4.16667Z" fill="#5A545C" />
  </svg>
)
