import { EN_LANGUAGE, NL_LANGUAGE } from '../i18n'

export interface Profile {
  id: string
  language: Language
  email: string
  firstName: string
  lastName: string
  jobTitle: string
  phoneNumber?: PhoneNumber
  organization: Organization
  bigNumber: string
  subscription: Subscription
}

export interface Organization {
  id: number
  name: string
  ownerId: number
  members: Array<{
    userId: number
    role: string
  }>
}

export interface Subscription {
  verified: boolean
  tier: string
}

export interface PhoneNumber {
  countryCode: string
  number: string
}

const languages = [NL_LANGUAGE, EN_LANGUAGE] as const
export type Language = (typeof languages)[number]

export const LANGUAGE: Record<string, Language> = {
  NL: NL_LANGUAGE,
  GB: EN_LANGUAGE,
}

export enum LIBRARY_TYPE {
  ORGANIZATION,
  PERSONAL,
  DEFAULT,
}

export type MakeOptional<Type, Key extends keyof Type> =
  Omit<Type, Key> & Partial<Pick<Type, Key>>;

export interface User {
  id: number
  subjectId: string
  email: string
  organizationId?: number
  roles?: Role[]
  profile?: UserProfile
}

export interface UserProfile {
  id?: number
  firstName?: string
  lastName?: string
  jobTitle?: string
  language?: string
  phoneNumber?: string
  phoneCountryCode?: string
}

export interface Role {
  id: number
  name: string
  description?: string
  permissions?: Permission[]
}

export interface Permission {
  id: number
  name: string
  description?: string
}

export interface Invitation {
  id: number
  hash: string
  email: string
  organizationId: number
  token: string
  invitedBy: number
  expiresAt: Date
  consumed: boolean
}

export interface Attachment {
  id: number
  displayName: string
  description: string
  url: string
  userId: number | null
  organizationId: number | null
  type: 'Personal' | 'Organization' | 'General'
  metadata: {
    img?: string
    title?: string
    domain?: string
    favicon?: string
    description?: string
  } | null
  createdAt?: string
  updatedAt?: string
}

export interface Template {
  id: number
  displayName: string
  content: string
  category: number[]
  subcategory: number[]
  userId: number | null
  organizationId: number | null
  attachments: Attachment[]
  attachmentIds?: number[]
  attachmentCount: number
  type: 'Personal' | 'Organization' | 'General'
  createdAt?: string
  updatedAt?: string
}

export interface Summary {
  displayName: string
  content: string
  category: number[]
  subcategory: number[]
  attachments: string[]
  id: string
  userId: string
  createdAt: string
  organizationId: string | null
}

export interface AuthContextType {
  user: User | null
  loading: boolean
  isAuthorized: boolean
  error?: Error
}

export interface Result<T> {
  isSuccessful: boolean,
  _error: string | null,
  _value: T | null
}
