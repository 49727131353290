import * as React from 'react'
import { useEffect, useState } from 'react'
import styles from './AudioRecorder.module.scss'
import OldMic from './../../../core/Icon/icons/OldMic.svg'
import { AudioLevel } from './AudioLevel'
import Button from '../../../core/Button/Button'
import { t } from 'i18next'
import useStore, { Page } from '../store'
import { SelectField } from '../AiConsultForm/SelectField'
import { useAiMutationPost } from '../../../../hooks/Ai/useAiApiQuery/useAiApiQuery'
import { useTestMode } from '../../../../utils/utils'

interface DeviceOption {
  value: string
  label: string
}

export const AudioRecorder: React.FC = () => {
  const [showModal, setShowModal] = useState(false) // Control modal visibility
  const approveRecording = useStore((state) => state.approveRecording)
  const deviceId = useStore((state) => state.deviceId)
  const setConsultId = useStore((state) => state.setConsultId)
  const protocol = useStore((state) => state.protocol)
  const setDeviceId = useStore((state) => state.setDeviceId)
  const setPage = useStore((state) => state.setPage)
  const defaultAudioLevels = new Array(8).fill(false)
  const [audioLevels, setAudioLevels] = useState<boolean[]>(defaultAudioLevels)
  const [deviceOptions, setDeviceOptions] = useState<DeviceOption[]>([]) // List of available devices
  const startMutation = useAiMutationPost({
    path: '/consultation/start',
  })
  const isTestMode = useTestMode()
  useEffect(() => {
    retryMicrophoneAccess()
  }, [])

  useEffect(() => {
    if (deviceOptions.length) {
      setDeviceOptions(deviceOptions)
      setDeviceId(deviceOptions[0].value)
    }
  }, [deviceOptions])

  useEffect(() => {
    let audioContext: AudioContext | null = null
    let analyzer: AnalyserNode | null = null
    let dataArray: Uint8Array | null = null
    let node: AudioNode
    let stream: MediaStream

    const monitorAudio = async () => {
      if (!approveRecording) {
        setAudioLevels([...defaultAudioLevels])
        return
      }

      const constraints: MediaStreamConstraints = {
        audio: { deviceId: deviceId ? { exact: deviceId } : undefined },
      }

      try {
        stream = await navigator.mediaDevices.getUserMedia(constraints)

        audioContext = new AudioContext()
        const source = audioContext.createMediaStreamSource(stream)
        analyzer = audioContext.createAnalyser()
        analyzer.fftSize = 256

        const bufferLength: number = analyzer.frequencyBinCount
        dataArray = new Uint8Array(bufferLength)

        node = source.connect(analyzer)

        const updateMeter = () => {
          if (!approveRecording) {
            return
          }

          analyzer?.getByteFrequencyData(dataArray!)
          const volume = Math.max(...dataArray!)
          const newLevels = [...audioLevels]
          audioLevels.forEach((bar, index) => {
            newLevels[index] = index < volume / 32
          })

          setAudioLevels(newLevels)
          requestAnimationFrame(updateMeter)
        }

        updateMeter()
      } catch (error) {
        console.error('Error accessing audio devices:', error)
      }
    }

    monitorAudio()

    return () => {
      stream?.getTracks().forEach((track) => track.stop())
      node?.disconnect()
      audioContext?.close()
    }
  }, [deviceId, approveRecording])

  const retryMicrophoneAccess = async () => {
    setShowModal(false) // Close the modal
    try {
      // Retry microphone access
      await navigator.mediaDevices.getUserMedia({ audio: true })

      // Fetch devices again
      const devices = await navigator.mediaDevices.enumerateDevices()
      const audioInputDevices = devices
        .filter((device) => device.kind === 'audioinput')
        .map((device) => ({
          label: device.label || `Microphone ${device.deviceId}`,
          value: device.deviceId,
        }))

      setDeviceOptions(audioInputDevices)
      if (audioInputDevices.length > 0) {
        setDeviceId(audioInputDevices[0].value)
      }
    } catch (error) {
      console.error('Microphone access retry failed:', error)
      setShowModal(true) // Keep modal open if retry fails
    }
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.iconContainer}>
            <img
              loading="lazy"
              src={OldMic}
              alt="Audio recording icon"
              className={styles.headerIcon}
            />
          </div>
          <h2 className={styles.title}>Ready to Start?</h2>
        </div>

        <div className={styles.controls}>
          <div className={styles.inputGroup}>
            <label className={styles.inputLabel}>
              Current device: {deviceId || 'None selected'}
            </label>
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.inputLabel}>Input device</label>
            <div style={{ width: '180px' }}>
              <SelectField
                isDisabled={!approveRecording}
                value={deviceId}
                onChange={setDeviceId}
                options={deviceOptions}
              />
            </div>
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.inputLabel}>Input level</label>
            <AudioLevel levels={audioLevels} />
          </div>
        </div>
        <Button
          className={`w-100 ${styles.submitBtn}`}
          data-testid="new_recording"
          type="submit"
          variant="primary"
          disabled={!approveRecording || !deviceId || !protocol}
          onClick={async () => {
            if (isTestMode) {
              setConsultId(12)
              setPage(Page.aiRecordingInProgress)
              return
            }
            startMutation.mutate(
              { language: 'nl-NL', verbosity: 'extensive', protocol: protocol },
              {
                onSuccess: (data) => {
                  setConsultId(
                    (data as { consultation_id: number }).consultation_id,
                  )
                  setPage(Page.aiRecordingInProgress)
                },
                onError: (error) => {
                  console.error('create Error:', error)
                },
              },
            )
          }}
        >
          {t('new.recording.button', 'New recording')}
        </Button>
      </div>

      {/* @todo replace it according to design */}
      {showModal && (
        <div className="modal fade show d-block" style={{ display: 'block' }}>
          <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Microphone Access Blocked</h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() => setShowModal(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Microphone access has been blocked. To enable it:
                <ol>
                  <li>Click the lock icon in your browser's address bar.</li>
                  <li>Find "Microphone" permissions.</li>
                  <li>Select "Allow" and refresh the page.</li>
                </ol>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => window.location.reload()}
                >
                  Refresh Page
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
