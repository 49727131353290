import * as React from 'react'
import styles from './AudioRecorder.module.scss'
import { LevelBar } from './LevelBar'
import { AudioLevelProps } from './types'

export const AudioLevel: React.FC<AudioLevelProps> = ({ levels }) => (
  <div className={styles.levelContainer}>
    {levels.map((active, index) => (
      <LevelBar key={index} active={active} />
    ))}
  </div>
)
