import React, { FunctionComponent } from 'react'
import i18n from '../../../i18n'
import { Paths } from '../../../utils/paths'
import { AsideNavigationPanel } from './AsideNavigationPanel'
import { BottomNavigationPanel } from './BottomNavigationPanel'
import { NavItem } from './SideNav'
import getNavItems from './utils'

export interface NavigationPanelProps {
  allowedLinks: React.JSX.Element[]
  asideLinks?: React.JSX.Element[]
}

const MENU_ITEMS: Paths[] = [
  Paths.consultation,
  Paths.history,
  Paths.library,
  Paths.settings /*, Paths.organization*/,
]

const getNavs = (aside = false) => {
  const navItems = getNavItems(i18n, aside)

  return MENU_ITEMS.filter((key) => Boolean(navItems[key as Paths])).map(
    (key) => {
      return (
        <NavItem
          id={navItems[key as Paths].id}
          disabled={false}
          key={navItems[key as Paths].key}
          to={navItems[key as Paths].href}
          label={navItems[key as Paths].label}
          icon={navItems[key as Paths].icon}
        />
      )
    },
  )
}

export const NavigationPanel: FunctionComponent = () => {
  const navs = getNavs()
  const aside = getNavs(true)

  return (
    <>
      <AsideNavigationPanel allowedLinks={navs} asideLinks={aside} />
      {/*<BottomNavigationPanel bottomLinks={navs} expandedLinks={[]} />*/}
    </>
  )
}
