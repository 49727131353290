import { useTranslation } from 'react-i18next'
import { ICON_TYPE } from '../../../core/Icon/Icon'
import { LinkPanel } from '../../../shared/LinkPanel/LinkPanel'
import useSettingsStore from '../store'
import FaqContainer from '../Faq/FaqContainer'

const SupportSettings = () => {
  const { t } = useTranslation()
  const { supportSubPage, setSupportSubPage } = useSettingsStore()

  if (supportSubPage === 'faq') {
    return <FaqContainer />
  }

  const supportItems = [
    {
      id: 'btn_support_faqs',
      title: t('support.faq', 'FAQs'),
      subtitle: t(
        'support.faq.details',
        'If you have any questions, see if they are answered here.',
      ),
      icon: ICON_TYPE.FAQ,
      onClick: () => setSupportSubPage('faq'),
    },
  ]

  return (
    <div className="settings-section">
      <h1 className="settings-title">{t('settings.support.title', 'Support')}</h1>
      <div className="settings-subtitle regular-300-gray-700">
        {t(
          'settings.support.subtitle',
          'Access help resources and contact support.',
        )}
      </div>

      {supportItems.map((item) => (
        <div
          key={item.id}
          className="settings-card cursor-pointer"
          onClick={item.onClick}
        >
          <div className="settings-card-header">
            {item.icon && <img src={item.icon} alt="" className="me-3" />}
            <div>
              <div className="settings-card-title">{item.title}</div>
              <div className="settings-card-subtitle">{item.subtitle}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default SupportSettings