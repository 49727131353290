import React, { useCallback, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { useAttachments } from '../../../../hooks/useAttachment/useAttachmentBase'
import { useAuth } from '../../../../hooks/useAuth'
import useRole from '../../../../hooks/useRole'
import {
  useCreateTemplate,
  useDeleteTemplate,
  useTemplates,
  useUpdateTemplate,
} from '../../../../hooks/useTemplate/useTemplateBase'
import { Template } from '../../../../types/domain'
import Button from '../../../core/Button/Button'
import { SearchInput } from '../../../core/SearchInput/SearchInput'
import { TemplateCard } from './TemplateCard'
import TemplateManagementModal from './TemplateManagementModal'
import useLibraryStore from '../store'
import ConfirmationModal from '../../../core/ConfirmationModal/ConfirmationModal'

const TemplateManagement: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { isManager } = useRole()
  const queryClient = useQueryClient()

  const {
    templateModal,
    openTemplateModal,
    closeTemplateModal,
    deleteModal,
    openDeleteModal,
    closeDeleteModal,
    showToast,
  } = useLibraryStore()

  const { data: templates = [], isLoading } = useTemplates()
  const { data: attachments = [] } = useAttachments()
  const { mutateAsync: createTemplate } = useCreateTemplate()
  const { mutateAsync: updateTemplate } = useUpdateTemplate()
  const { mutateAsync: deleteTemplate } = useDeleteTemplate()

  const attachmentNamesMap = attachments.reduce(
    (acc, attachment) => ({
      ...acc,
      [attachment.id]: attachment.displayName,
    }),
    {} as Record<string | number, string>,
  )

  const [searchQuery, setSearchQuery] = useState('')

  const handleSubmit = async (data: Partial<Template>) => {
    try {
      if (templateModal.editItem?.id) {
        const id = templateModal.editItem.id
        await updateTemplate({ ...data, id })
        await queryClient.invalidateQueries({ queryKey: ['templates'] })
        showToast(
          'success',
          t('template.update.success', 'Template updated successfully'),
        )
        closeTemplateModal()
      } else {
        await createTemplate(data)
        await queryClient.invalidateQueries({ queryKey: ['templates'] })
        showToast(
          'success',
          t('template.create.success', 'Template created successfully'),
        )
        closeTemplateModal()
      }
    } catch (error: any) {
      console.error('Error handling template:', error)
      const errorMessage =
        error.details?.message ||
        error.message ||
        t('template.operation.error', 'Operation failed. Please try again.')
      showToast('danger', errorMessage)
    }
  }

  const handleEditTemplate = (template: Template) => {
    openTemplateModal(template)
  }

  const handleDeleteTemplate = async (id: number | undefined) => {
    if (id) {
      openDeleteModal(id, 'template')
    }
  }

  const handleConfirmDelete = async () => {
    if (deleteModal.itemToDelete?.id) {
      try {
        await deleteTemplate({ id: deleteModal.itemToDelete.id })
        const updatedTemplates = templates.filter(
          (template) => template.id !== deleteModal.itemToDelete?.id,
        )
        queryClient.setQueryData(['templates'], updatedTemplates)
        await queryClient.invalidateQueries({ queryKey: ['templates'] })

        showToast(
          'success',
          t('template.delete.success', 'Template deleted successfully'),
        )
        closeDeleteModal()
      } catch (error: any) {
        console.error('Error deleting template:', error)
        const errorMessage =
          error.details?.message ||
          error.message ||
          t('template.delete.error', 'Failed to delete template')
        showToast('danger', errorMessage)
      }
    }
  }

  const onSearchQueryChange = useCallback((value: string) => {
    setSearchQuery(value)
  }, [])

  const filteredTemplates =
    templates?.filter?.(
      (template) =>
        template.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
        template.displayName.toLowerCase().includes(searchQuery.toLowerCase()),
    ) || []

  const TEMPLATE_TYPES = {
    Personal: 'Personal',
    Organization: 'Organization',
    General: 'General',
  } as const

  type TemplateType = (typeof TEMPLATE_TYPES)[keyof typeof TEMPLATE_TYPES]

  const groupTemplates = (templates: Template[]) => {
    // Initialize with all possible types
    const initialGroups = Object.values(TEMPLATE_TYPES).reduce(
      (acc, type) => {
        acc[type] = []
        return acc
      },
      {} as Record<TemplateType, Template[]>,
    )

    // Group templates
    return templates.reduce((acc, template) => {
      let type: TemplateType = TEMPLATE_TYPES.General
      if (template.organizationId !== null) type = TEMPLATE_TYPES.Organization
      if (template.userId !== null) type = TEMPLATE_TYPES.Personal

      acc[type].push(template)
      return acc
    }, initialGroups)
  }

  const groupedTemplates = groupTemplates(filteredTemplates)

  return (
    <div className="content-library__content">
      <div className="content-library__content-header">
        <div className="search">
          <SearchInput onChange={onSearchQueryChange} value={searchQuery} />
        </div>
        <div className="add-button">
          <Button
            icon="plus"
            onClick={() => openTemplateModal()}
            variant="secondary"
          >
            {t('template.addTemplate', 'Add Template')}
          </Button>
        </div>
      </div>

      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center mh-300px">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="content-library__content-section">
          {Object.entries(groupedTemplates).map(([type, templates]) => (
            <div
              key={type}
              className="content-library__content-section__wrapper"
            >
              <div className="content-library__content-section__title">
                {type}
              </div>
              {templates.length > 0 ? (
                <div className="content-library__content-section__content">
                  {templates.map((template) => (
                    <TemplateCard
                      key={template.id}
                      template={template}
                      onEdit={handleEditTemplate}
                      onDelete={handleDeleteTemplate}
                    />
                  ))}
                </div>
              ) : (
                <p className="text-muted">
                  No {type.toLowerCase()} templates available
                </p>
              )}
            </div>
          ))}
        </div>
      )}

      <TemplateManagementModal
        show={templateModal.show}
        onHide={closeTemplateModal}
        template={templateModal.editItem as Template}
        onSubmit={handleSubmit}
        isManager={isManager}
        attachmentNamesMap={attachmentNamesMap}
      />

      <ConfirmationModal
        show={deleteModal.show && deleteModal.itemToDelete?.type === 'template'}
        onHide={closeDeleteModal}
        onConfirm={handleConfirmDelete}
        message={t(
          'template.confirmDelete',
          'Are you sure you want to delete this template?',
        )}
      />
    </div>
  )
}

export default TemplateManagement
