import React from 'react'
import { ArrowDown } from './icons/ArrowDown'
import { NewTab } from './icons/NewTab'
import { Finish } from './icons/Finish'
import { EyeSlash } from './icons/EyeSlash'
import { Eye } from './icons/Eye'
import { Profile } from './icons/Profile'
import { Privacy } from './icons/Privacy'
import { CompanyDetails } from './icons/CompanyDetails'
import { Phone } from './icons/Phone'
import { FAQ } from './icons/FAQ'
import { Chat } from './icons/Chat'
import { NLFlag } from './icons/NLFlag'
import { GBFlag } from './icons/GBFlag'
import { IconName } from '../../../utils/getIconClass/getIconClass'
import { Mic } from './icons/Mic'
import { Clock } from './icons/Clock'
import { Camera } from './icons/Camera'
import { Settings } from './icons/Settings'
import { Link } from './icons/Link'
import { MoreVertical } from './icons/MoreVertical'
import { Edit } from './icons/Edit'
import { Delete } from './icons/Delete'
import { Close } from './icons/Close'

export interface WithClassName {
  className?: string
}

type IconProps = { icon: ICON_TYPE | IconName; fill?: string } & WithClassName

const Icon: React.FC<IconProps> = (props) => {
  if (!props.icon) {
    return null
  }
  const customIcon = ICON_TYPE[props.icon as ICON_TYPE]

  return customIcon ? (
    renderIcon(props)
  ) : (
    <span
      className={`icon-${props.icon} ${props.className ?? 'icon-size-750'}`}
    ></span>
  )
}

export enum ICON_TYPE {
  ARROW_DOWN = 'ARROW_DOWN',
  NEW_TAB = 'NEW_TAB',
  EYE = 'EYE',
  EYE_SLASH = 'EYE_SLASH',
  PROFILE = 'PROFILE',
  PRIVACY = 'PRIVACY',
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  MIC = 'MIC',
  CLOCK = 'CLOCK',
  CAMERA = 'CAMERA',
  PHONE = 'PHONE',
  SETTINGS = 'SETTINGS',
  FAQ = 'FAQ',
  GB_FLAG = 'GB_FLAG',
  NL_FLAG = 'NL_FLAG',
  LINK = 'LINK',
  MORE_VERTICAL = 'MORE_VERTICAL',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  CLOSE = 'CLOSE',
}

const renderIcon = ({ icon, ...props }: IconProps) => {
  switch (icon) {
    case ICON_TYPE.ARROW_DOWN:
      return <ArrowDown {...props} />
    case ICON_TYPE.CAMERA:
      return <Camera {...props} />
    case ICON_TYPE.CLOCK:
      return <Clock {...props} />
    case ICON_TYPE.NEW_TAB:
      return <NewTab {...props} />
    case ICON_TYPE.EYE_SLASH:
      return <EyeSlash {...props} />
    case ICON_TYPE.EYE:
      return <Eye {...props} />
    case ICON_TYPE.PROFILE:
      return <Profile {...props} />
    case ICON_TYPE.PRIVACY:
      return <Privacy {...props} />
    case ICON_TYPE.COMPANY_DETAILS:
      return <CompanyDetails {...props} />
    case ICON_TYPE.MIC:
      return <Mic {...props} />
    case ICON_TYPE.PHONE:
      return <Phone {...props} />
    case ICON_TYPE.FAQ:
      return <FAQ {...props} />
    case ICON_TYPE.NL_FLAG:
      return <NLFlag {...props} />
    case ICON_TYPE.SETTINGS:
      return <Settings {...props} />
    case ICON_TYPE.GB_FLAG:
      return <GBFlag {...props} />
    case ICON_TYPE.LINK:
      return <Link {...props} />
    case ICON_TYPE.MORE_VERTICAL:
      return <MoreVertical {...props} />
    case ICON_TYPE.EDIT:
      return <Edit {...props} />
    case ICON_TYPE.DELETE:
      return <Delete {...props} />
    case ICON_TYPE.CLOSE:
      return <Close {...props} />
    default:
      return null
  }
}

export default Icon
