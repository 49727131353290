import * as React from 'react'
import styles from './NavigationChip.module.scss'

interface NavigationChipProps {
  label: string
  isActive?: boolean
  disabled?: boolean
  onClick?: () => void
}

export const NavigationChip: React.FC<NavigationChipProps> = ({
  label,
  isActive = false,
  disabled = false,
  onClick,
}) => {
  return (
    <div
      className={`${styles.chip} ${isActive ? styles.active : ''} ${disabled ? styles.disabled : ''}`}
      role="tab"
      tabIndex={0}
      aria-selected={isActive}
      onClick={onClick}
    >
      {label}
    </div>
  )
}
