import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import Button from '../../core/Button/Button'
import Modal from '../../shared/Modal/Modal'
import { LanguageSwitcher } from './LanguageSwitcher'
import { useAuth } from '../../../hooks/useAuth'
import { useUpdateProfile } from '../../../hooks/useUser/useUser'
import { Toast } from '../../core/Toast/Toast'

interface LanguageSwitcherModalProps {
  isShow: boolean
  onHide: () => void
}

export function LanguageSwitcherModal({
  isShow,
  onHide,
}: LanguageSwitcherModalProps) {
  const [lng, setLng] = useState('')
  const { t, i18n } = useTranslation()
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { mutate: updateProfile } = useUpdateProfile(user?.id!)
  const [toastConfig, setToastConfig] = useState<{
    show: boolean
    variant: string
    message: string
  }>({
    show: false,
    variant: '',
    message: '',
  })

  const handleChangeLng = useCallback(() => {
    if (!lng) return

    updateProfile(
      { language: lng },
      {
        onSuccess: async () => {
          localStorage.setItem('selectedLanguage', lng)
          await i18n.changeLanguage(lng)
          await queryClient.invalidateQueries({ queryKey: ['currentUser'] })
          setToastConfig({
            show: true,
            variant: 'success',
            message: t(
              'language.change.success',
              'Language updated successfully',
            ),
          })
          onHide()
        },
        onError: () => {
          setToastConfig({
            show: true,
            variant: 'danger',
            message: t('language.change.error', 'Failed to update language'),
          })
        },
      },
    )
  }, [lng, i18n, updateProfile, queryClient, onHide, t])

  return (
    <>
      {toastConfig.show && (
        <Toast variant={toastConfig.variant} message={toastConfig.message} />
      )}
      <Modal
        show={isShow}
        dialogClassName="max-w-600px"
        contentClassName="rounded-2"
        onHide={onHide}
      >
        <Modal.Body className="p-600 d-flex flex-column align-items-center">
          <h4 className="mb-500">{t('language.select', 'Select language')}</h4>
          <div className="mb-500">
            {t(
              'language.select.description',
              'Please select the language you want to use',
            )}
          </div>
          <div className="w-500px">
            <LanguageSwitcher onChange={setLng} value={lng} />
            <div className="border-top mt-600 py-600 d-flex gap-300 justify-content-center">
              <Button variant="secondary" type="button" onClick={onHide}>
                {t('btn.close', 'Close')}
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={handleChangeLng}
                disabled={!lng}
              >
                {t('btn.confirm', 'Confirm')}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
