import useStore, { Page } from '../../components/features/Consult/store'
import { useEffect } from 'react'

function useClearConsultData() {
  const setPage = useStore((state) => state.setPage)
  const setConsultId = useStore((state) => state.setConsultId)
  useEffect(() => {
    setPage(Page.config)
    setConsultId(-1)
  }, [])
}

export default useClearConsultData
