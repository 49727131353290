import i18n from 'i18next'
import { getDataSecurityQuestions } from './dataSecurityQuestions'
import { getTemplateQuestions } from './templateQuestions'

export enum SECTIONS {
  DATA_SECURITY = 'DATA_SECURITY',
  TEMPLATE = 'TEMPLATE',
}
export const sections: {
  id: SECTIONS
  title: string
}[] = [
  {
    id: SECTIONS.DATA_SECURITY,
    title: i18n.t('support.faq.dataSecurity', 'Data security'),
  },
  {
    id: SECTIONS.TEMPLATE,
    title: i18n.t('support.faq.template', 'Template creation'),
  },
]

export const questionsMap: Record<SECTIONS, () => Question[]> = {
  [SECTIONS.DATA_SECURITY]: getDataSecurityQuestions,
  [SECTIONS.TEMPLATE]: getTemplateQuestions,
}

export interface Question {
  question: string
  answer: string
}
