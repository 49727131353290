import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../../hooks/useAuth'
import PersonalDetails from './PersonalDetails'
import AccountDeletion from './AccountDeletion'
import LoginInformation from './LoginInformation'
import { LanguageSwitcher } from '../../LanguageSwitcher/LanguageSwitcher'
import LegalLinks from '../../../shared/LegalLinks'

const ProfileSettings = () => {
  const { t } = useTranslation()
  const { user } = useAuth()

  if (!user?.profile) return null

  return (
    <div className="settings-content">
      <div className="settings-title">
        {t('settings.profile.title', 'Account')}
      </div>      

      <PersonalDetails profile={user.profile} />
      <LoginInformation />
      <LanguageSwitcher />
      <AccountDeletion />
      <LegalLinks />
    </div>
  )
}

export default ProfileSettings