import { Alert } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { Variant } from 'react-bootstrap/types'

export const TIMEOUT = 5000

export interface ToastProps {
  variant: Variant
  message: string | JSX.Element
}
export const Toast = ({ variant, message }: ToastProps) => {
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(false)
    }, TIMEOUT)

    return () => clearTimeout(timer)
  }, [])

  if (!isOpen) {
    return null
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1030,
        margin: '20px',
      }}
    >
      <Alert variant={variant} dismissible onClose={() => setIsOpen(false)}>
        {message}
      </Alert>
    </div>
  )
}
