import React, { useEffect, useState } from 'react'
import Button from '../../../core/Button/Button'
import Modal from '../../../shared/Modal/Modal'
import { Attachment } from '../../../../types/domain'
import { useTranslation } from 'react-i18next'

interface AttachmentManagementModalProps {
  show: boolean
  onHide: () => void
  editAttachment: Attachment | null
  onSubmit: (data: Partial<Attachment>) => Promise<void>
  isManager: boolean
}

const AttachmentManagementModal: React.FC<AttachmentManagementModalProps> = ({
  show,
  onHide,
  editAttachment,
  onSubmit,
  isManager,
}) => {
  const { t } = useTranslation()
  const [formData, setFormData] = useState<Partial<Attachment>>({})

  useEffect(() => {
    if (show) {
      setFormData(editAttachment ? {
        displayName: editAttachment.displayName,
        description: editAttachment.description,
        url: editAttachment.url,
      } : {
        displayName: '',
        description: '',
        url: '',
      })
    }
  }, [show, editAttachment])

  const handleClose = () => {
    onHide()
    setFormData({})
  }

  const handleSubmit = () => {
    onSubmit(formData)
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="max-w-600px"
      contentClassName="rounded-2"
    >
      <Modal.Body className="p-600 d-flex flex-column align-items-center">
        <h4 className="mb-500">
          {editAttachment ? t('attachment.edit', 'Edit Attachment') : t('attachment.add', 'Add Attachment')}
        </h4>
        <div className="w-500px">
          <div>
            <label className="form-label">{t('attachment.name', 'Name')}</label>
            <input
              className="form-control"
              value={formData.displayName || ''}
              onChange={(e) =>
                setFormData({ ...formData, displayName: e.target.value })
              }
              placeholder={t('attachment.name.placeholder', 'Attachment Name')}
            />
          </div>
          <div>
            <label className="form-label">{t('attachment.description', 'Description')}</label>
            <textarea
              className="form-control"
              value={formData.description || ''}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              placeholder={t('attachment.description.placeholder', 'Description')}
            />
          </div>
          <div>
            <label className="form-label">{t('attachment.url', 'URL')}</label>
            <input
              className="form-control"
              type="url"
              value={formData.url || ''}
              onChange={(e) =>
                setFormData({ ...formData, url: e.target.value })
              }
              placeholder={t('attachment.url.placeholder', 'Attachment URL')}
            />
          </div>          
          <div className="d-flex justify-content-end mt-400">
            <Button variant="secondary" onClick={handleClose}>
              {t('btn.cancel', 'Cancel')}
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              {editAttachment ? t('btn.save', 'Save Changes') : t('btn.add', 'Add Attachment')}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AttachmentManagementModal
