import React from 'react'

export const sortIcon = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(1,0,0,1,8,7.996)">
      <path
        style={{
          fill: 'rgb(135, 139, 139)',
          fillRule: 'nonzero',
        }}
        d="M4.275,5.864C4.242,5.906 4.201,5.941 4.153,5.964C4.106,5.988 4.053,6 4,6C3.947,6 3.894,5.988 3.847,5.964C3.799,5.941 3.758,5.906 3.725,5.864L0.059,0.53C0.024,0.48 0.004,0.422 0.001,0.361C-0.003,0.301 0.01,0.24 0.038,0.187C0.067,0.133 0.109,0.088 0.161,0.056C0.213,0.025 0.273,0.008 0.333,0.008L7.667,0.008C7.728,0.008 7.787,0.025 7.839,0.056C7.891,0.088 7.933,0.133 7.962,0.187C7.99,0.24 8.003,0.301 7.999,0.361C7.996,0.422 7.976,0.48 7.941,0.53L4.275,5.864Z"
      />
    </g>
  </svg>
)
