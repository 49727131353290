import { WithClassName } from '../Icon'

export const Profile = ({ className }: WithClassName) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0001 1.16667C6.91258 1.16667 1.16675 6.9125 1.16675 14C1.16675 21.0875 6.91258 26.8333 14.0001 26.8333C21.0876 26.8333 26.8334 21.0875 26.8334 14C26.8334 6.9125 21.0876 1.16667 14.0001 1.16667ZM14.0001 25.6667C7.56708 25.6667 2.33341 20.433 2.33341 14C2.33341 7.567 7.56708 2.33333 14.0001 2.33333C20.4331 2.33333 25.6667 7.567 25.6667 14C25.6667 20.433 20.4331 25.6667 14.0001 25.6667ZM14.0001 13.4645C16.2552 13.4645 18.0834 11.6363 18.0834 9.38117C18.0834 7.126 16.2552 5.29783 14.0001 5.29783C11.7449 5.29783 9.91675 7.126 9.91675 9.38117C9.91675 11.6363 11.7449 13.4645 14.0001 13.4645ZM14.0001 6.4645C15.6077 6.4645 16.9167 7.7735 16.9167 9.38117C16.9167 10.9888 15.6077 12.2978 14.0001 12.2978C12.3924 12.2978 11.0834 10.9888 11.0834 9.38117C11.0834 7.7735 12.3924 6.4645 14.0001 6.4645ZM14.0001 14.6498C11.5641 14.6498 9.28092 15.8375 7.88442 17.8337L5.89758 20.6722C6.15075 20.979 6.42608 21.2672 6.71192 21.5437L8.83992 18.5033C10.0171 16.8222 11.9467 15.8177 14.0001 15.8177C16.0534 15.8177 17.9819 16.8222 19.1591 18.5033L21.2871 21.5437C21.5729 21.2672 21.8482 20.9802 22.1014 20.6722L20.1146 17.8348C18.7181 15.8375 16.4361 14.6498 14.0001 14.6498Z"
      fill="#00708D"
    />
  </svg>
)
