import React from 'react'
import { Registration } from './features/Auth/Registration/Registration'

const RegistrationPage: React.FC = () => {
  return (
    <main>
      <Registration />
    </main>
  )
}

export default RegistrationPage
