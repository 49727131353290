import React from 'react'
import { WithClassName } from '../Icon'

export const Delete: React.FC<WithClassName> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 9C14.5523 9 15 9.44772 15 10L15 17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17L13 10C13 9.44772 13.4477 9 14 9Z" fill="#D33F49"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 9C14.5523 9 15 9.44772 15 10L15 17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17L13 10C13 9.44772 13.4477 9 14 9Z" fill="url(#paint0_linear_2509_7489)" fill-opacity="0.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 9C10.5523 9 11 9.44772 11 10L11 17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17L9 10C9 9.44772 9.44772 9 10 9Z" fill="#D33F49"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 9C10.5523 9 11 9.44772 11 10L11 17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17L9 10C9 9.44772 9.44772 9 10 9Z" fill="url(#paint1_linear_2509_7489)" fill-opacity="0.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6V20C19 21.1046 18.1046 22 17 22H7C5.89543 22 5 21.1046 5 20V6ZM7 7V20H17V7H7Z" fill="#D33F49"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6V20C19 21.1046 18.1046 22 17 22H7C5.89543 22 5 21.1046 5 20V6ZM7 7V20H17V7H7Z" fill="url(#paint2_linear_2509_7489)" fill-opacity="0.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6Z" fill="#D33F49"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6Z" fill="url(#paint3_linear_2509_7489)" fill-opacity="0.2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V6C17 6.55228 16.5523 7 16 7H8C7.44772 7 7 6.55228 7 6V4ZM15 4H9V5H15V4Z" fill="#D33F49"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V6C17 6.55228 16.5523 7 16 7H8C7.44772 7 7 6.55228 7 6V4ZM15 4H9V5H15V4Z" fill="url(#paint4_linear_2509_7489)" fill-opacity="0.2"/>
    <defs>
      <linearGradient id="paint0_linear_2509_7489" x1="14" y1="9" x2="14" y2="18" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0.24"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint1_linear_2509_7489" x1="10" y1="9" x2="10" y2="18" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0.24"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint2_linear_2509_7489" x1="12" y1="5" x2="12" y2="22" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0.24"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint3_linear_2509_7489" x1="12" y1="5" x2="12" y2="7" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0.24"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint4_linear_2509_7489" x1="12" y1="2" x2="12" y2="7" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0.24"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
) 