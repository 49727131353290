import { useTranslation } from 'react-i18next'
import useSettingsStore from './store'

interface MenuItem {
  id: 'profile' | 'privacy' | 'support' | 'dittoai' | 'vocabulary'
  label: string
  disabled?: boolean
}

const SettingsNavigation = () => {
  const { t } = useTranslation()
  const { page, setPage } = useSettingsStore()

  const menuItems: MenuItem[] = [
    { id: 'profile', label: t('settings.profile', 'Profile') },
    { id: 'privacy', label: t('settings.privacy', 'Privacy') },
    { id: 'support', label: t('settings.support', 'Support') },
    { 
      id: 'dittoai', 
      label: t('settings.dittoai', 'Ditto AI'),
      disabled: true 
    },
    { 
      id: 'vocabulary', 
      label: t('settings.vocabulary', 'Vocabulary'),
      disabled: true 
    },
  ]

  return (
    <div className="settings-navigation">
      {menuItems.map((item) => (
        <div
          key={item.id}
          onClick={() => !item.disabled && setPage(item.id)}
          className={`settings-nav-item ${
            page === item.id ? 'settings-nav-item-active' : ''
          } ${item.disabled ? 'settings-nav-item-disabled' : 'cursor-pointer'}`}
        >
          {item.label}
        </div>
      ))}
    </div>
  )
}

export default SettingsNavigation 