import axios from 'axios'
import {
  QueryKey,
  UseQueryOptions,
  UseMutationOptions,
  useQuery,
  useMutation,
} from '@tanstack/react-query'

interface QueryConfig {
  baseUrl: string
  path: string
  params?: Record<string, any>
  data?: any
}

const makeRequest = async (
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  { baseUrl, path, params, data }: QueryConfig,
) => {
  try {
    const response = await axios({
      method,
      url: `${baseUrl}${path}`,
      params,
      data,
    })
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios response data:', error.response?.data)
      throw error.response?.data
    }
    throw error
  }
}

export function useMutationPost<
  TData = unknown,
  TError = unknown,
  TVariables = any,
>(
  config: Omit<QueryConfig, 'data'>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationFn'>,
) {
  return useMutation<TData, TError, TVariables>({
    mutationFn: (variables) =>
      makeRequest('POST', {
        ...config,
        data: variables,
      }),
    ...options,
  })
}
