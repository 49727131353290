import { t } from 'i18next'
import React, { useState } from 'react'
import Button from '../../core/Button/Button'
import { Attachment } from '../../../types/domain'
import AddLinkModal from './AddLinkModal'
import Icon, { ICON_TYPE } from '../../core/Icon/Icon'
import { AttachmentPreview } from '../../core/AttachmentPreview/AttachmentPreview'
import { LibraryCard } from '../../core/LibraryCard/LibraryCard'

interface AttachmentListProps {
  attachments: Attachment[]
  onRemove: (index: number) => void
  onAddAttachment: (attachment: Attachment) => void
  onAttachmentDragStart: (
    e: React.DragEvent<HTMLDivElement>,
    attachment: Attachment,
  ) => void
}

const AttachmentList: React.FC<AttachmentListProps> = ({
  attachments,
  onRemove,
  onAddAttachment,
  onAttachmentDragStart,
}) => {
  const [showAddModal, setShowAddModal] = useState(false)

  const handleAddAttachment = (url: string) => {
    const newAttachment = {
      displayName: url,
      url: url,
    } as Attachment
    onAddAttachment(newAttachment)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    const attachmentData = e.dataTransfer.getData('attachment')
    console.log('attachmentData', attachmentData)
    if (attachmentData) {
      const attachment: Attachment = JSON.parse(attachmentData)
      console.log('attachment2', attachment)
      onAddAttachment(attachment)
    }
  }

  return (
    <div
      className="attachment-list w-100"
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      {attachments.map((attachment, index) => (
        <div
          className="d-flex align-items-center justify-content-between mb-2"
          key={index}
        >
          <LibraryCard
            title={attachment.displayName}
            date={attachment.createdAt}
            content={<AttachmentPreview
              metadata={attachment.metadata || {
                title: '',
                description: '',
                domain: '',
              }}
            />
          }
          />
          <Button
            variant="link"
            type="button"
            onClick={() => onRemove(index)}
            className="p-0 ms-2"
          >
            <Icon icon={ICON_TYPE.CLOSE} className="text-dark" />
          </Button>
        </div>
      ))}
      <div className="d-flex justify-content-end mb-3">
        <Button
          id="add_link-btn"
          icon="plus"
          variant="secondary-outline"
          onClick={() => setShowAddModal(true)}
        >
          {t('attachments.add', 'Add link')}
        </Button>
      </div>

      <AddLinkModal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        onAdd={handleAddAttachment}
      />
    </div>
  )
}

export default AttachmentList
