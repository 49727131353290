import React, { ReactNode } from 'react'
import { ICON_TYPE } from '../Icon/Icon'
import { ActionMenu } from '../MoreMenu/ActionMenu'

interface LibraryCardProps {
  title: string
  date?: string
  content: ReactNode
  titleAdornment?: ReactNode
  onEdit?: () => void
  onDelete?: () => void
  size?: 'normal' | 'small'
}

export const LibraryCard: React.FC<LibraryCardProps> = ({
  title,
  date,
  content,
  titleAdornment,
  onEdit,
  onDelete,
  size = 'normal'
}) => {
  const menuActions = [
    ...(onEdit ? [{
      label: 'Edit',
      icon: ICON_TYPE.EDIT,
      onClick: onEdit
    }] : []),
    ...(onDelete ? [{
      label: 'Delete',
      icon: ICON_TYPE.DELETE,
      onClick: onDelete,
      variant: 'danger' as const
    }] : [])
  ]

  return (
    <div className={`library-card ${size === 'small' ? 'library-card--small' : ''}`}>
      <div className="library-card__content">
        {content}
      </div>
      <div className="library-card__header-wrapper">
        <div className="library-card__header-wrapper--metadata">
          <div className="library-card__title-wrapper">
            <div className="library-card__title">{title}</div>
            {titleAdornment}
          </div>
          {date && <div className="library-card__date">{date}</div>}
        </div>
        {(onEdit || onDelete) && (
          <div className="library-card__header-wrapper--menu">
            <ActionMenu actions={menuActions} />
          </div>
        )}
      </div>
    </div>
  )
} 