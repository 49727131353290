import { useEffect, useRef } from 'react'

export function useOnPropChangedOnce(
  onTrueProp: () => void,
  onFalseProp: () => void,
  prop: boolean,
) {
  const effectRan = useRef(true)

  useEffect(() => {
    if (effectRan.current === prop) {
      if (prop) {
        effectRan.current = false
        onTrueProp()
      } else {
        effectRan.current = true
        onFalseProp()
      }
    }
  }, [prop])
}
