import { useLocation } from 'react-router-dom'

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const useTestMode = (): boolean => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  return queryParams.get('test') === 'test'
}
