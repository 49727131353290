import { t } from 'i18next'
import React, { useState } from 'react'
import ArrowMoveLeft from '../components/core/Icon/icons/svg/Chevron_Left_Duo.svg'
import { ChatContainer } from '../components/features/Consult/Transcript/ChatContainer'
import { SoapReport } from '../components/features/Consult/Report/SoapReport'
import { DOCTOR_PROTOCOL, PATIENT_PROTOCOL } from '../utils/constants'
import { History } from '../components/features/Consult/History/History'

const HistoryPage: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false)
  const [collapsedRight, setCollapsedRight] = useState(false)
  const [isDoctorProtocol, setIsDoctorProtocol] = useState(true)

  return (
    <div className="d-flex h-100">
      <div
        className={`consult-left-column d-flex flex-column ${collapsed ? 'collapsed' : ''}`}
      >
        <div className="consult-head justify-content-between">
          <span className="collapsable">
            {t('consult.history.header', 'History')}
          </span>
          <img
            alt="arrow move"
            src={ArrowMoveLeft}
            className={`cursor-pointer ${collapsed ? 'mirror-x' : ''}`}
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>
        <div className="consult-left-column-content flex-grow-1 collapsable">
          <History />
        </div>
      </div>
      <div className="d-flex flex-grow-1 flex-column">
        <div className="justify-content-center align-items-center consult-head">
          <>
            <div
              onClick={() => setIsDoctorProtocol(true)}
              className={`cursor-pointer consult-head-menu ${isDoctorProtocol ? 'consult-head-menu-active' : ''}`}
            >
              Medical
            </div>
            <div
              onClick={() => setIsDoctorProtocol(false)}
              className={`cursor-pointer consult-head-menu consult-head-menu ${isDoctorProtocol ? '' : 'consult-head-menu-active'}`}
            >
              Patient
            </div>
          </>
        </div>
        <div className={`consult-right-column-content flex-grow-1 d-flex`}>
          <SoapReport
            type={isDoctorProtocol ? DOCTOR_PROTOCOL : PATIENT_PROTOCOL}
          />
        </div>
      </div>
      <div
        className={`consult-right-end-column d-flex flex-column ${collapsedRight ? 'collapsed' : ''}`}
      >
        <div className="consult-head justify-content-between">
          <span className="collapsable">
            {t('consult.transcript.header', 'Transcript')}
          </span>
          <img
            className={`cursor-pointer ${collapsedRight ? '' : 'mirror-x'}`}
            onClick={() => setCollapsedRight(!collapsedRight)}
            alt="arrow move"
            src={ArrowMoveLeft}
          />
        </div>
        <div
          className={`consult-right-end-column-content flex-grow-1 ${collapsedRight ? 'collapsable' : ''}`}
        >
          {<ChatContainer />}
        </div>
      </div>
    </div>
  )
}

export default HistoryPage
