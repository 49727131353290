import React, { useMemo } from 'react'
import styles from './SoapReport.module.scss'
import { SoapSection } from './SoapSection'
import useStore from '../store'
import { t } from 'i18next'

export const SoapReport: React.FC<{
  type: string
}> = ({ type }) => {
  const editedSummaries = useStore((state) => state.editedSummaries)
  const sections = useMemo(
    () =>
      editedSummaries
        .find((s) => s.protocol_name === type)
        ?.content.map((c) => {
          const titleMap = {
            S: t('report.title.subjective', 'Subjective'),
            O: t('report.title.objective', 'Objective'),
            A: t('report.title.assessment', 'Assessment'),
            P: t('report.title.plan', 'Plan'),
            SUMMARY: t('report.title.plan', 'PATIENT REPORT'),
          }
          return {
            field_name: c.field_name,
            title: titleMap[c.field_name as keyof typeof titleMap],
            content: c.content,
          }
        }) || [],
    [editedSummaries, type],
  )

  return (
    <div className={styles.reportContent}>
      {sections.map((section) => (
        <SoapSection
          key={section.title}
          title={section.title}
          content={section.content}
          fieldName={section.field_name}
          protocol={type}
        />
      ))}
    </div>
  )
}
