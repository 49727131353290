import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useAuth } from '../../../../hooks/useAuth'
import { useResetPassword } from '../../../../hooks/useUser/useUser'
import { Toast } from '../../../core/Toast/Toast'
import Button from '../../../core/Button/Button'

const LoginInformation = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { mutate: resetPassword } = useResetPassword(user?.id!)
  const [toastConfig, setToastConfig] = useState<{
    show: boolean
    variant: string
    message: string
  }>({
    show: false,
    variant: '',
    message: '',
  })

  const handleResetPassword = async () => {
    if (!user?.id) return

    resetPassword(undefined, {
      onSuccess: () => {
        setToastConfig({
          show: true,
          variant: 'success',
          message: t('settings.profile.password.reset.success', 'Password reset email sent'),
        })
      },
      onError: () => {
        setToastConfig({
          show: true,
          variant: 'danger',
          message: t('settings.profile.password.reset.error', 'Failed to reset password'),
        })
      },
    })
  }

  return (
    <div className="settings-card">
      {toastConfig.show && (
        <Toast variant={toastConfig.variant} message={toastConfig.message} />
      )}
      <h2 className="h3">{t('settings.profile.login', 'Login Information')}</h2>
      <p className="regular-300-gray-700">
        {t('settings.profile.login.description', 'Use the button below to update your password')}
      </p>
      <Button variant="secondary" onClick={handleResetPassword}>
        {t('settings.profile.password.reset', 'Reset Password')}
      </Button>
    </div>
  )
}

export default LoginInformation 