import DataTable, { TableProps } from 'react-data-table-component'
import { useTranslation } from 'react-i18next'
import { sortIcon } from './SortIcon'
import { dataTableStyles } from './dataTable.style'

export const Table = <T,>(props: TableProps<T>) => {
  const { t } = useTranslation()

  return (
    <DataTable
      {...props}
      sortIcon={sortIcon}
      customStyles={dataTableStyles}
      paginationComponentOptions={{
        rowsPerPageText: t('table.rowsPerPageText', 'Rows per page'),
      }}
    />
  )
}
