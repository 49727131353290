import { remark } from 'remark'
import remarkHtml from 'remark-html'

import rehypeParse from 'rehype-parse'
import rehypeRemark from 'rehype-remark'
import remarkStringify from 'remark-stringify'
import { visit } from 'unist-util-visit'
import remarkIns from 'remark-ins'

function rehypeHandleIns() {
  return () => {
    return (tree: any) => {
      visit(tree, 'element', (node) => {
        if (node.tagName === 'ins') {
          // Transform <ins> to Markdown-style inserted text (e.g., ++text++)
          node.type = 'text'
          node.value = `++${node.children.map((child: any) => child.value).join('')}++`
          delete node.tagName
          delete node.children
        }
      })
    }
  }
}

export function markdownToHtml(markdownText: string) {
  const file = remark()
    .use(remarkHtml)
    .use(remarkIns) // Handle ++text++ syntax
    .processSync(markdownText)
  return String(file)
}

export function htmlToMarkdown(htmlText: string) {
  const file = remark()
    .use(rehypeParse, { emitParseErrors: true, duplicateAttribute: false })
    .use(rehypeHandleIns()) // Handle <ins> tags
    .use(rehypeRemark)
    .use(remarkStringify)
    .processSync(htmlText)

  return String(file)
}
