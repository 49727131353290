import React from 'react'
import { NavigationPanelProps } from './NavigationPanel'

export const AsideNavigationPanel: React.FC<NavigationPanelProps> = ({
  allowedLinks,
  asideLinks = [],
}) => {
  return (
    <aside className="app-side-nav d-flex">
      <div>{allowedLinks}</div>
      <div>{asideLinks}</div>
    </aside>
  )
}
