import React, { useRef } from 'react'
import styles from './ChatContainer.module.scss'
import { ChatMessage } from './ChatMessage'
import useStore from '../store'

export const ChatContainer: React.FC = () => {
  const transcript = useStore((state) => state.transcript)
  const anon = useStore((state) => state.anonymization)
  const firstAnonValue = useRef<boolean>()

  // We care only about value for existing consult,
  if (firstAnonValue.current === undefined) {
    firstAnonValue.current = anon
  }
  return (
    <div className={styles.container}>
      {(
        transcript[
          firstAnonValue.current ? 'transcript_anonymized' : 'transcript_raw'
        ]?.segments || []
      ).map((message, index) => (
        <ChatMessage
          key={index}
          text={message.text}
          isUser={!message.speaker}
        />
      ))}
    </div>
  )
}
