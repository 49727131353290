export const ERROR_MESSAGE = 'error'
export const TOKEN = 'token'

export const hasNumber = (value: string) => /^.*[0-9].*/.test(value)
export const hasSpecialCharacter = (value: string) =>
  /^.*(?=.*[!@#$%^&+=]).*$/.test(value)
export const hasLowerCase = (value: string) => value.toUpperCase() !== value
export const hasUpperCase = (value: string) => value.toLowerCase() !== value
export const hasLength = (value: string) => value.length > 7

export const rules = [
  hasNumber,
  hasSpecialCharacter,
  hasLowerCase,
  hasUpperCase,
]
export const validatePassword = (value: string) => {
  const count = rules.reduce(
    (acc, validate) => (validate(value) ? acc + 1 : acc),
    0,
  )

  return count > 2
}
