import { WithClassName } from '../Icon'

export const Phone = ({ className }: WithClassName) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.97397 1.537L9.19497 5.758L6.85297 8.1L6.31297 8.64L6.69097 9.303C7.63797 10.961 8.80397 12.489 10.157 13.842C11.511 15.196 13.038 16.362 14.696 17.308L15.359 17.686L15.899 17.146L18.241 14.804L22.462 19.025L19.486 21.99C19.161 22.314 18.713 22.5 18.256 22.5C18.078 22.5 17.901 22.472 17.732 22.418C13.925 21.196 10.552 19.135 7.70897 16.291C4.86497 13.447 2.80397 10.075 1.58197 6.268C1.38397 5.65 1.54697 4.977 2.00997 4.513L4.97397 1.537ZM4.97397 0.5C4.74097 0.5 4.50797 0.589 4.32997 0.767L1.30097 3.808C0.579974 4.532 0.316974 5.6 0.629974 6.574C1.85297 10.386 3.97597 13.973 7.00197 16.999C10.028 20.025 13.615 22.147 17.427 23.371C17.699 23.458 17.979 23.501 18.257 23.501C18.972 23.501 19.672 23.22 20.193 22.7L23.233 19.672C23.59 19.317 23.59 18.739 23.234 18.383L18.887 14.036C18.709 13.858 18.476 13.769 18.243 13.769C18.01 13.769 17.777 13.858 17.599 14.036L15.194 16.441C13.645 15.557 12.187 14.457 10.866 13.136C9.54497 11.815 8.44497 10.357 7.56097 8.808L9.96597 6.403C10.322 6.047 10.322 5.471 9.96597 5.115L5.61697 0.766C5.43897 0.588 5.20697 0.5 4.97397 0.5Z"
      fill="#00708D"
    />
  </svg>
)
