import React from 'react'
import { t } from 'i18next'
import stars from '../../core/Icon/icons/svg/type-ai.svg'
import doctor from '../../core/Icon/icons/png/doctor.png'
import useStore, { Page } from './store'

interface ConsultConfigProps {}

const ConsultConfig: React.FC<ConsultConfigProps> = () => {
  const setPage = useStore((state) => state.setPage)
  return (
    <>
      <div>
        <h4 className="consult-head-title">
          {t('consult.menu.header', 'Start a new consultation')}
        </h4>
        <div className="consult-head-subtitle">
          {t(
            'consult.menu.subheader',
            'Select which type of consultation you want to conduct.',
          )}
        </div>
      </div>
      <div className="consult-block" onClick={() => setPage(Page.newAi)}>
        <img className="consult-block-img" alt="" src={stars} />
        <div className="consult-block-title">
          {t('consult.menu.starsaititle', 'AI-enhanced consultation')}
        </div>
        <div className="consult-block-description">
          {t(
            'consult.menu.starsaitext',
            'Ditto AI will transcribe your conversation with the patient and propose a medical and patient-level summary.',
          )}
        </div>
      </div>
      <div className="consult-block" onClick={() => setPage(Page.newManual)}>
        <img className="consult-block-img" alt="" src={doctor} />
        <div className="consult-block-title">
          {t('consult.menu.standardtitle', 'Standard consultation')}
        </div>
        <div className="consult-block-description">
          {t(
            'consult.menu.standardtext',
            'Type your patient summary or use preferred templates.',
          )}
        </div>
      </div>
    </>
  )
}

export default ConsultConfig
