import { useEffect, useState } from 'react'
import { Attachment } from '../../types/domain'
import {
  useAttachments,
  useCreateAttachment,
  useUpdateAttachment,
  useDeleteAttachment,
} from './useAttachmentBase'
import { useAuth } from '../useAuth'

export const useAttachment = () => {
  const { user } = useAuth()
  const [attachments, setAttachments] = useState<Attachment[]>([])

  const { data, isLoading, refetch, error } = useAttachments()
  const createAttachmentMutation = useCreateAttachment()
  const updateAttachmentMutation = useUpdateAttachment()
  const deleteAttachmentMutation = useDeleteAttachment()

  useEffect(() => {
    if (data && user) {
      const filteredList = data.filter(
        (attachment) =>
          attachment.userId === user.id ||
          attachment.organizationId === user.organizationId ||
          (attachment.userId === null && attachment.organizationId === null),
      )
      setAttachments(filteredList)
    }
  }, [data, user])

  const addAttachment = async (newAttachmentData: Partial<Attachment>) => {
    try {
      const result = await createAttachmentMutation.mutateAsync(newAttachmentData)
      await refetch()
      return result
    } catch (error) {
      console.error('Error adding attachment:', error)
      throw error
    }
  }

  const updateAttachment = async (
    id: number,
    updatedAttachment: Partial<Attachment>,
  ) => {
    try {
      const result = await updateAttachmentMutation.mutateAsync({ id, ...updatedAttachment })
      await refetch()
      return result
    } catch (error) {
      console.error('Error updating attachment:', error)
      throw error
    }
  }

  const deleteAttachment = async (id: number) => {
    try {
      const result = await deleteAttachmentMutation.mutateAsync({ id })
      await refetch()
      return result
    } catch (error) {
      console.error('Error deleting attachment:', error)
      throw error
    }
  }

  return {
    attachments,
    isLoading,
    error,
    addAttachment,
    updateAttachment,
    deleteAttachment,
    refreshAttachments: refetch,
  }
}
