import { WithClassName } from '../Icon'

export const CompanyDetails = ({ className }: WithClassName) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3334 6.99999L18.6667 11.6667V2.33333H11.6667V8.16666H1.16675V25.6667H26.8334V6.99999H23.3334ZM2.33341 9.33333H11.6667V11.6667H2.33341V9.33333ZM9.91675 18.6667C9.91675 17.3798 10.9632 16.3333 12.2501 16.3333C13.5369 16.3333 14.5834 17.3798 14.5834 18.6667C14.5834 19.9535 13.5369 21 12.2501 21C10.9632 21 9.91675 19.9535 9.91675 18.6667ZM17.5001 24.5H12.8334V22.1083C14.4877 21.8295 15.7501 20.4003 15.7501 18.6667C15.7501 16.7335 14.1832 15.1667 12.2501 15.1667C10.3169 15.1667 8.75008 16.7335 8.75008 18.6667C8.75008 20.4003 10.0124 21.8283 11.6667 22.1083V24.5H2.33341V19.8333H7.74908C7.65108 19.4577 7.58342 19.0715 7.58342 18.6667H2.33341V16.3333H8.23208C8.48992 15.8923 8.81308 15.5003 9.19575 15.1667H2.33341V12.8333H11.6667V14.0595C11.8604 14.035 12.0506 14 12.2501 14C12.4496 14 12.6397 14.035 12.8334 14.0595V3.49999H17.5001V24.5ZM25.6667 24.5H18.6667V13.3163L23.8164 8.16666H25.6667V24.5Z"
      fill="#00708D"
    />
  </svg>
)
