import { createContext } from 'react'
import { AuthContextType } from './types/domain'
import { useAuth0 } from '@auth0/auth0-react'
import { useCurrentUser } from './hooks/useUser/useUser'

export const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    isAuthenticated,
    isLoading: isAuth0Loading,
    error: auth0Error,
  } = useAuth0()

  const {
    data: user,
    isLoading: isUserLoading,
    error: userError,
  } = useCurrentUser()

  const loading = isAuth0Loading || isUserLoading

  const isAuthorized = isAuthenticated && !!user

  const error = auth0Error || userError

  const value = {
    user: user || null,
    loading,
    isAuthorized,
    error: error as Error | undefined,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
