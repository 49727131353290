import * as React from 'react'
import {
  Modal as BootstrapModal,
  ModalBody as BootstrapModalBody,
  ModalDialog as BootstrapModalDialog,
  ModalFooter as BootstrapModalFooter,
  ModalHeader as BootstrapModalHeader,
  ModalProps as BootstrapModalProps,
  ModalHeaderProps,
} from 'react-bootstrap'
import type { BsPrefixProps } from 'react-bootstrap/helpers'

export interface ModalProps extends BootstrapModalProps {}

const Modal = (props: ModalProps) => {
  return <BootstrapModal {...props} />
}

const ModalBody = (
  props: BsPrefixProps<'div'> | React.HTMLAttributes<HTMLElement>,
) => {
  return <BootstrapModalBody {...props} />
}

const ModalFooter = (
  props: BsPrefixProps<'div'> | React.HTMLAttributes<HTMLElement>,
) => {
  return <BootstrapModalFooter {...props} />
}

const ModalHeader = (props: ModalHeaderProps) => {
  return <BootstrapModalHeader {...props} />
}

const ModalDialog = (props: ModalHeaderProps) => {
  return <BootstrapModalDialog {...props} />
}

export default Object.assign(Modal, {
  Body: ModalBody,
  Header: ModalHeader,
  Footer: ModalFooter,
  Dialog: ModalDialog,
})
