import { WithClassName } from '../Icon'

export const Clock = ({ className }: WithClassName) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
      <path fillRule="evenodd" clipRule="evenodd"
            d="M10.5 3.33334C6.81812 3.33334 3.83335 6.31811 3.83335 10C3.83335 13.6819 6.81812 16.6667 10.5 16.6667C14.1819 16.6667 17.1667 13.6819 17.1667 10C17.1667 6.31811 14.1819 3.33334 10.5 3.33334ZM2.16669 10C2.16669 5.39763 5.89765 1.66667 10.5 1.66667C15.1024 1.66667 18.8334 5.39763 18.8334 10C18.8334 14.6024 15.1024 18.3333 10.5 18.3333C5.89765 18.3333 2.16669 14.6024 2.16669 10Z"
            fill="#5A545C" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M10.5 5.00001C10.9603 5.00001 11.3334 5.3731 11.3334 5.83334V9.16667H14.6667C15.1269 9.16667 15.5 9.53977 15.5 10C15.5 10.4602 15.1269 10.8333 14.6667 10.8333H10.5C10.0398 10.8333 9.66669 10.4602 9.66669 10V5.83334C9.66669 5.3731 10.0398 5.00001 10.5 5.00001Z"
            fill="#5A545C" />
  </svg>
)
