import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Cookies from 'js-cookie'
import Button from '../../../core/Button/Button'
import { COOKIE_CONSENT } from '../../Auth/Login/CookieConsentModal'

const CookieSettings = () => {
  const { t } = useTranslation()
  const [valueFromCookie, setValueFromCookie] = useState(
    Cookies.get(COOKIE_CONSENT) === 'true',
  )
  const [useCookie, setUseCookie] = useState(valueFromCookie)

  const saveConsent = () => {
    setValueFromCookie(useCookie)
    Cookies.set(COOKIE_CONSENT, useCookie.toString())
  }

  return (
    <div className="settings-card">
      <div className="settings-card-header">
        <h2 className="h3">
          {t('settings.privacy.cookies', 'Cookie Settings')}
        </h2>
        <div>
          <Button
            variant="primary"
            onClick={saveConsent}
            disabled={valueFromCookie === useCookie}
          >
            {t('common.save', 'Save')}
          </Button>
        </div>
      </div>

      <p className="regular-300-gray-700">
        {t('settings.privacy.cookies.description', 
          'These cookies help us improve our services by collecting anonymous usage data.')}
      </p>

      <Form.Check
        className="settings-radio-option"
        type="radio"
        id="cookie-accept"
      >
        <Form.Check.Input
          type="radio"
          name="cookie"
          checked={useCookie}
          onChange={() => setUseCookie(true)}
        />
        <Form.Check.Label>
          {t('settings.privacy.cookies.accept', 'Accept all cookies')}
        </Form.Check.Label>
      </Form.Check>

      <Form.Check
        className="settings-radio-option"
        type="radio"
        id="cookie-reject"
      >
        <Form.Check.Input
          type="radio"
          name="cookie"
          checked={!useCookie}
          onChange={() => setUseCookie(false)}
        />
        <Form.Check.Label>
          {t('settings.privacy.cookies.reject', 'Reject non-essential cookies')}
        </Form.Check.Label>
      </Form.Check>

      <div className="settings-card-footer">
        <Trans
          i18nKey="settings.privacy.cookies.policy"
          t={t}
          defaults="Read our <Link>Cookie Policy</Link> to learn more."
          components={{
            Link: <Link to="/cookie-policy" target="_blank" />,
          }}
        />
      </div>
    </div>
  )
}

export default CookieSettings 