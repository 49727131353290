import React from 'react'
import { useTemplates } from '../../../hooks/useTemplate/useTemplateBase'
import { Spinner } from 'react-bootstrap'
import { LIBRARY_TYPE, Template } from '../../../types/domain'
import { SearchInput } from '../../core/SearchInput/SearchInput'
import { AttachmentCounter } from '../../core/AttachmentCounter/AttachmentCounter'

export const TemplateLibrary: React.FC = () => {
  const { data: templates = [], isLoading } = useTemplates()
  const [searchTerm, setSearchTerm] = React.useState('')

  const handleDragStart = (e: React.DragEvent, template: Template) => {
    if (e.dataTransfer) {
      const templateData = {
        ...template,
        attachments: template.attachments || [],
      }
      e.dataTransfer.setData('template', JSON.stringify(templateData))
    }
  }

  const getContentByType = (items: Template[], type: LIBRARY_TYPE) => {
    const filtered = items.filter((template) =>
      template.displayName.toLowerCase().includes(searchTerm.toLowerCase()),
    )

    if (type === LIBRARY_TYPE.ORGANIZATION) {
      return filtered.filter((item) => item.organizationId != null)
    }
    if (type === LIBRARY_TYPE.PERSONAL) {
      return filtered.filter(
        (item) => item.organizationId === null && item.userId != null,
      )
    }
    return filtered.filter(
      (item) => item.organizationId === null && item.userId === null,
    )
  }

  if (isLoading) return <Spinner />

  return (
    <div className="library-list">
      <div className="library-list-wrapper">
        <div className="library-list__search-wrapper">
          <SearchInput onChange={setSearchTerm} value={searchTerm} />
        </div>
        <div className="library-list__section">
          <div className="library-list__section-title">Organization</div>
          <div className="library-list__section-list-wrapper">
            {getContentByType(templates, LIBRARY_TYPE.ORGANIZATION).map(
              (template) => (
                <div
                  className="library-list__section-item-wrapper"
                  key={template.id}
                  draggable
                  onDragStart={(e) => handleDragStart(e, template)}
                >
                  <div className="library-list__section-item">
                    <div
                      className="library-list__section-item-title"
                      data-noclosesoapeditor="true"
                    >
                      {template.displayName}
                    </div>
                    <div className="library-list__section-item-attachment-counter">
                      <AttachmentCounter count={template.attachmentCount} />
                    </div>
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
        <div className="library-list__section">
          <div className="library-list__section-title">Personal</div>
          <div className="library-list__section-list-wrapper">
            {getContentByType(templates, LIBRARY_TYPE.PERSONAL).map(
              (template) => (
                <div
                  className="library-list__section-item-wrapper"
                  key={template.id}
                  draggable
                  onDragStart={(e) => handleDragStart(e, template)}
                >
                  <div className="library-list__section-item">
                    <div
                      className="library-list__section-item-title"
                      data-noclosesoapeditor="true"
                    >
                      {template.displayName}
                    </div>
                    <div className="library-list__section-item-attachment-counter">
                      <AttachmentCounter count={template.attachmentCount} />
                    </div>
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
        <div className="library-list__section">
          <div className="library-list__section-title">General</div>
          <div className="library-list__section-list-wrapper">
            {getContentByType(templates, LIBRARY_TYPE.DEFAULT).map(
              (template) => (
                <div
                  className="library-list__section-item-wrapper"
                  key={template.id}
                  draggable
                  onDragStart={(e) => handleDragStart(e, template)}
                >
                  <div className="library-list__section-item">
                    <div
                      className="library-list__section-item-title"
                      data-noclosesoapeditor="true"
                    >
                      {template.displayName}
                    </div>
                    <div className="library-list__section-item-attachment-counter">
                      <AttachmentCounter count={template.attachmentCount} />
                    </div>
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
