import React, { useEffect } from 'react'
import styles from './HistoryItem.module.scss'
import { HistoryItemProps } from './types'
import useStore, { Transcript } from '../store'
import { makeRequest } from '../../../../hooks/Ai/useAiApiQuery/useAiApiQuery'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'

export const HistoryItem: React.FC<HistoryItemProps> = ({
  title,
  consultation_id,
  created_at,
  duration,
}) => {
  const setTranscript = useStore((state) => state.setTranscript)
  const setSummaries = useStore((state) => state.setEditedSummaries)
  const setConsultId = useStore((state) => state.setConsultId)
  const chosenConsultId = useStore((state) => state.consultId)
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const formatDate = (input: string) => {
    // Parse the input string into a Date object (interpreted as UTC)
    const utcDate: Date = new Date(input)

    // Check if utcDate is an invalid date
    if (isNaN(utcDate.getTime())) {
      return []
    }

    // Convert to user's local time zone using getTimezoneOffset
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000,
    )

    const day = localDate.getDate().toString().padStart(2, '0') // Ensure 2-digit day
    const month = localDate.toLocaleString('en-US', { month: 'short' }) + '.' // Add period after short month
    const year = localDate.getFullYear()

    const formattedDate = `${day} ${month} ${year}`

    // Get formatted time part in user's local time zone
    const formattedTime: string = localDate.toTimeString().slice(0, 5)

    // Combine the date and time
    return [formattedDate, formattedTime]
  }

  const { getAccessTokenSilently } = useAuth0()
  useEffect(() => {
    const handleUpdateView = async () => {
      let response
      try {
        response = await queryClient.fetchQuery({
          queryKey: ['consultation_list', consultation_id],
          queryFn: () =>
            makeRequest(getAccessTokenSilently, 'GET', {
              path: `/consultation/${consultation_id}`,
            }),
          staleTime: 1000 * 60 * 180, // 180 minutes
        })
        setTranscript(response as Transcript)
        setSummaries(
          (response as Transcript).summaries
            ? [...(response as Transcript).summaries]
            : [],
        )
      } catch (error) {
        console.log(error)
      }
    }
    if (consultation_id === chosenConsultId) {
      handleUpdateView()
    }
  }, [chosenConsultId])
  return (
    <div
      onClick={async () => {
        setConsultId(consultation_id)
      }}
      className={`cursor-pointer ${styles.item} ${chosenConsultId === consultation_id ? styles.itemChosen : ''}`}
    >
      <div className={styles.title}>
        {title ?? t('history.title.backup', 'Consultation')}
      </div>
      <div className={styles.metaContainer}>
        {formatDate(created_at).map((el, key, array) => {
          return (
            <>
              <div className={styles.metaText}>{el}</div>
              <div className={styles.dotCenter} />
            </>
          )
        })}

        {duration && (
          <>
            <div className={styles.metaText}>
              {duration > 60
                ? `${Math.floor(duration / 60)}${t('history.item.min', 'min')}`
                : `${duration}${t('history.item.sec', 'sec')}`}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
