import React, { PropsWithChildren } from 'react'
import {
  Button as BootstrapButton,
  ButtonProps as BootstrapButtonProps,
  Spinner,
} from 'react-bootstrap'
import { ButtonVariant } from 'react-bootstrap/types'
import Icon, { ICON_TYPE } from '../Icon/Icon'
import { IconName } from '../../../utils/getIconClass/getIconClass'

type ButtonProps = PropsWithChildren<{
  onClick?: (ev: React.MouseEvent) => void
  variant?: ButtonVariant | 'label-icon'
  size?: BootstrapButtonProps['size']
  disabled?: boolean
  isLoading?: boolean
  className?: string
  type?: 'submit' | 'reset' | 'button' | undefined
  icon?: ICON_TYPE | IconName
  id?: string
}>

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  variant,
  size,
  disabled,
  className,
  type,
  isLoading,
  icon,
  id,
  ...rest
}) => {
  return (
    <BootstrapButton
      id={id}
      data-testid={id}
      onClick={onClick}
      size={size}
      variant={variant}
      disabled={disabled || isLoading}
      className={className}
      type={type}
      {...rest}
    >
      {icon && <Icon icon={icon} className="ms-2" />}
      {isLoading ? <Spinner animation="border" /> : children}      
    </BootstrapButton>
  )
}

export default Button
