import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import useRole from '../../../hooks/useRole'
import LabelIconButton from '../../core/Button/LabelIconButton'
import { Table } from '../../shared/Table/Table'
import InviteUserForm from './InviteUserForm'

type OrganizationMember = {
  userId: string
  role: string
}

type User = {
  id: string
  firstName: string
  lastName: string
  jobTitle: string
  email: string
}

type Organization = {
  id: string
  name: string
  ownerId: string
  members: OrganizationMember[]
}

type Invite = {
  email: string
  role: string
  createdAt: string
  invitationId: string
}

const OrganizationDetails: React.FC = () => {
  const { user } = useAuth()
  const { isAdmin, isOwner, hasRole } = useRole()
  const [organization, setOrganization] = useState<Organization | null>(null)
  const [memberDetails, setMemberDetails] = useState<User[]>([])
  const [invitationId, setInvitationId] = useState('')
  const [pendingInvites, setPendingInvites] = useState<Invite[]>([])
  const navigate = useNavigate()

  // useEffect(() => {
  //   const fetchOrganization = async () => {
  //     // if (user?.organizationId) {
  //     //   const orgRef = doc(db, 'organizations', user.organizationId)
  //     //   const orgSnap = await getDoc(orgRef)
  //     //   if (orgSnap.exists()) {
  //     //     const orgData = orgSnap.data() as Organization
  //     //     setOrganization(orgData)
  //     //     // Fetch invitations for the organization
  //     //     const invitesQuery = query(
  //     //       collection(db, 'invitations'),
  //     //       where('organizationId', '==', orgData.id),
  //     //     )
  //     //     const invitesSnapshot = await getDocs(invitesQuery)

  //     //     // If there are invitations, set them in state
  //     //     if (!invitesSnapshot.empty) {
  //     //       const invitesData = invitesSnapshot.docs.map(
  //     //         (doc) => doc.data() as Invite,
  //     //       )
  //     //       setPendingInvites(invitesData)
  //     //     } else {
  //     //       // If there are no invitations, set an empty array
  //     //       setPendingInvites([])
  //     //     }

  //     //     // Fetch member details for all members in the organization
  //     //     const userIds = orgData.members.map((member) => member.userId)
  //     //     const usersQuery = query(
  //     //       collection(db, 'users'),
  //     //       where('id', 'in', userIds),
  //     //     )
  //     //     const usersSnapshot = await getDocs(usersQuery)
  //     //     const membersData = usersSnapshot.docs.map(
  //     //       (doc) => doc.data() as User,
  //     //     )
  //     //     setMemberDetails(membersData)
  //     //   }
  //     // }
  //   }

  //   fetchOrganization()
  // }, [user, db])

  const handleInvitation = () => {
    if (invitationId) {
      // Logic to join the organization using the invitation ID
      alert(
        `You have been assigned to the organization using invitation ID: ${invitationId}`,
      )
    } else {
      alert('Please provide a valid invitation ID.')
    }
  }

  const handleRegisterOrganization = () => {
    navigate('/organization/register')
  }

  const handleRoleChange = async (userId: string, newRole: string) => {
    // const userRef = doc(db, 'users', userId)
    // await updateDoc(userRef, {
    //   role: newRole,
    // })

    // // Update role in the organization as well
    // const orgRef = doc(db, 'organizations', organization!.id)
    // await updateDoc(orgRef, {
    //   members: organization?.members.map((member) =>
    //     member.userId === userId ? { ...member, role: newRole } : member,
    //   ),
    // })

    alert(`Role of user with ID ${userId} updated to ${newRole}`)
  }

  const handleRemoveUser = async (userId: string) => {
    // const orgRef = doc(db, 'organizations', organization!.id)
    // await updateDoc(orgRef, {
    //   members: organization?.members.filter(
    //     (member) => member.userId !== userId,
    //   ),
    // })
    alert(`User with ID ${userId} has been removed from the organization`)
  }

  const resendInvitation = (invitationId: string) => {
    alert(`Resending invitation to ${invitationId}`)
  }

  const cancelInvitation = async (invitationId: string) => {
    // Remove the invitation from the "invitations" collection
    // const inviteRef = doc(db, 'invitations', invitationId)
    // await deleteDoc(inviteRef)
    alert(`Invitation with ID ${invitationId} has been canceled`)
  }

  const columns = [
    {
      name: 'First Name',
      selector: (row: User) => row.firstName,
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: (row: User) => row.lastName,
      sortable: true,
    },
    {
      name: 'Job Title',
      selector: (row: User) => row.jobTitle,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row: User) => row.email,
      sortable: true,
    },
    {
      cell: (row: User) => (
        <div>
          <LabelIconButton
            id="user-remove_icon-btn"
            icon="delete"
            bordered
            onClick={() => handleRemoveUser(row.id)}
          />
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  const inviteColumns = [
    {
      name: 'Email',
      selector: (row: Invite) => row.email,
      sortable: true,
    },
    {
      name: 'Role',
      selector: (row: Invite) => row.role,
      sortable: true,
    },
    {
      cell: (row: Invite) => (
        <div>
          <button onClick={() => resendInvitation(row.invitationId)}>
            Resend
          </button>
          <button onClick={() => cancelInvitation(row.invitationId)}>
            Cancel
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  return (
    <div>
      {user && organization ? (
        <div>
          <h1>{organization.name}</h1>
          {(isOwner || isAdmin) && (
            <div>
              {memberDetails.length > 0 ? (
                <Table
                  columns={columns}
                  data={memberDetails}
                  pagination
                  highlightOnHover
                  pointerOnHover
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5, 10, 20]}
                  noDataComponent={<div>No members available</div>}
                />
              ) : (
                <div>No members available</div>
              )}

              {pendingInvites.length > 0 ? (
                <div>
                  <h3>Pending Invitations</h3>
                  <Table
                    columns={inviteColumns}
                    data={pendingInvites}
                    pagination
                    highlightOnHover
                    pointerOnHover
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 20]}
                    noDataComponent={<div>No pending invitations</div>}
                  />
                </div>
              ) : (
                <div>No pending invitations</div>
              )}

              <div>
                {/* <InviteUserForm
                  organizationId={user.organization.id}
                  organizationName={user.organization.name}
                /> */}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <p>You are not currently a member of any organization.</p>
          <div>
            <input
              type="text"
              placeholder="Enter Invitation ID"
              value={invitationId}
              onChange={(e) => setInvitationId(e.target.value)}
            />
            <button onClick={handleInvitation}>
              Join Organization with Invitation
            </button>
          </div>
          <div>
            <button onClick={handleRegisterOrganization}>
              Start Organization Registration
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default OrganizationDetails
