import React, { useState } from 'react'
import Modal from '../../shared/Modal/Modal'
import Button from '../../core/Button/Button'
import { Form } from 'react-bootstrap'
import { t } from 'i18next'

interface AddLinkModalProps {
  show: boolean
  onHide: () => void
  onAdd: (url: string) => void
}

const AddLinkModal: React.FC<AddLinkModalProps> = ({ show, onHide, onAdd }) => {
  const [url, setUrl] = useState('')
  const [isUrlValid, setIsUrlValid] = useState(true)

  const validateUrl = (url: string) => {
    const urlPattern = /^(https?:\/\/[^\s$.?#].[^\s]*)$/
    const isValid = urlPattern.test(url)
    setIsUrlValid(isValid)
    return isValid
  }

  const handleAdd = () => {
    if (url && validateUrl(url)) {
      onAdd(url)
      setUrl('')
      setIsUrlValid(true)
      onHide()
    }
  }

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUrl = e.target.value
    setUrl(newUrl)
    if (newUrl) {
      validateUrl(newUrl)
    } else {
      setIsUrlValid(true)
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="max-w-500px"
      contentClassName="rounded-2"
    >
      <Modal.Body className="p-600">
        <h4 className="mb-500">{t('attachments.modal.title', 'New link')}</h4>
        <Form.Group className="mb-3">
          <Form.Label>{t('attachments.modal.url', 'URL')}</Form.Label>
          <Form.Control
            type="text"
            value={url}
            onChange={handleUrlChange}
            placeholder={t('attachments.modal.placeholder', 'Enter here')}
            isInvalid={!isUrlValid}
          />
          {!isUrlValid && (
            <Form.Control.Feedback type="invalid">
              {t('attachments.modal.invalid-url', 'Please enter a valid URL')}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <div className="d-flex justify-content-end gap-2">
          <Button variant="secondary" onClick={onHide}>
            {t('common.cancel', 'Cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={handleAdd}
            disabled={!url || !isUrlValid}
          >
            {t('attachments.modal.add', 'Add link')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddLinkModal