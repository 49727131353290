import {
  QueryKey,
  UseQueryOptions,
  UseMutationOptions,
  useQuery,
  useMutation,
} from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import axios, { AxiosError, Method } from 'axios'

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

interface AuthQueryConfig {
  baseUrl: string
  path: string
  params?: Record<string, any>
  data?: any
}

const makeAuthRequest = async (
  getToken: () => Promise<string>,
  method: HttpMethod,
  { baseUrl, path, params, data }: AuthQueryConfig,
) => {
  try {
    const token = await getToken()

    let finalPath = path
    if (data?.id && finalPath.includes('{id}')) {
      finalPath = finalPath.replace('{id}', data.id.toString())
    }

    const response = await axios({
      method,
      url: `${baseUrl}${finalPath}`,
      params,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (error) {
    console.error(`Error in ${method} request to ${path}:`, error)
    if (axios.isAxiosError(error)) {
      console.error('Axios response data:', error.response?.data)
      throw error.response?.data
    }
    throw error
  }
}

export function useAuthQuery<TData = unknown, TError = unknown>(
  queryKey: QueryKey,
  config: AuthQueryConfig,
  options?: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'>,
) {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery<TData, TError>({
    queryKey,
    queryFn: async () => {
      try {
        return await makeAuthRequest(getAccessTokenSilently, 'GET', config)
      } catch (error) {
        if (error instanceof Error) {
          if (error.message === 'TOKEN_EXPIRED') {
            const newToken = await getAccessTokenSilently()
            return makeAuthRequest(
              () => Promise.resolve(newToken),
              'GET',
              config,
            )
          }
        }
        throw error
      }
    },
    ...options,
  })
}

export function useAuthMutationPost<
  TData = unknown,
  TError = unknown,
  TVariables = any,
>(
  config: Omit<AuthQueryConfig, 'data'>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationFn'>,
) {
  const { getAccessTokenSilently } = useAuth0()

  return useMutation<TData, TError, TVariables>({
    mutationFn: (variables) =>
      makeAuthRequest(getAccessTokenSilently, 'POST', {
        ...config,
        data: variables,
      }),
    ...options,
  })
}

export function useAuthMutationPut<
  TData = unknown,
  TError = unknown,
  TVariables = any,
>(
  config: Omit<AuthQueryConfig, 'data'>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationFn'>,
) {
  const { getAccessTokenSilently } = useAuth0()

  return useMutation<TData, TError, TVariables>({
    mutationFn: (variables) =>
      makeAuthRequest(getAccessTokenSilently, 'PUT', {
        ...config,
        data: variables,
      }),
    ...options,
  })
}

export function useAuthMutationDelete<
  TData = unknown,
  TError = unknown,
  TVariables = any,
>(
  config: Omit<AuthQueryConfig, 'data'>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationFn'>,
) {
  const { getAccessTokenSilently } = useAuth0()

  return useMutation<TData, TError, TVariables>({
    mutationFn: (variables) =>
      makeAuthRequest(getAccessTokenSilently, 'DELETE', {
        ...config,
        data: variables,
      }),
    ...options,
  })
}
