import React, { useEffect, useRef, useState } from 'react'
import styles from './SoapSection.module.scss'
import { SoapSectionProps } from './types'
import useStore, { Transcript } from '../store'
import { useAiMutationPost } from '../../../../hooks/Ai/useAiApiQuery/useAiApiQuery'
import { useQueryClient } from '@tanstack/react-query'
import MarkdownEditor, {
  EditorContentChanged,
} from '../../../core/MarkdownEditor/MarkdownEditor'
import Markdown from 'react-markdown'
import { DOCTOR_PROTOCOL } from '../../../../utils/constants'

export const SoapSection: React.FC<SoapSectionProps> = ({
  title,
  content,
  fieldName,
  protocol,
}) => {
  const [value, setValue] = useState(content)
  const sectionRef = useRef<HTMLDivElement | null>(null)
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const editedFieldName = useStore((state) => state.editedFieldName)
  const setEditedFieldName = useStore((state) => state.setEditedFieldName)
  const setEditedSummaries = useStore((state) => state.setEditedSummaries)
  const editedSummaries = useStore((state) => state.editedSummaries)
  const consultId = useStore((state) => state.consultId)
  useEffect(() => {
    // on rerender initial state should be updated
    setValue(content)
  }, [content])

  const postSummaryMutation = useAiMutationPost({
    path: `/consultation/${consultId}/summary`,
  })

  // Adjust textarea height
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current
    if (textarea) {
      textarea.style.height = 'auto' // Reset height
      textarea.style.height = `${textarea.scrollHeight}px` // Adjust height to content
    }
  }

  // Adjust height on value or editedFieldName change
  useEffect(() => {
    adjustTextareaHeight()
  }, [value, editedFieldName])
  const queryClient = useQueryClient()
  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetElement = event.target as Element
      if (
        sectionRef.current &&
        !sectionRef.current.contains(event.target as Node) &&
        targetElement.getAttribute('data-noclosesoapeditor') !== 'true'
      ) {
        if (fieldName === editedFieldName) {
          postSummaryMutation.mutate(editedSummaries, {
            onSuccess: () => {
              queryClient.setQueryData(
                ['consultation_list', consultId],
                (oldData: Transcript) => {
                  return { ...oldData, summaries: [...editedSummaries] }
                },
              )
            },
          })
          setEditedFieldName('')
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [fieldName, editedFieldName])

  const handleInput = (value: string) => {
    setValue(value)
    const summaryIndex = editedSummaries.findIndex(
      (s) => s.protocol_name === protocol,
    )
    const fieldIndex = editedSummaries[summaryIndex].content.findIndex(
      (field) => field.field_name === fieldName,
    )
    editedSummaries[summaryIndex].content[fieldIndex].content = value
    setEditedSummaries([...editedSummaries])
  }

  const editorContentToString = (value: EditorContentChanged) => {
    const stringValue = value.markdown
    handleInput(stringValue)
  }

  return (
    <div
      className={styles.section}
      ref={sectionRef}
      onClick={() => {
        setEditedFieldName(fieldName)
      }}
    >
      <div className={styles.sectionTitle}>{title}</div>
      <div className={styles.sectionContent}>
        <div className={styles.contentWrapper}>
          <div className={styles.contentContainer}>
            {editedFieldName === fieldName ? (
              protocol === DOCTOR_PROTOCOL ? (
                <textarea
                  className={styles.textContentEdit}
                  ref={textareaRef}
                  value={value}
                  rows={1}
                  onChange={(e) => handleInput(e.target.value)}
                  onMouseDown={(e) => e.stopPropagation()}
                  style={{ width: '100%', overflow: 'hidden', resize: 'none' }}
                  placeholder=""
                />
              ) : (
                <MarkdownEditor
                  value={value}
                  onChange={(value) => editorContentToString(value)}
                />
              )
            ) : (
              <div className={styles.textContent}>
                <Markdown>{value}</Markdown>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
