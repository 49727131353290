'use client'

import { useTranslation } from 'react-i18next'
import { NL_LANGUAGE } from '../../../i18n'
import { TermsContentNl } from './nl/TermsContentNl'
import { TermsContent } from './en/TermsContent'

export function TermsContainer() {
  const { i18n } = useTranslation()

  if (NL_LANGUAGE === i18n.language) {
    return <TermsContentNl />
  }

  return <TermsContent />
}
