import React, { useEffect } from 'react'
import { t } from 'i18next'
import ArrowLeft from '../../core/Icon/icons/svg/ArrowLeft.svg'
import useStore, { NavigationItem, NavigationTab, Page } from './store'
import { Navigation } from './ChipNavigation/Navigation'
import { RecordingOptions } from './AiConsultForm/RecordingOptions'
import { EMPTY_CONSULT } from '../../../utils/constants'
import { TemplateLibrary } from '../InteractionSummary/TemplateLibrary'

const AiConsult: React.FC = () => {
  const setPage = useStore((state) => state.setPage)
  const setConsultId = useStore((state) => state.setConsultId)
  const navigationState = useStore((state) => state.navigationState)
  const consultId = useStore((state) => state.consultId)
  const initializeNavigationState = useStore(
    (state) => state.initializeNavigationState,
  )

  useEffect(() => {
    initializeNavigationState(Page.newAi)
  }, [initializeNavigationState])

  const navigationItems: NavigationItem[] = [
    {
      label: NavigationTab.OPTIONS,
      isVisible: true,
      isActive: true,
      disabled: false,
    },
    {
      label: NavigationTab.TEMPLATES,
      isVisible: true,
      isActive: false,
      disabled: consultId === EMPTY_CONSULT,
    },
    {
      label: NavigationTab.FILES,
      isVisible: false,
      isActive: false,
      disabled: false,
    },
  ]

  return (
    <>
      <div className="align-self-start">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            setConsultId(EMPTY_CONSULT)
            setPage(Page.config)
          }}
          className="ai-consult-top-link d-flex justify-content-center align-items-center"
        >
          <img alt="" src={ArrowLeft} /> {t('consult.menu.header', 'Back')}
        </a>
      </div>
      <Navigation navigationItems={navigationItems} />
      {navigationState === NavigationTab.OPTIONS && <RecordingOptions />}
      {navigationState === NavigationTab.TEMPLATES && <TemplateLibrary />}
    </>
  )
}

export default AiConsult
