import { Attachment } from '../../../../types/domain'
import { LibraryCard } from '../../../core/LibraryCard/LibraryCard'
import { AttachmentPreview } from '../../../core/AttachmentPreview/AttachmentPreview'

interface AttachmentCardProps {
  attachment: Attachment
  onEdit: (attachment: Attachment) => void
  onDelete: (id: number) => void
}

export function AttachmentCard({ attachment, onEdit, onDelete }: AttachmentCardProps) {
  return (
    <LibraryCard
      title={attachment.displayName}
      date="25 Nov, 2024"
      content={<AttachmentPreview
        metadata={attachment.metadata || {
          title: '',
          description: '',
          domain: '',
        }}
      />}
      onEdit={() => onEdit(attachment)}
      onDelete={() => attachment.id && onDelete(attachment.id)}
    />
  )
}