import { WithClassName } from '../Icon'

export const NLFlag = ({ className }: WithClassName) => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_3014_133686)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H24V18H0V0Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H24V6H0V0Z"
        fill="#AE1C28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6H24V12H0V6Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12H24V18H0V12Z"
        fill="#21468B"
      />
    </g>
    <defs>
      <clipPath id="clip0_3014_133686">
        <rect width="24" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
