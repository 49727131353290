export const getEnvMap = () => {
    const isLocal = (window as any).dittoEnv?.startsWith("$");
    if (isLocal) {
        return new Map();
    }
    return new Map(
        (window as any).dittoEnv
            .trim()
            .split("\t")
            .map((entry: string) => {
                const [key, value] = entry.split("=");
                if (!key || !value) {
                    throw new Error('Unexpected value of the "dittoEnv"');
                }
                return [key, value];
            }),
    );
};

export const getEnvironmentVariable = (key: string): string => {
    const envMap = getEnvMap();
    return envMap.get(key) || process.env[key] || '';
}; 