import { useTranslation } from 'react-i18next'
import CookieSettings from './CookieSettings'

const PrivacySettings = () => {
  const { t } = useTranslation()

  return (
    <div className="settings-section">
      <h1 className="settings-title">
        {t('settings.privacy.title', 'Privacy Settings')}
      </h1>
      <div className="settings-subtitle regular-300-gray-700">
        {t(
          'settings.privacy.subtitle',
          'Manage your privacy and cookie preferences.',
        )}
      </div>

      <CookieSettings />
    </div>
  )
}

export default PrivacySettings