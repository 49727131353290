import { t } from 'i18next'

export const CategoryOptions = [
  {
    id: 0,
    label: t('category.patient-care-services', 'Patient Care Services'),
    parentId: null,
  },
  {
    id: 1,
    label: t('category.primary-care', 'Primary Care'),
    parentId: 0,
  },
  {
    id: 2,
    label: t('category.specialty-care', 'Specialty Care'),
    parentId: 0,
  },
  {
    id: 3,
    label: t('category.emergency-services', 'Emergency Services'),
    parentId: 0,
  },
  {
    id: 4,
    label: t('category.palliative-care', 'Palliative Care'),
    parentId: 0,
  },
  {
    id: 5,
    label: t('category.diagnostic-services', 'Diagnostic Services'),
    parentId: null,
  },
  {
    id: 6,
    label: t('category.laboratory', 'Laboratory'),
    parentId: 5,
  },
  {
    id: 7,
    label: t('category.imaging', 'Imaging'),
    parentId: 5,
  },
  {
    id: 8,
    label: t('category.genetic-testing', 'Genetic Testing'),
    parentId: 5,
  },
  {
    id: 9,
    label: t('category.surgical-services', 'Surgical Services'),
    parentId: null,
  },
  {
    id: 10,
    label: t('category.elective-surgery', 'Elective Surgery'),
    parentId: 9,
  },
  {
    id: 11,
    label: t('category.emergency-surgery', 'Emergency Surgery'),
    parentId: 9,
  },
  {
    id: 12,
    label: t('category.minimally-invasive', 'Minimally Invasive Surgery'),
    parentId: 9,
  },
  {
    id: 13,
    label: t('category.mental-health', 'Mental Health Services'),
    parentId: null,
  },
  {
    id: 14,
    label: t('category.counseling', 'Counseling'),
    parentId: 13,
  },
  {
    id: 15,
    label: t('category.psychiatric-services', 'Psychiatric Services'),
    parentId: 13,
  },
  {
    id: 16,
    label: t('category.substance-abuse', 'Substance Abuse Services'),
    parentId: 13,
  },
  {
    id: 17,
    label: t('category.rehabilitation', 'Rehabilitation Services'),
    parentId: null,
  },
  {
    id: 18,
    label: t('category.physical-therapy', 'Physical Therapy'),
    parentId: 17,
  },
  {
    id: 19,
    label: t('category.occupational-therapy', 'Occupational Therapy'),
    parentId: 17,
  },
  {
    id: 20,
    label: t('category.speech-therapy', 'Speech Therapy'),
    parentId: 17,
  },
  {
    id: 21,
    label: t('category.preventive-services', 'Preventive Services'),
    parentId: null,
  },
  {
    id: 22,
    label: t('category.screenings', 'Screenings'),
    parentId: 21,
  },
  {
    id: 23,
    label: t('category.vaccinations', 'Vaccinations'),
    parentId: 21,
  },
  {
    id: 24,
    label: t('category.health-education', 'Health Education'),
    parentId: 21,
  },
]

export const categoryOptions: CategoryOption[] = CategoryOptions.filter(
  (option) => option.parentId === null,
).map((option) => ({
  value: option.id,
  label: option.label,
}))

export const getSubcategoryOptions = (
  parent: CategoryOption[] | null,
): CategoryOption[] => {
  if (!parent || parent.length === 0) {
    return []
  }

  const parentIds = parent.map((option) => option.value)

  return CategoryOptions.filter((option) =>
    parentIds.includes(option.parentId as number),
  ).map(
    (option) =>
      ({
        value: option.id,
        label: option.label,
      }) as CategoryOption,
  )
}

export interface CategoryOption {
  value: number
  label: string
}

type CategoryId = number
type SubcategoryId = number

export const getCategoryLabels = (categoryIds: CategoryId[]): string => {
  return categoryIds
    .map((id) => {
      const category = CategoryOptions.find((cat) => cat.id === id)
      return category ? category.label : id.toString()
    })
    .join(', ')
}

export const getCategoryOptions = (
  categoryIds: CategoryId[],
): CategoryOption[] => {
  return categoryIds.map((id) => {
    const category = CategoryOptions.find((cat) => cat.id === id)
    return { value: category?.id, label: category?.label } as CategoryOption
  })
}
