import cn from 'classnames'
import { MultipleFieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  hasLowerCase,
  hasNumber,
  hasSpecialCharacter,
  hasUpperCase,
} from './password_constants'

interface Props {
  isDirty: boolean
  passwordErrors?: MultipleFieldErrors
  password: string
}

export const PasswordRequirements = ({
  isDirty,
  passwordErrors,
  password,
}: Props) => {
  const { t } = useTranslation()

  return (
    <div className="w-100 text-muted border p-500 rounded-2 mt-500">
      <div>{t('password.rules.title', 'Your password must')}</div>
      <ul className="mb-0">
        <li className={cn({ success: isDirty && !passwordErrors?.hasLength })}>
          {t('password.rules.length', 'Contain at least 8 characters')}
        </li>
        <li
          className={cn({
            success: isDirty && !passwordErrors?.meetRequirements,
          })}
        >
          {t('password.rules.three', 'Contain at least 3 of the following:')}
        </li>
        <ul>
          <li className={cn({ success: hasLowerCase(password) })}>
            {t('password.rules.lowerCase', 'Lower case letters (a-z)')}
          </li>
          <li className={cn({ success: hasUpperCase(password) })}>
            {t('password.rules.upperCase', 'Upper case letters (A-Z)')}
          </li>
          <li className={cn({ success: hasNumber(password) })}>
            {t('password.rules.numbers', 'Numbers (0-9)')}
          </li>
          <li
            className={cn({
              success: hasSpecialCharacter(password),
            })}
          >
            {t(
              'password.rules.characters',
              'Special characters (e.g. !@#$%^&*)',
            )}
          </li>
        </ul>
      </ul>
      <ul className="mb-0">
        <li className={cn({ success: isDirty && !passwordErrors?.dontMatch })}>
          {t('password.rules.confirm', 'Match the confirm password')}
        </li>
      </ul>
    </div>
  )
}
