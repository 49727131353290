import { WithClassName } from '../Icon'

export const FAQ = ({ className }: WithClassName) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 8.03809V1.03809C18 0.486086 17.552 0.0380859 17 0.0380859H1C0.448 0.0380859 0 0.486086 0 1.03809V13.0001L3.962 9.03809H17C17.552 9.03809 18 8.59009 18 8.03809ZM3.548 8.03809L3.255 8.33109L1 10.5861V1.03809H17V8.03809H3.548ZM5 5.03809H13V4.03809H5V5.03809ZM23 11.0381H7C6.448 11.0381 6 11.4861 6 12.0381V19.0381C6 19.5901 6.448 20.0381 7 20.0381H20.038L24 24.0001V12.0381C24 11.4861 23.552 11.0381 23 11.0381ZM23 21.5861L20.745 19.3311L20.452 19.0381H7V12.0381H23V21.5861ZM19 15.0381H11V16.0381H19V15.0381Z"
      fill="#00708D"
    />
  </svg>
)
