import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuth } from '../../../../hooks/useAuth'
import { useDeleteAccount } from '../../../../hooks/useUser/useUser'
import Button from '../../../core/Button/Button'
import { Modal } from 'react-bootstrap'

const AccountDeletion = () => {
  const { t } = useTranslation()
  const { logout } = useAuth0()
  const { user } = useAuth()
  const { mutate: deleteAccount, isPending } = useDeleteAccount()
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const handleDeleteAccount = () => {
    deleteAccount(undefined, {
      onSuccess: () => {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        })
      },
    })
  }

  return (
    <div className="settings-card settings-card-danger">
      <h2 className="h3 text-danger">
        {t('settings.profile.remove', 'Remove Account')}
      </h2>
      <Button
        variant="danger"
        onClick={() => setShowDeleteModal(true)}
      >
        {t('settings.profile.remove.button', 'Delete Account')}
      </Button>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('settings.profile.remove.confirm.title', 'Delete Account')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(
            'settings.profile.remove.confirm.message',
            'Are you sure you want to delete your account? This action cannot be undone.',
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            {t('common.cancel', 'Cancel')}
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteAccount}
            isLoading={isPending}
          >
            {t('settings.profile.remove.confirm.button', 'Yes, Delete My Account')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AccountDeletion 