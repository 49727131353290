import { FunctionComponent } from 'react'
import { UserProfile } from '../../types/domain'
import { useAuth } from '../../hooks/useAuth'

export const UserLabel: FunctionComponent = () => {
  const { user } = useAuth()

  return (
    <>
      <div
        style={{ width: 45, height: 45 }}
        className="rounded-circle bg-gray-100 text-gray-500 d-flex align-items-center justify-content-center border border-gray-200 me-base"
      >
        {getAvatarLabel(user?.profile)}
      </div>
      <span className="text-truncate medium-300">
        {getUserName(user?.profile)}
      </span>
    </>
  )
}

export const getUserName = (profile?: UserProfile) =>
  profile ? `${profile.firstName ?? ''} ${profile.lastName ?? ''}` : ''

export const getAvatarLabel = (profile?: UserProfile) =>
  profile
    ? `${profile.firstName?.[0] ?? ''} ${profile.lastName?.[0] ?? ''}`
    : ''
