import cx from 'classnames'
import { forwardRef, PropsWithChildren } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { NavIcon, NavItemProps } from './SideNav'
import { MakeOptional } from '../../../types/domain'

export const MenuNavLink = forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<MakeOptional<NavItemProps, "icon">>
>(({ id, to, label, icon = null, children, disabled }, ref) => {
  const location = useLocation()
  const isActive = location.pathname === to

  return (
    <Link
      aria-disabled={disabled}
      id={id}
      data-testid={`navigation-item-${label.toLowerCase()}`}
      ref={ref}
      to={to}
      className={cx('app-side-nav__item', {
        'app-side-nav__item--active': isActive,
        'cursor-pe-none': disabled,
        disabled,
      })}
      aria-label={label}
    >
      {icon && <NavIcon icon={icon} disabled={disabled} />}
      {children}
    </Link>
  )
})

MenuNavLink.displayName = 'MenuNavLink'
