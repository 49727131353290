import React, { useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useAuth } from '../hooks/useAuth'
import { useTemplates } from '../hooks/useTemplate/useTemplateBase'
import { useAttachments } from '../hooks/useAttachment/useAttachmentBase'
import { Attachment, Template } from '../types/domain'
import { InteractionSummaryForm } from '../types/forms'
import { CategoryOption } from '../components/features/InteractionCategory/CategoryOptions'
import QRModal from '../components/features/InteractionSummary/QRCodeModal'
import QRModalStreaming from '../components/features/InteractionSummary/QRCodeModalStreaming'
import {
  generateMultipleQRCodes,
  generateQRCodeData,
} from '../components/features/InteractionSummary/QRCodeGenerator'
import { SummaryTemplate } from '../components/features/ContentLibrary/TemplateManagement/SummaryTemplate'
import { FormSection } from '../components/features/InteractionSummary/FormSection'
import { QRCodeModel } from '../components/features/InteractionSummary/QRCodeModel'

const SummaryPage: React.FC = () => {
  const { user } = useAuth()
  const { data: templates = [], isLoading: isLoadingTemplates } = useTemplates()
  const { data: userAttachments = [], isLoading: isLoadingAttachments } =
    useAttachments()

  const [qrModalState, setQrModalState] = useState<{
    show: boolean
    data: string | null
    dataStream: string[] | null
  }>({
    show: false,
    data: null,
    dataStream: null,
  })

  const [error, setError] = useState<string | null>(null)
  const [selectedCategory, setSelectedCategory] =
    useState<CategoryOption | null>(null)
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<CategoryOption | null>(null)

  const methods = useForm<InteractionSummaryForm>({
    mode: 'all',
    defaultValues: {
      title: '',
      description: '',
      category: null,
      subCategory: null,
      attachments: [],
    },
  })

  const handleQRModalClose = () => {
    setQrModalState({ show: false, data: null, dataStream: null })
  }

  const handleGenerateQRCode = async (data: QRCodeModel, qrStream: boolean) => {
    try {
      if (qrStream) {
        const generatedData = await generateMultipleQRCodes(data, 500)
        setQrModalState({ show: true, data: null, dataStream: generatedData })
      } else {
        const generatedData = await generateQRCodeData(data)
        setQrModalState({ show: true, data: generatedData, dataStream: null })
      }
      setError(null)
    } catch (err) {
      setError('Error generating QR Code. Please try again.')
    }
  }

  const onSubmit = async (data: InteractionSummaryForm) => {
    const qrData: QRCodeModel = {
      v: '0.1',
      t: data.title,
      c: data.category?.value ?? null,
      sc: data.subCategory?.value ?? null,
      hn: `${user?.profile?.firstName} ${user?.profile?.lastName}`,
      ht: user?.profile?.jobTitle as string,
      i: user?.organizationId?.toString() ?? '',
      s: data.description,
      u: data.attachments.map((a) => a.url),
    }

    console.log('qrData', qrData)
    handleGenerateQRCode(qrData, false)
  }

  const handleAddAttachment = (attachment: Attachment) => {
    const currentAttachments = methods.getValues('attachments')
    if (!currentAttachments.some((att) => att.url === attachment.url)) {
      methods.setValue('attachments', [...currentAttachments, attachment], {
        shouldDirty: true,
      })
    }
  }

  const handleRemoveAttachment = (index: number) => {
    const currentAttachments = methods.getValues('attachments')
    methods.setValue(
      'attachments',
      currentAttachments?.filter?.((_, i) => i !== index) || [],
      { shouldDirty: true },
    )
  }

  const handleCategoryChange = (category: CategoryOption | null) => {
    setSelectedCategory(category)
    if (!category) {
      setSelectedSubCategory(null)
      methods.setValue('subCategory', null)
    }
    methods.setValue('category', category)
  }

  const handleSubCategoryChange = (category: CategoryOption | null) => {
    setSelectedSubCategory(category)
    methods.setValue('subCategory', category)
  }

  const onTemplateApplied = (template: Template) => {
    console.log('template', template)
    console.log('userAttachments', userAttachments)
    console.log('getValues(attachments)', methods.getValues('attachments'))
    if (template.attachments?.length) {
      const currentAttachments = methods.getValues('attachments')
      const uniqueAttachments = Array.from(
        new Set([...currentAttachments, ...template.attachments])
      )
      methods.setValue('attachments', uniqueAttachments, { shouldDirty: true })
    }
  }

  return (
    <div className='manual-consult__content'>
    <FormSection
            methods={methods}
            selectedCategory={selectedCategory}
            selectedSubCategory={selectedSubCategory}
            onCategoryChange={handleCategoryChange}
            onSubCategoryChange={handleSubCategoryChange}
            onSubmit={onSubmit}
            onAddAttachment={handleAddAttachment}
            onRemoveAttachment={handleRemoveAttachment}
            onTemplateApplied={onTemplateApplied}
          />

{qrModalState.data && (
          <QRModal
            show={qrModalState.show}
            onHide={handleQRModalClose}
            qrData={qrModalState.data}
          />
        )}
        {qrModalState.dataStream && (
          <QRModalStreaming
            show={qrModalState.show}
            onHide={handleQRModalClose}
            qrData={qrModalState.dataStream}
          />
        )}
    </div>
  )
}

export default SummaryPage
